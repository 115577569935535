import swal from "sweetalert2"

export const swalAlert = (swalType) => {
    const type = {
      success: (title:string, text:string = '') => {
        swal({
          type: 'success',
          title,
          text,
          showConfirmButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
      },
      error:(title:string, text:string = '')=>{
        swal({
          type: 'error',
          title,
          text,
          showConfirmButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
      },
      loading:(title:string, text:string = '')=>{
        swal({
          title,
          text,
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: function () {
            swal.showLoading()
          }
        })
      },
      endLoading:()=> {
        swal.close();
      }
    }

    return type[swalType];
  }
