import { UserCVComponent } from '../../user-cv.component';
import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersField } from 'app/models/usersInfo/users-fields';
import { Work } from 'app/models/worksinfo/work';
import { Course } from 'app/models/Coursesinfo/course';
import { UsersService } from 'app/models/usersInfo/users.service';
import { WorkService } from 'app/models/worksinfo/works.service';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { AuthService } from 'app/models/Auth/auth.service';

declare var jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'add-workinfo',
  templateUrl: './add-workinfo.component.html',
  styleUrls: ['./add-workinfo.component.css']
})


export class AddWorkInfoComponent implements OnInit {
  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/];
  User_info: UsersField = new UsersField();

  public WorksInfo: any;
  courses: Course[];
  user: UsersField;

  stateButton: boolean = false
  isok: Boolean;
  errorMessage: string = '';
  submitbuton: string = 'Aceptar';
  fileToUpload: File;
  
  isImageError = true;
  enablesavefile = false;


  constructor(public userCVComponent: UserCVComponent,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public workServices: WorkService,
    private router: Router,
    public attachmentService: AttachmentService,
    private authService: AuthService) {

      

  }






  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {


    this.WorksInfo = {

      IsCurrent: false,
      stateUploadFile: "Cargar Certificado Laboral"
    }

   
   


    if (!isNullOrUndefined(this.userCVComponent.User_info))
      this.User_info = this.userCVComponent.User_info;
      this.User_info["UserID"]=this.userCVComponent.user


  }

  uploadFile(fileInput: any) {
    console.log(fileInput)
    this.WorksInfo["newCertificate"]= <File>fileInput.target.files[0];
    if (this.WorksInfo["newCertificate"].size > 1000000) {//1M
      this.WorksInfo["stateUploadFile"] = "Error de Archivo, No debe superar 1MB"
      this.WorksInfo["enablesavefile"] = false
      console.error("ARCHIVO MAS DE 1MB")
    }
    else {
      this.WorksInfo["stateUploadFile"] =  'Archivo OK'
      this.WorksInfo["enablesavefile"] = true
    }
  }

  CreateWorksInfo(model: Work, isValid: boolean) {

    console.log(model);
    console.log(isValid);

    if (isValid) {
      this.stateButton = true
      console.log(model);
      this.errorMessage = "Validando ... ";
      this.submitbuton = "Validando ... ";


       //REVISO SI HAY NUEVO ARCHIVO CARGADO LISTO PARA GUARDAR
    if (this.WorksInfo["enablesavefile"]==true){
      this.attachmentService.NewWorkInfoSuports(this.WorksInfo["newCertificate"]).then((result) => {

        model.Certificate = "/attachments/workinfo/download/" + result['name'];
  

      this.workServices.saveWork(model).subscribe(
        p => {
          this.isok = true;
          this.stateButton = false
          this.userCVComponent.ngOnInit();

          this.WorksInfo = {};
          this.userCVComponent.WorkInfos = [];
          this.WorksInfo["stateUploadFile"]='Cargar Certificado'
          this.errorMessage = '';
          this.submitbuton = 'Guardar'

          //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
          setTimeout(function () {
            jQuery("#ModalWork").modal('hide');
            jQuery(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
            jQuery(':checkbox, :radio').prop('checked', false);
          }, 500)

        },
        e => {
          this.errorMessage = "Error de Red";
          this.stateButton = false
          this.isok = false;
          this.submitbuton = 'Actualizar'
        });
      }, (error) => {
        console.error(error);
        this.authService.swalError(
          "Error en Archivo",
          ""
        );
      })
    
    }else{

      this.workServices.saveWork(model).subscribe(
        p => {
          this.isok = true;
          this.stateButton = false
          this.userCVComponent.ngOnInit();

          this.WorksInfo = {};
          this.userCVComponent.WorkInfos = [];
          this.errorMessage = '';
          this.submitbuton = 'Guardar'

          //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
          setTimeout(function () {
            jQuery("#ModalWork").modal('hide');
            jQuery(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
            jQuery(':checkbox, :radio').prop('checked', false);
          }, 500)

        },
        e => {
          this.errorMessage = "Error de Red";
          this.stateButton = false
          this.isok = false;
          this.submitbuton = 'Actualizar'
        });

    }

     
  


    }

  }


}


