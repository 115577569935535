import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import { NewSelectAcademicComponent, ButtonViewComponent } from './new-select-academic.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

export const routes: Routes = [
  { path: 'user/academicarea', component: NewSelectAcademicComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CustomFormsModule,
    HttpModule,
    Ng2SmartTableModule,

  ],
  entryComponents: [
    ButtonViewComponent,
  ],
  declarations: [
    NewSelectAcademicComponent,
    ButtonViewComponent
  ]

})
export class NewSelectAcademicModule { }
