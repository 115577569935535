import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { swalAlert } from '../swal';

@Component({
  moduleId: module.id,
  selector: 'edit-subject-form',
  templateUrl: './edit-subject-form.component.html',
  styleUrls: ['./edit-subject-form.component.css'],
})


export class EditSubjectFormComponent implements OnInit {

  constructor(
    private _fb: FormBuilder,
    private _courseService: CoursesService
  ) {
  }

  @Input ('roles') protected roles:any[] = [];
  @Input('userData') protected userData: any = undefined;
  @Output('updatedSubjectGroup') protected updatedSubjectGroup: EventEmitter<any> = new EventEmitter();

  private _selectedSubject: any = undefined;
  public get selectedSubject(): any {
    return this._selectedSubject;
  }
  @Input('subjectGroupData') protected set subjectGroupData(value: any) {
    if (value) {
      this._selectedSubject = value;
      this.patchForm();
    }
  }

  public formGroup: FormGroup = this._fb.group({
    codeBlackBoard: [''],
    codeClassRoom: [''],
    virtualLink: [''],
    minTerms: this._fb.array([])
  });
  public isSavingData:boolean = false;

  ngOnInit(): void {
    //
  }

  /**
   * Update form data
   */
  private patchForm() {
    this.formGroup.patchValue({
      codeBlackBoard: this._selectedSubject.CodeBlackBoard,
      codeClassRoom: this._selectedSubject.CodeClassRoom,
      virtualLink: this._selectedSubject.VirtualLink,
    })

    this.addControl();
  }

  /**
   * Add minTerms controls
   * 
   * @returns 
   */
  private addControl() {
    const minTerms: any[] = this._selectedSubject.minTerms;
  
    if (!minTerms || minTerms.length === 0)
      return; 
  
    const formControls = this._fb.array(
      minTerms.map((e, index) =>
        this._fb.group({
          noteName: [e.NameMid, Validators.required],
          percentage: [e.Porcent, Validators.required],
        })
      )
    );
  
    this.formGroup.setControl('minTerms', formControls);
  }


  /**
   * Store minTerms
   */
  public saveSubjects() {
    swalAlert('loading')('Cargando...', '');
    this.isSavingData = true;

    const data = {
      ...this._selectedSubject,
      CodeBlackBoard: this.formGroup.get('codeBlackBoard').value,
      CodeClassRoom: this.formGroup.get('codeClassRoom').value,
      VirtualLink: this.formGroup.get('virtualLink').value,
    }

    this._courseService.putSubjectByProfesorID(this.userData.id, this._selectedSubject.id, data).pipe(
      switchMap((subjectGroup) => {
        
        if (this._selectedSubject.minTerms && this._selectedSubject.minTerms.length > 0) {
          const minTermsFormArray = this.formGroup.get('minTerms') as FormArray;
          const minTerms = this._selectedSubject.minTerms.map((e,index) => {
            const control = minTermsFormArray.at(index) as FormGroup;

            e.NameMid = control.get('noteName').value;
            e.Porcent = control.get('percentage').value;

            return this._courseService.putMidTermByProfesorID(this._selectedSubject.id, e.id, e)
            .pipe(catchError(e => {
              console.log('Error al guardar el midterm', e);
              return of(null);
            }))

          })
          return forkJoin(minTerms).pipe(map(minTerms => ({ minTerms, subjectGroup })))
        }

        return of(null);
      })
    ).subscribe({
      next: ({ minTerms, subjectGroup }) => {
        const data = {
          ...subjectGroup,
          minTerms: minTerms || null,
          subjects: this.selectedSubject.subjects ? this.selectedSubject.subjects : null
        }
        
        this.isSavingData = false;
        this.updatedSubjectGroup.emit(data);
        swalAlert('success')('Hecho!', 'Se han guardado los datos con éxito.');
      }, error: (err) => {
        console.log(err);
        this.isSavingData = false;
        swalAlert('error')('Error!', 'Ha ocurrido un error: ' + err.message);
      }
    })
  }
}