import { Injectable } from '@angular/core';

import {Mail} from './mail';
import { AuthService} from "../Auth/auth.service";


@Injectable()
export class MailService {

 
    constructor( private authService: AuthService){
  }

  private baseUrl: string = this.authService.UrlAPI;

  




  sendEmail(mail: Mail, file: File ){


    return new Promise((resolve, reject) => {

      var xhr = new XMLHttpRequest();
      var fd = new FormData();
     
      xhr.open("POST", `${this.baseUrl}/correos/sendEmail`, true);
      xhr.onreadystatechange = function() {
          if(xhr.readyState == 4 && xhr.status == 200) {
              resolve(JSON.parse(xhr.responseText));
          }
      };
      fd.append('mail', JSON.stringify(mail));
      fd.append('file', file);
      xhr.setRequestHeader('Authorization', this.authService.getToken())
      xhr.send(fd);
  
    });

 }


}







