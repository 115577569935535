import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';


// Modulos Externos
import * as Moment from 'moment';

import { extendMoment } from 'moment-range';
import Swal from 'sweetalert2';



const moment = extendMoment(Moment);


@Injectable({
  providedIn: 'root'
})
export class SharedService {



  constructor(
  

  ) {
  }


  /***** FUNCIONES QUE NO CONSULTAN API */

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  getUnique(arr, comp) {
    const unique = arr
      .map(e => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  swalLoading(titleString: string) {
    Swal({
      type: 'success',
      title: titleString,
      text: 'This Process May Take Some Time',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: function () {
        Swal.showLoading();
      }

    });

  }

  swalEndLoad() {
    Swal({
      type: 'error',
      timer: 5,

    }).catch();

  }




  swalSuccess(titleString: string, textString: string) {

    Swal({
      type: 'success',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,

    }).catch();

  }


  swalError(titleString: string, textString: string) {

    Swal({
      type: 'error',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).catch();

  }


 



}
