import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ConversorPipe } from '../../../models/pipes/conversor.pipe';
import { MyInscriptionComponent } from './my-inscription/my-inscription.component';
import { NewCourseComponent } from './new-course/new-course.component';
import { ColombiaPipeMododule } from '../../../components/profile-nav/profile-nav.component';
import { MyRecordsHistoryComponent } from './my-records-history/my-records-history.component';
import { OrderModule } from 'ngx-order-pipe';
export const routes: Routes = [

  { path: 'user/myinscription', component: MyInscriptionComponent },
  { path: 'user/myrecordsHistory', component: MyRecordsHistoryComponent },
  { path: 'user/newcourse', component: NewCourseComponent }

];




@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CustomFormsModule,
    HttpModule,
    Ng2SmartTableModule,
    ColombiaPipeMododule,
    OrderModule
  
  ],
  declarations: [
    MyInscriptionComponent,
    MyRecordsHistoryComponent,
    NewCourseComponent,
    ConversorPipe,
  ],
  entryComponents: [
  ]
})
export class modStudent { }
