import { Injectable } from '@angular/core';

import {Scientific} from './scientific';
import { AuthService} from "../Auth/auth.service";
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ScientificService {

 
    constructor(private http : HttpClient, private authService: AuthService){
  }
  private baseUrl: string = this.authService.UrlAPI;

  getAllScientificPublication() :Observable<any> {
    let TypeCourses$ = this.http
     .get(`${this.baseUrl}/TypePublications`, { headers: this.getHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
    ) 
     return TypeCourses$;
 }


  getScientificInfosByUserID(id:number) :Observable<any> {
     let TypeCourses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/ScientificInfos/?filter={"include": ["TypePublications"],"order": "id DESC"}`, { headers: this.getHeaders()})
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      ) 
      return TypeCourses$;
  }


  saveScientific(scientific: Scientific): Observable<any>{
    return this
     .http
     .post(`${this.baseUrl}/Userapps/${scientific.UserID}/ScientificInfos`, JSON.stringify(scientific) , { headers: this.putHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
    ) 
    
 }

 getScientificByID(id:number) :Observable<any> {
  let TypeCourses$ = this.http
   .get(`${this.baseUrl}/ScientificInfos/${id}`, { headers: this.putHeaders()})
   .pipe(
    catchError(err => this.authService.errorMessages(err))
  ) 
   return TypeCourses$;
}

UpdateScientificByID(scientific: Scientific): Observable<any>{
  return this
   .http
   .put(`${this.baseUrl}/Userapps/${scientific.UserID}/ScientificInfos/${scientific.id}`, JSON.stringify(scientific) , { headers: this.putHeaders()})
   .pipe(
    catchError(err => this.authService.errorMessages(err))
  ) 
  
}


 DeleteScientificByID(UserID,ScientificID): Observable<any>{
  return this
   .http
   .delete(`${this.baseUrl}/Userapps/${UserID}/ScientificInfos/${ScientificID}`,  { headers: this.putHeaders()})
   .pipe(
    catchError(err => this.authService.errorMessages(err))
  ) 
  
}

getHeaders(): HttpHeaders{
  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.authService.getToken()
  });
  return headers;
}

putHeaders(): HttpHeaders{
  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.authService.getToken()
  });
  return headers;
}



}


