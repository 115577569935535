import { Component, OnInit, AfterViewChecked, Input, Output, EventEmitter } from "@angular/core";
import { ViewCell } from "ng2-smart-table";
import { isNullOrUndefined } from "util";

// INICIO COMPONENTE PARA EL NG SMART TABLE DE ACORDE
@Component({
    selector: 'is-according-table',
    template: `
        {{renderValue}}
        `
  })
  export class AccordingView implements ViewCell, OnInit, AfterViewChecked {
    constructor(
  
    ) { }
    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    @Output() save: EventEmitter<any> = new EventEmitter()
    ngOnInit() {
      // console.log("aca esta el render despues")
      //  console.log(this.intermediate.Grups)
      if (!isNullOrUndefined(this.value)) {
        if (this.value.toString() == "true" || this.value.toString() == "1"  )
          this.renderValue = "Si"
        if (this.value.toString() == "false" || this.value.toString() == "0"  )
          this.renderValue = "No"
      }
    }
    ngAfterViewChecked() {
      this.renderValue = String(this.value);
    }
  }
  // FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS
  