import { UserCVComponent } from '../../user-cv.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersField } from 'app/models/usersInfo/users-fields';
import { Course } from 'app/models/Coursesinfo/course';
import { UsersService } from 'app/models/usersInfo/users.service';
import { WorkService } from 'app/models/worksinfo/works.service';
import { Work } from 'app/models/worksinfo/work';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { AuthService } from 'app/models/Auth/auth.service';

declare var jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'edit-workinfo',
  templateUrl: './edit-workinfo.component.html',
  styleUrls: ['./edit-workinfo.component.css']
})


export class EditWorkInfoComponent implements OnInit {
  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/];
  User_info: UsersField = new UsersField();

  WorksInfoEdit: any[];
  courses: Course[];
  user: UsersField;

  DatosDisponiblesEditar: boolean

  isok: Boolean;
  errorMessage: string = '';
  submitbuton: string = 'Actualizar'

  stateButton: boolean = false
  

  constructor(public userCVComponent: UserCVComponent,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public workServices: WorkService,
    private router: Router,
    public attachmentService: AttachmentService,
    private authService: AuthService

  ) {

  }





  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {
    this.DatosDisponiblesEditar = this.userCVComponent.DatosDisponiblesEditar;
    


  }

  uploadFile(fileInput: any) {

    console.log(this.userCVComponent.EditWork)

    this.userCVComponent.EditWork["newCertificate"] = <File>fileInput.target.files[0];
    if ( this.userCVComponent.EditWork["newCertificate"].size > 1000000) {//1M
      this.userCVComponent.EditWork["stateUploadFile"] = 'Error de Archivo, No debe superar 1MB'
     
      this.userCVComponent.EditWork["enablesavefile"] = false
    }
    else {
      this.userCVComponent.EditWork["stateUploadFile"] =  'Archivo OK'
     
      this.userCVComponent.EditWork["enablesavefile"] = true
    }
  }


  ReloadWindow() {


  }

  EditWorksInfo(model: Work) {
    console.log(model)

    this.stateButton = true
    this.userCVComponent.ValidateDate(model)

    console.log(model);
    // this.errorMessage="Validando ... ";
    this.submitbuton = "Validando ... ";

    //REVISO SI HAY NUEVO ARCHIVO CARGADO LISTO PARA GUARDAR
    if (this.userCVComponent.EditWork["enablesavefile"]==true){

      this.attachmentService.NewWorkInfoSuports(this.userCVComponent.EditWork["newCertificate"]).then((result) => {

        model.Certificate = "/attachments/workinfo/download/" + result['name'];

        this.workServices.UpdateWorkByID(model).subscribe(
          p => {
            this.isok = true;
            this.stateButton = false
            this.userCVComponent.ngOnInit();
  
            //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
            setTimeout(function () {
              jQuery("#ModalEditWork").modal('hide');
            }, 500);
  
            this.userCVComponent.EditWork = []
            this.submitbuton = "Actualizar";
  
  
          },
          e => {
            this.errorMessage = e;
            this.isok = false;
            this.stateButton = false
            this.errorMessage = "Corrija los datos erróneos";
            this.submitbuton = 'Actualizar'
          });
  
  
      }, (error) => {
        console.error(error);
        this.authService.swalError(
          "Error en Archivo",
          ""
        );
      })


    }
    else{
      //SOLO ACTUALIZO LOS DATOS
      this.workServices.UpdateWorkByID(model).subscribe(
        p => {
          this.isok = true;
          this.stateButton = false
          this.userCVComponent.ngOnInit();

          //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
          setTimeout(function () {
            jQuery("#ModalEditWork").modal('hide');
          }, 500);

          this.userCVComponent.EditWork = []
          this.submitbuton = "Actualizar";


        },
        e => {
          this.errorMessage = e;
          this.isok = false;
          this.stateButton = false
          this.errorMessage = "Corrija los datos erróneos";
          this.submitbuton = 'Actualizar'
        });


    }

    


  }








}


