export class UserCourse{
    
        constructor(
    
        public UserID?:number,
        public CourseOferID?:number,
        public IsPaided?:boolean,
        public Invoice?: string,
        public IsAcepted?: boolean,
        public GPA?: number,
        public CourseGroupID?: number,
        public id?: number,
        public NameCourseOfer?: string,
        public CourseID?: number,
        public SchoolID?:  number,
        public IsActive?: boolean,
        public Since?: string,
        public Until?: string,
        public Price?:number,
    
        ){
    
        }
    }