import { Injectable } from '@angular/core';

import { AuthService} from "../Auth/auth.service";


import { DateMidTerm } from './datemidterm';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';




@Injectable()
export class RecordService {


  constructor(private http : HttpClient,private authService: AuthService){
  }

  private baseUrl: string = this.authService.UrlAPI;


  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }
  
  putHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  getDatesBySchool(SchoolID:number) :Observable<any> {
    let date$ = this.http
     .get(`${this.baseUrl}/Schools/${SchoolID}/DateMidTerms?filter={"limit":1, "order": "id DESC"}`, { headers: this.getHeaders()})
     .pipe(
        catchError(err => this.authService.errorMessages(err))
      ) 
     return date$;
 }



  patchDates(date: DateMidTerm): Observable<any>{
    return this
     .http
     .patch(`${this.baseUrl}/DateMidTerms`, 
           JSON.stringify(date), 
           {headers: this.putHeaders()});
 }

}

 
