import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { UserCVsModule } from './cv/user-cv.module';
import { SelectAcademicModule } from './select-academic/select-academic.module';
import { NewSelectAcademicModule } from './new-select-academic/new-select-academic.module';

import { StudentRecordModule } from './student-record/student-record.module';

import { ColombiaPipeMododule } from '../../../components/profile-nav/profile-nav.component';
import { AccordingViewProfessor } from './student-record/student-record.component';

export const routes: Routes = [

];




@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    UserCVsModule,
    SelectAcademicModule,
    NewSelectAcademicModule,
    StudentRecordModule,
    ColombiaPipeMododule


  ],

  declarations: [
    AccordingViewProfessor

  ],
  entryComponents: [
    AccordingViewProfessor]
})
export class modProfesor { }
