import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'urlSaniter'
   })
   export class UrlSaniter implements PipeTransform {
   
    constructor(private sanitizer: DomSanitizer) {}
   
    transform(value: any, args?: any): any {
      value = value.replace('SafeValue must use [property]=binding:', '');
     
      /* return this.sanitizer.bypassSecurityTrustResourceUrl(value); */
      return this.sanitizer.sanitize(SecurityContext.URL, value);

    }
   
      
   
   }