import {Component, OnInit} from "@angular/core"
import {environment} from "environments/environment"

@Component({
  selector: "Tutorial",
  templateUrl: "./tutorial.component.html",
})
export class TutorialComponent {
  public APP_NAME: string = environment.APP_NAME
}
