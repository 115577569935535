export class CourseGroup{

    constructor(

			public id?:number,
			public CourseOferID?:number,
			public name?: string,
			public StartDayTime?: Date,
			public EndtDayTime?: Date,
    ){

    }
}