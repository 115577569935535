import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Course } from "../../../../models/Coursesinfo/course";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalDataSource } from 'ng2-smart-table';
import { ViewCell } from 'ng2-smart-table';
import { Module } from '../../../../models/Coursesinfo/module';
import { School } from '../../../../models/Coursesinfo/school';
import { UsersField } from '../../../../models/usersInfo/users-fields';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { UsersService } from '../../../../models/usersInfo/users.service';
import { AuthService } from '../../../../models/Auth/auth.service';
import { UserJob } from '../../../../models/usersInfo/userjob';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'environments/environment';

export class Intermediate {
  exist: boolean = false
  text = "Inscribirse"
}




@Component({
  selector: 'button-view',
  template: `
      <button [disabled]="intermediate.exist" (click)="onClick()" class="btn btn-block btn-info btn-block ">{{intermediate.text}}</button>
    `,
})
export class ButtonViewComponent implements ViewCell, OnInit {

  constructor(
    public intermediate: Intermediate,
  ) { }

  renderValue: string;



  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();


  }

  onClick() {
    this.save.emit(this.rowData);

  }




}

@Component({
  selector: 'select-academic',
  templateUrl: './select-academic.component.html',
  providers: [ButtonViewComponent, Intermediate]
})
export class SelectAcademicComponent implements OnInit {

  modules: Module[];
  schools: School[];
  courses: Course[];

  subjects: Course[];
  user: UsersField;
  userid: string;

  areaID: number = 0;
  courseID: number = 0;
  schoolID: number = 4;
  id_temp: any

  settings = {
    noDataMessage: "No hay datos disponibles",

    edit: {
      editButtonContent: '<i class="fa fa-pencil-square"></i>',
      saveButtonContent: '<i class="fa fa-floppy-o"></i>',
      cancelButtonContent: '<i class="fa fa-window-close"></i>',
      confirmSave: true
    },
    actions: {
      add: false,
      delete: false,
      edit: false,
      columnTitle: "Acciones"
    },
    columns: {



      NameSubject: {
        title: 'MATERIA',
        filter: false,
        editable: false,
      },



      id: {
        title: 'SELECCIONAR',
        filter: false,
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            // this.saveDocenteDetails(row.id)
          });
        }
      }
    }
  };

  source: LocalDataSource;

  public SCHOOL_LOGO_DYNAMIC_SVG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);

  constructor(private coursesService: CoursesService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private usersServices: UsersService,
    public intermediate: Intermediate,
    private buttonViewComponent: ButtonViewComponent,
    private authService: AuthService) {
    this.source = new LocalDataSource(); // create the source

    this.user = {
      SchoolID: 4,
      JobOferID: 0,
      CourseOferID: 0,
    }

    this.coursesService.getAllSchools().subscribe(p => this.schools = p);

    this.coursesService.getAllAcademicAreasBySchool(4).subscribe(p => this.modules = p);
    this.coursesService.getAllCoursesMayaBySchool(4).subscribe(p => this.courses = p);

  }

  ngOnInit() {





    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      this.userid = this.authService.getCurrentUser();
    }
  }

  selectSchool(model: UsersField) {

    this.schoolID = model.SchoolID;
    this.coursesService.getAllAcademicAreasBySchool(model.SchoolID).subscribe(p => this.modules = p);
    this.coursesService.getAllCoursesMayaBySchool(model.SchoolID).subscribe(p => this.courses = p);

  }


  selectArea(model: UsersField) {

    this.intermediate.exist = true

    this.areaID = model.JobOferID;
    this.coursesService.getAllSubjectsByAreaByCourse(this.areaID, this.courseID).subscribe(p => {
      this.subjects = p
      this.source.load(p);
    });


    this.usersServices.getAllListOAPDs(this.schoolID, null, this.courseID, this.areaID, null).subscribe(oaps => {

      console.log("OAPS",oaps);
      var exist_area = [false]
      var pending = [false]

      for (var index = 0; index < oaps.length; index++) {
        if (parseInt(this.userid) == oaps[index].id && oaps[index].isAcepted == true) {
          console.log("ESTA ACEPTADO")
          exist_area = exist_area.concat([true])
        }
        if (parseInt(this.userid) == oaps[index].id && oaps[index].isAcepted == false) {
          console.log("PENDIENTE DE ACEPTACIÓN")
          pending = pending.concat([true])
        }

      }


      if (exist_area.includes(true)) {
        this.intermediate.exist = true
        this.intermediate.text = "Área Aceptada"
        this.source.refresh()
      }
      else if (pending.includes(true)) {
        this.intermediate.exist = true
        this.intermediate.text = "Pendiente de Aceptación"
        this.source.refresh()
      }
      else {
        {
          this.intermediate.exist = false
          this.intermediate.text = "Inscribirse"
          this.source.refresh()
        }
      }

    });






  }


  
  allEquals(array) {
    var isSame = true;
    for (var i = 0; i < array.length; i++) {
        isSame = (array[0] === array[i] && array[i] === true) ? true : false;
    }
    return isSame;
}


  selectCourse(model: UsersField) {
    this.courseID = model.CourseOferID
    this.coursesService.getAllSubjectsByAreaByCourse(this.areaID, this.courseID).subscribe(p => {
      this.subjects = p
      this.source.load(p);
    });
  }


  saveDocenteDetails(sbujectID: number) {
    //this.errorMessage="Corriga los datos erroneos";

    if (Number.isInteger(parseInt(this.userid))) {
      let model: UserJob = {
        JobOferID: this.areaID,
        UserID: parseInt(this.userid),
        SchoolID: this.schoolID
      }


      Swal({

        type: 'success',
        title: "Inscribiendo",


        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,

        onOpen: function () {
          Swal.showLoading()
        }
      })




      this.usersService.assignAcademicArea(model).subscribe(
        p => {
          Swal("Inscrito!", "Se inscribió Correctamente al Área Académica.", "success").then(() => {

            this.intermediate.exist = true
            this.intermediate.text = "Pendiente de Aceptación"
            this.source.refresh()
          }
          );

        },
        e => {
          // this.errorMessage=e;
          //this.isok=false;
        });
    }
  }
}