
export class UserCourseCert{

    constructor(
        public id?: number,
        public UserCourseID?: number,
        public TypeCertID?: number,
        public SchoolID?: number,
        public GPA?: number,
        public Invoice?: string,
        public IsPaid?: number,
        public IsSigned?: number,
        public CertLink?: string,
        public TimeStamp?: string,
        public Data?: string,
        public UserID?: number,

    ){

    }
}