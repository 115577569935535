import { UserCVComponent } from '../../user-cv.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersField } from 'app/models/usersInfo/users-fields';
import { Course } from 'app/models/Coursesinfo/course';
import { UsersService } from 'app/models/usersInfo/users.service';
import { AcademicService } from 'app/models/Academicinfo/academic.service';
import { Academic } from 'app/models/Academicinfo/academic';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { AuthService } from 'app/models/Auth/auth.service';

declare var jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'edit-academicinfo',
  templateUrl: './edit-academicinfo.component.html',
  styleUrls: ['./edit-academicinfo.component.css']
})

export class EditAcademicInfoComponent implements OnInit {
  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/];
  User_info: UsersField = new UsersField();
  AcademicInfoEdit: any[];
  courses: Course[];
  user: UsersField;
  TypeCourses: any[];
  DatosDisponiblesEditarAcademic: boolean
  isok: Boolean;
  errorMessage: string = '';
  submitbuton: string = 'Actualizar'
  stateButton: boolean = false
  fileToUpload: File;
  imagebuton = "Subir Archivo";
  isImageError = true;
  enablesavefile = false
  buttonDiploma = "Subir Diploma";

  constructor(public userCVComponent: UserCVComponent,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public academicService: AcademicService,
    private router: Router,
    public attachmentService: AttachmentService,
    private authService: AuthService) {
  }
  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {

    this.DatosDisponiblesEditarAcademic = this.userCVComponent.DatosDisponiblesEditarAcademic;
    this.academicService.getAllTypeCourses().subscribe(p => this.TypeCourses = p);
  }

  ReloadWindow() {
  }

  uploadFile(fileInput: any) {
    
    this.userCVComponent.EditAcademic["newCertificate"] = <File>fileInput.target.files[0];
    if (this.userCVComponent.EditAcademic["newCertificate"].size > 1000000) {//1M
      this.userCVComponent.EditAcademic["stateUploadFile"] = 'Error de Archivo, No debe superar 1MB'
      this.userCVComponent.EditAcademic["enablesavefile"] = false
    }
    else {
      this.userCVComponent.EditAcademic["stateUploadFile"] =  'Archivo OK'
      this.userCVComponent.EditAcademic["enablesavefile"] = true
    }
    
  }

  EditAcademicInfo(model: Academic) {

    this.stateButton = true
    console.debug(model);
    // this.errorMessage="Validando ... ";
    this.submitbuton = "Validando ... ";

    if (model.IsCurrent) {
      this.academicService.UpdateAcademicByID(model).subscribe(
        p => {
          this.stateButton = false
          this.isok = true;
          this.userCVComponent.ngOnInit();
          //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
          setTimeout(function () {
            jQuery("#ModalEditAcademic").modal('hide');
          }, 500);
          this.userCVComponent.EditWork = []
          this.submitbuton = "Actualizar";
        },
        e => {
          this.stateButton = false
          this.errorMessage = e;
          this.isok = false;
          this.errorMessage = "Corrija los datos erróneos";
          this.submitbuton = 'Actualizar'
        });

    } else {

       //REVISO SI HAY NUEVO ARCHIVO CARGADO LISTO PARA GUARDAR
    if (this.userCVComponent.EditAcademic["enablesavefile"]==true){

      this.attachmentService.NewAcademicInfoSuports(this.userCVComponent.EditAcademic["newCertificate"]).then((result) => {
        model.Diploma = "/attachments/academicinfo/download/" + result['name'];

        this.academicService.UpdateAcademicByID(model).subscribe(
          p => {
            this.stateButton = false
            this.isok = true;
            this.userCVComponent.ngOnInit();
            //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
            setTimeout(function () {
              jQuery("#ModalEditAcademic").modal('hide');
            }, 500);
            this.userCVComponent.EditAcademic = []
            this.submitbuton = "Actualizar";
          },
          e => {
            this.stateButton = false
            this.errorMessage = e;
            this.isok = false;
            this.errorMessage = "Corrija los datos erróneos";
            this.submitbuton = 'Actualizar'
          });

      }, (error) => {
        console.error(error);
        this.authService.swalError(
          "Error en Archivo",
          ""
        );
      })

    }
    else{
      this.academicService.UpdateAcademicByID(model).subscribe(
        p => {
          this.stateButton = false
          this.isok = true;
          this.userCVComponent.ngOnInit();
          //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
          setTimeout(function () {
            jQuery("#ModalEditAcademic").modal('hide');
          }, 500);
          this.userCVComponent.EditAcademic = []
          this.submitbuton = "Actualizar";
        },
        e => {
          this.stateButton = false
          this.errorMessage = e;
          this.isok = false;
          this.errorMessage = "Corrija los datos erróneos";
          this.submitbuton = 'Actualizar'
        });


    }
    }

  }
}