import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { StudentRecordComponent } from './student-record.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ColombiaPipeMododule } from '../../../../components/profile-nav/profile-nav.component';
import { LinkRenderComponent } from './table-components/link/link-render.component';
import { StudentBulletinComponent } from '../student-bulletin/student-bulletin.component';

export const routes: Routes = [
  { path: 'user/studentrecord', component: StudentRecordComponent },
  { path: 'user/studentbulletin', component: StudentBulletinComponent }
];

@NgModule( {
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule.forChild( routes ),
    FormsModule,
    HttpModule,
    Ng2SmartTableModule,
    ColombiaPipeMododule,
  ],
  entryComponents: [
    LinkRenderComponent,
  ],
  declarations: [
    LinkRenderComponent,
    StudentRecordComponent,
  ]

} )
export class StudentRecordModule { }