import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CustomFormsModule } from 'ng2-validation';
import { TextMaskModule } from 'angular2-text-mask';
import { UserCVComponent } from './user-cv.component';
//importar ventanas modales
import { AddWorkInfoComponent } from './workinfo-modal/add-workinfo/add-workinfo.component';
import { EditWorkInfoComponent } from './workinfo-modal/edit-workinfo/edit-workinfo.component';
import { AddAcademicInfoComponent } from './academicinfo-modal/add-academicinfo/add-academicinfo.component';
import { EditAcademicInfoComponent } from './academicinfo-modal/edit-academicinfo/edit-academicinfo.component';
import { AddScientificInfoComponent } from './scientificinfos-modal/add-scientificinfos/add-scientificinfos.component';
import { EditScientificInfoComponent } from './scientificinfos-modal/edit-scientificinfos/edit-scientificinfos.component';


export const routes: Routes = [
  { path: 'user/cv', component: UserCVComponent }



];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CustomFormsModule,
    TextMaskModule,
    HttpModule,
  ],
  declarations: [
    UserCVComponent,
    AddWorkInfoComponent,
    EditWorkInfoComponent,
    AddAcademicInfoComponent,
    EditAcademicInfoComponent,
    AddScientificInfoComponent,
    EditScientificInfoComponent,
  ]



})
export class UserCVsModule { }
