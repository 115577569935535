import { schoolToLoad } from "./environment.common";

export const environment = {
    production: false,
    UrlAPI: 'https://dev-backv1-dot-cedoc360liceos.uc.r.appspot.com/api/',
    URL_BACK_V2: 'https://dev-backv2-dot-cedoc360liceos.uc.r.appspot.com/api/',
    Url: 'https://dev-backv1-dot-cedoc360liceos.uc.r.appspot.com/',
    dialogflow: {
        saraBot: "1e9f879b83eb40bc9a4be925f9274604",
    },

    APP_NAME: 'celic',
    SHOW_FOOTER: false,

    ...schoolToLoad({ school: 'celic', 
        mainSchoolLogoImg: 'logo-celic-black', 
        politicsSchoolLogo: 'logo-celic-black', 
        politicsLogoBottom: 'logo-celic-black',
    })
};