import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';
import { Subject } from 'app/models/Coursesinfo/subject';
import { UserSubject } from 'app/models/Coursesinfo/user-subject';
import { SubjectGroup } from 'app/models/usersInfo/subjectgroup';
import { User } from 'app/models/usersInfo/user';
import { UserCourse } from 'app/models/usersInfo/usercourse';
import { environment } from 'environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'view-bulletin-by-user',
  templateUrl: './view-bulletin-by-user.component.html',
  styleUrls: ['./view-bulletin-by-user.component.scss']
})
export class ViewBulletinByUser implements OnInit, OnChanges {

  @Input()
  midTermNumSelected: number;
  @Input()
  userCourseSelected: UserCourse;
  @Input()
  subjectsByCourseAndSemester: Array<Subject>;
  @Input()
  academicAreas: Array<any>;
  @Input()
  subjectGroups: Array<SubjectGroup>;
  @Input()
  qualitativeDescriptions: Array<any>;

  public userSelected: User
  public userSubjectsSelected: Array<UserSubject> = []
  renderData: any[];
  constructor(private coursesServices: CoursesService, private sanitizer: DomSanitizer) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    console.log("changes", changes)
    if (changes.qualitativeDescriptions && changes.qualitativeDescriptions.currentValue) {
      this.qualitativeDescriptions = [...changes.qualitativeDescriptions.currentValue]
    }
    if (changes.midTermNumSelected && changes.midTermNumSelected.currentValue) {
      this.midTermNumSelected = changes.midTermNumSelected.currentValue
      this.getFinalGradeByAcademicArea()
    }


    if (changes.subjectsByCourseAndSemester && changes.subjectsByCourseAndSemester.currentValue) {
      this.subjectsByCourseAndSemester = [...changes.subjectsByCourseAndSemester.currentValue]
      this.getFinaGrades()
    }
    if (changes.academicAreas && changes.academicAreas.currentValue) {
      this.academicAreas = [...changes.academicAreas.currentValue]
    }
    if (changes.subjectGroups && changes.subjectGroups.currentValue) {
      this.subjectGroups = [...changes.subjectGroups.currentValue]
      this.getFinaGrades()
    }
    if (changes.userCourseSelected && changes.userCourseSelected.currentValue) {
      this.userSelected = { ...changes.userCourseSelected.currentValue.userapp }
      this.getFinaGrades()
    }
  }




  getFinaGrades() {
    this.userSubjectsSelected = []
    let gradesObservables = this.subjectGroups.map(element => {
      let filterUserSubject = {
        where: { and: [{ SubjectGroupID: element.id }, { UserID: this.userSelected.id }] },
        include: ["UserRecords"]
      }
      return (this.coursesServices.getUserSubjectsNew(JSON.stringify(filterUserSubject)));
    });
    // flat cant not be used, mixing of reduce and concat is implemented instead
    forkJoin(gradesObservables).subscribe({
      next: (data) => this.userSubjectsSelected = data.reduce(
        (arr, elem) => arr.concat(elem), []
      ),
      complete: () => {
        this.setUserData()
      },
    });



  }

  setUserData() {
    let tempData = [...this.academicAreas]
    this.renderData = []
    console.log("tempData", tempData)
    console.log("this.userSubjectsSelected", this.userSubjectsSelected)
    console.log("this.this.subjectGroups", this.subjectGroups)
    tempData.forEach(area => {
      area.subjects = this.subjectsByCourseAndSemester.filter(x => x.AcademicAreaID == area.id)

      area.subjects.forEach(subject => {
        subject.subjectGroup = this.subjectGroups.find(x => x.SubjectID == subject.id)
        if (subject.subjectGroup == null || subject.subjectGroup == undefined) delete subject.subjectGroup
        if (subject.subjectGroup) {
          let userSubectBySubjectGroup = this.userSubjectsSelected.find(x => x.SubjectGroupID == subject.subjectGroup.id)
          subject.subjectGroup.FinalRecord = userSubectBySubjectGroup.FinalRecord
          subject.subjectGroup.absences = userSubectBySubjectGroup.Absences

          subject.subjectGroup.minTerms.forEach(midTerm => {
            midTerm.partialRecord = userSubectBySubjectGroup.UserRecords.find(x => x.MidTermID == midTerm.id).ParcialRecord
            midTerm.remedialGrade = userSubectBySubjectGroup.UserRecords.find(x => x.MidTermID == midTerm.id).remedialGrade
            midTerm.absences = userSubectBySubjectGroup.UserRecords.find(x => x.MidTermID == midTerm.id).absences
          });
        }
      })

    });
    this.renderData = tempData.sort((a, b) => a.NameArea.localeCompare(b.NameArea))
    this.getFinalGradeByAcademicArea()

    console.log("se va a renderizar", this.renderData)
  }


  getFinalGradeByAcademicArea() {

    this.renderData.forEach(academicArea => {
      let finalGrade = 0
      academicArea.subjects.forEach(subject => {
        if (subject.subjectGroup && this.midTermNumSelected > 0) {
          let midtermByNum = subject.subjectGroup.minTerms.find(x => x.MidTermNum == this.midTermNumSelected)
          finalGrade += midtermByNum.remedialGrade ? midtermByNum.remedialGrade * (subject.Creditos / 100) : midtermByNum.partialRecord * (subject.Creditos / 100)
        }
        else if (subject.subjectGroup && this.midTermNumSelected == -1)
          finalGrade += subject.subjectGroup.FinalRecord * (subject.Creditos / 100)
      });

      academicArea.areaFinalGrade = parseFloat(String(finalGrade)).toFixed(2)

    });

    console.log("this.this.renderData nuevo",this.renderData)

  }



  getGradeByMidterm(subject, returnNumber?: boolean): number | string {
    if (!subject)
      return "/"
    let subjectGroup = subject.subjectGroup
    if (!subjectGroup)
      return "//"
    if (!subjectGroup.minTerms)
      return "--"


    let currentValue = null
    console.log("midTermNumSelected", this.midTermNumSelected)
    let midTerm = null
    if (this.midTermNumSelected > 0) {
      midTerm = subjectGroup.minTerms.find(x => x.MidTermNum === this.midTermNumSelected)
      if (midTerm)
        currentValue = midTerm.remedialGrade ? midTerm.remedialGrade : midTerm.partialRecord
      else
        return "NO REGISTRA"
    }
    if (this.midTermNumSelected == -1) //Nota final
      currentValue = subjectGroup.FinalRecord

    if (subject.TypeRecord != "Cualitativo")
      return currentValue

    if (subject.TypeRecord == "Cualitativo") {


      if (this.qualitativeDescriptions.length > 0) {
        for (const item of this.qualitativeDescriptions) {
          const [min, max] = item.range.split('-').map(Number);
          if (currentValue >= min && currentValue <= max) {
            return item.showImage ? this.sanitizer.bypassSecurityTrustHtml(`<img src="${environment.URL_BACK_V2 + item.imageURL}"  width="40px" alt="${currentValue}" class="note-icon"/> `) : item.qualitativeName;
          }
        }
      }


    }

  }

  

  getAbsencesByMidterm(subject): number | string {
    if (!subject)
      return "/"
    let subjectGroup = subject.subjectGroup
    if (!subjectGroup)
      return "//"
    if (!subjectGroup.minTerms)
      return "--"

    let midTerm = null
    if (this.midTermNumSelected > 0) {
      midTerm = subjectGroup.minTerms.find(x => x.MidTermNum === this.midTermNumSelected)
      if (midTerm)
        return midTerm.absences
      else
        return "NO REGISTRA"
    }
    if (this.midTermNumSelected == -1) //Nota final
      return subjectGroup.absences



  }

}
