import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { PagesRegister } from './public/signup/signup.page';
import { PolicyComponent } from './public/privacy-policy/privacy-policy.component';

import { ProfileComponent } from './structure/profile/profile.component';
import { TutorialComponent } from './structure/tutorial/tutorial.component';
import { CvPublicComponent } from './structure/cv-public/cv-public.component';
import { StructureModule } from './structure/structure.module';
import { modProfesor } from './structure/modUser/modprofesor/modprofesor.module';
import { modStudent } from './structure/modUser/modstudent/modstudent.module';
import { TutorialalumniComponent } from './structure/tutorialalumni/tutorialalumni.component';



const routes: Routes = [
  {
    path: 'register',
    component: PagesRegister
  },
  {
    path: 'privacy-policy',
    component: PolicyComponent,
  },
  {
    path: 'user/my-account',
    component: ProfileComponent
  },
  {
    path: '',
    redirectTo: '/user/my-account',
    pathMatch: 'full'
  }, // redirect to `first-component`
  {
    path: 'Cv-Public/:id',
    component: CvPublicComponent
  },
  {
    path: 'user/tutorial',
    component: TutorialComponent
  },
  {
    path: 'user/tutorialalumni',
    component: TutorialalumniComponent
  },
  {
    path: '**',
    component: ProfileComponent
  }
];

@NgModule( {
  imports: [
    RouterModule.forRoot( routes ),
    StructureModule,
    modProfesor,
    modStudent
  ],
  exports: [
    RouterModule,
    StructureModule,
    modProfesor,
    modStudent
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' }
  ]
} )
export class AppRoutingModule { }
