import {Component, Input, OnInit} from "@angular/core"

@Component({
  selector: "tutorial-container",
  templateUrl: "./tutorial-container.component.html",
  styleUrls: ["./tutorial-container.component.css"],
})
export class TutorialContainerComponent {
  @Input("tutorialCover") tutorialCover: string
  @Input("tutorialLink") tutorialLink: string
  @Input("coverCategory") coverCategory: string
  @Input("tutorialName") tutorialName: string
  @Input("appName") appName: string
}
