export class CourseOfer{

    constructor(

			public id?:number,
			public NameCourseOfer?:string,
			public CourseID?: number,
			public IsActive?: boolean,
			public Since?: Date,
			public Until?: Date,
			public RealSince?: Date,
			public RealUntil?: Date,
			public SchoolID?: number,
			public Price?: string,
			public TypeCourseID?: number,
			public IsSemestralize?: boolean,
			public DepartamentID?: number,
			public TypeTrainingID?: number,
			public Altitud?: string,
			public Longitud?: string,
			public InscriptStart?: Date,
			public InscriptEnd?: Date,
			public SemesterNumber?: number,
			public ModalityID?: number,
			public TotalPeople?: number,
			public OferState?: number,
			public Profiles?: string,
			public Forces?: string,
			public ForeignPeople?: number,
			public IsDirectiva?: boolean,
			public year?: number,
			public infoMidterms?: JSON,
			public createdBy?: number,
			public createdAt?: Date,
			public approvedBy?: number,
			public approvedAt?: Date
    ){

    }
}