import { Injectable } from '@angular/core';
import {Work} from './work';
import { AuthService} from "../Auth/auth.service";

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


@Injectable()
export class WorkService {


    constructor(private http : HttpClient, private authService: AuthService){
  }
  private baseUrl: string = this.authService.UrlAPI;


  getWorkByUserID(id:number) :Observable<any> {
     let TypeCourses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/WorkInfos/?filter={"order": "id DESC" }`, { headers: this.getHeaders()})
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      ) 
      return TypeCourses$;
  }


   getWorkByID(id:number) :Observable<any> {
     let TypeCourses$ = this.http
      .get(`${this.baseUrl}/WorkInfos/${id}`, { headers: this.putHeaders()})
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      ) 
      return TypeCourses$;
  }




   saveWork(work: Work): Observable<any>{
     return this
      .http
      .post(`${this.baseUrl}/Userapps/${work.UserID}/WorkInfos`, JSON.stringify(work) , { headers: this.putHeaders()})
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      ) 
     
  }


  UpdateWorkByID(work: Work): Observable<any>{
     return this
      .http
      .put(`${this.baseUrl}/Userapps/${work.UserID}/WorkInfos/${work.id}`, JSON.stringify(work) , { headers: this.putHeaders()})
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      ) 
     
  }


   DeleteWorkByID(UserID,WorkID): Observable<any>{
     return this
      .http
      .delete(`${this.baseUrl}/Userapps/${UserID}/WorkInfos/${WorkID}`,  { headers: this.putHeaders()})
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      ) 
     
  }



  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }
  
  putHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }



}



