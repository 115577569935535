import { UserCVComponent } from '../../user-cv.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersField } from 'app/models/usersInfo/users-fields';
import { Course } from 'app/models/Coursesinfo/course';
import { UsersService } from 'app/models/usersInfo/users.service';
import { ScientificService } from 'app/models/Scientificinfo/scientific.service';
import { Scientific } from 'app/models/Scientificinfo/scientific';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { AuthService } from 'app/models/Auth/auth.service';

declare var jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'edit-scientificinfo',
  templateUrl: './edit-scientificinfos.component.html',
  styleUrls: ['./edit-scientificinfos.component.css']
})


export class EditScientificInfoComponent implements OnInit {
  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/];
  User_info: UsersField = new UsersField();

  ScientificInfoEdit: any[];
  courses: Course[];
  user: UsersField;
  TypeCourses: any[];
  DatosDisponiblesEditarScientific: boolean
  TypePublications: any[];
  stateButton: boolean = false
  isok: Boolean;
  errorMessage: string = '';
  submitbuton: string = 'Actualizar'
  fileToUpload: File;
  imagebuton = "Subir Archivo";
  isImageError = true;
  enablesavefile = false
  buttonSoporte = "Subir Soporte";

  constructor(public userCVComponent: UserCVComponent,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public scientificService: ScientificService,
    private router: Router,
    public attachmentService: AttachmentService,
    private authService: AuthService) {

  }





  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {
    this.DatosDisponiblesEditarScientific = this.userCVComponent.DatosDisponiblesEditarScientific;



    this.scientificService.getAllScientificPublication().subscribe(p => this.TypePublications = p);





  }


  ReloadWindow() {


  }
  uploadFile(fileInput: any) {

    console.log(this.userCVComponent.EditScientific)

    this.userCVComponent.EditScientific["newCertificate"] = <File>fileInput.target.files[0];
    if (this.userCVComponent.EditScientific["newCertificate"].size > 1000000) {//1M
      this.userCVComponent.EditScientific["stateUploadFile"] = 'Error de Archivo, No debe superar 1MB'

      this.userCVComponent.EditScientific["enablesavefile"] = false
    }
    else {
      this.userCVComponent.EditScientific["stateUploadFile"] = 'Archivo OK'

      this.userCVComponent.EditScientific["enablesavefile"] = true
    }
  }

  EditScientificInfo(model: Scientific) {

    this.stateButton = true

    console.debug(model);
    // this.errorMessage="Validando ... ";
    this.submitbuton = "Validando ... ";

    //REVISO SI HAY NUEVO ARCHIVO CARGADO LISTO PARA GUARDAR
    if (this.userCVComponent.EditScientific["enablesavefile"] == true) {


      this.attachmentService.NewScientificInfoSuports(this.userCVComponent.EditScientific["newCertificate"]).then((result) => {

        model.Soporte = "/attachments/scientificinfo/download/" + result['name'];

        this.scientificService.UpdateScientificByID(model).subscribe(
          p => {
            this.isok = true;
            this.stateButton = false
            this.userCVComponent.ngOnInit();

            //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
            setTimeout(function () {
              jQuery("#ModalEditScientific").modal('hide');
            }, 500);

            this.userCVComponent.EditScientific = []
            this.submitbuton = "Actualizar";


          },
          e => {
            this.errorMessage = e;
            this.isok = false;

            this.stateButton = false
            this.errorMessage = "Corrija los datos erróneos";
            this.submitbuton = 'Actualizar'
          });
      }, (error) => {
        console.error(error);
        this.authService.swalError(
          "Error en Archivo",
          ""
        );
      })




    }
    else {

      this.scientificService.UpdateScientificByID(model).subscribe(
        p => {
          this.isok = true;
          this.stateButton = false
          this.userCVComponent.ngOnInit();

          //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
          setTimeout(function () {
            jQuery("#ModalEditScientific").modal('hide');
          }, 500);

          this.userCVComponent.EditScientific = []
          this.submitbuton = "Actualizar";


        },
        e => {
          this.errorMessage = e;
          this.isok = false;

          this.stateButton = false
          this.errorMessage = "Corrija los datos erróneos";
          this.submitbuton = 'Actualizar'
        });

    }



  }



}


