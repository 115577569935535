import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import swal from 'sweetalert2'
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { UsersService } from '../../../../models/usersInfo/users.service';
import { AuthService } from '../../../../models/Auth/auth.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'MyRecordsHistory',
  templateUrl: './my-records-history.component.html'
})

export class MyRecordsHistoryComponent {

  settingsMyRecord = {
    noDataMessage: "No hay datos disponibles",

    actions: false,
    add: {
      addButtonContent: '',
      createButtonContent: '',
      cancelButtonContent: '',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '',
      saveButtonContent: '',
      cancelButtonContent: '',
      confirmSave: true

    },
    pager: {
      perPage: 20,
    },
    delete: {
      deleteButtonContent: '',
      confirmDelete: true
    },
    columns: {
      NameSubject: {
        title: 'Nombre de Materia',
        filter: false,
        editable: false,
        width: '600px'
      },

      Record1: {
        title: 'Nota 1',
        filter: false,
        editable: false
      },
      Porcent1: {
        title: '%',
        filter: false,
        editable: false
      },

      Record2: {
        title: 'Nota 2',
        filter: false,
        editable: false
      },
      Porcent2: {
        title: '%',
        filter: false,
        editable: false
      },
      Record3: {
        title: 'Nota 3',
        filter: false,
        editable: false
      },
      Porcent3: {
        title: '%',
        filter: false,
        editable: false
      },

      FinalRecord: {
        title: 'Definitiva',
        filter: false,
        editable: false
      }
    }
  };

  sourceMyRecord = new LocalDataSource(); // create the source
  public userid: any
  public Records: any = []
  public Records_temp: any = []
  public Courses: any[];
  nameGroups: any[] = [];
  public groupSearch = ""

  constructor(
    private coursesService: CoursesService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    this.userid = this.authService.getCurrentUser();

  }

  ngOnInit() {

    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      this.userid = this.authService.getCurrentUser();



      swal({
        type: 'success',
        title: "Cargando Cursos",
        text: "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,

        onOpen: function () {
          swal.showLoading()
        }
      })





      this.coursesService.getSubjectsByUserID(parseInt(this.userid)).subscribe(response => {
        if (response.length == 0) {
          swal({
            type: 'error',
            title: "No se encontró historial de materias",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,


          })
        }
        else {


          this.Records_temp = response
          // console.log(response)
          for (var index = 0; index < response.length; index++) {

            //ORGANIZAR LOS MIDTERMS DE LA RESPUESTA
            var mid = response[index]["subjectGroup"].dateRecords;
            response[index]["subjectGroup"].dateRecords = mid.sort(function (a, b) {
              // SE ORDENA DE FORMA ASCENDENTE
              return a["id"] == b["id"] ? 0 : a["id"] < b["id"] ? 1 : -1;
            });



            /*
                        var loadInfo = {
            
                          NameSubject: response[index]["subjectGroup"]["coursesgroup"].name.toUpperCase() + ", " + response[index]["subjectGroup"]["subjects"].NameSubject.toUpperCase(),
                          FinalRecord: response[index].FinalRecord
                        }
            */
            // this.Records = this.Records.concat(loadInfo)

          }


          this.Records = this.Records.sort(function (a, b) {
            // Ascending: first age less than the previous
            return a["NameSubject"].localeCompare(b["NameSubject"]);
          });


          //BUSCO LOS MIDTERMS DE LOS RESULTADOS
          var searchUserRecordsByMidterms = []

          for (var index = 0; index < this.Records_temp.length; index++) {
            // console.log(this.Records_temp[index]["subjectGroup"]["minTerms"].length)
            for (var index2 = 0; index2 < this.Records_temp[index]["subjectGroup"]["minTerms"].length; index2++) {
              if (!searchUserRecordsByMidterms.includes(this.Records_temp[index]["subjectGroup"]["minTerms"][index2].id))
                searchUserRecordsByMidterms = searchUserRecordsByMidterms.concat([this.Records_temp[index]["subjectGroup"]["minTerms"][index2].id])
            }

          }

          if (searchUserRecordsByMidterms.length == 0) {
            swal({
              type: 'error',
              title: "No se encontró historial de materias",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowOutsideClick: false,


            })
          }
          else {

            //CREO EL FILTRO PARA BUSCAR EN EL API
            var filterarray = []
            for (var index = 0; index < searchUserRecordsByMidterms.length; index++) {
              filterarray.push({ "MidTermID": searchUserRecordsByMidterms[index] })
            }
            var filterdb = `{"where": { "or": ` + JSON.stringify(filterarray) + `}}`;

            // console.log(filterdb)
            //consulto todos los UserRecord por el filtro
            this.coursesService.getUserRecordsByMidtermsFilter(filterdb).subscribe(responseRecords => {
              console.log(responseRecords, 'aaaaaaaaa');
              

              //AGREGAR LAS NOTAS A TODO EL HISTORIAL DEL ESTUDIANTE
              for (var index = 0; index < this.Records_temp.length; index++) {

                for (var index2 = 0; index2 < this.Records_temp[index]["subjectGroup"]["dateRecords"].length; index2++) {
                  //console.log("se va a buscar el midterm " +this.Records_temp[index]["subjectGroup"]["dateRecords"][index2].MidTermID + " con el usersubjecid= "+ this.Records_temp[index].id)
                  var pos = []
                  //BUSCO LAS POSICIONES DONDE EL COURSEODDERID DE LA VARIABLE TEMP ESTE EN EL ID DEL CURSO CONSULTADO
                  for (var k = 0; k < responseRecords.length; k++) {
                    if ((responseRecords[k].MidTermID == this.Records_temp[index]["subjectGroup"]["dateRecords"][index2].MidTermID) && (responseRecords[k].UserSubjectID == this.Records_temp[index].id))
                      pos = pos.concat([k])
                  }

                  if (pos.length > 0) {
                    this.Records_temp[index]["subjectGroup"]["dateRecords"][index2]["UserRecords"] = []
                    //CON LAS POSICIONES CONCATENO EL LOS RESULTADOS EN DATERECORDS 
                    for (var k = 0; k < pos.length; k++) {

                      //CREO UN CAMPO EN EL USERRECORDS CON LAS NOTAS                 
                      this.Records_temp[index]["subjectGroup"]["dateRecords"][index2]["UserRecords"].push(responseRecords[pos[k]])
                      // console.log("la poicion es " + pos[k])
                      //console.log("se va a buscar el midterm " + this.Records_temp[index]["subjectGroup"]["dateRecords"][index2].MidTermID + " con el usersubjecid= " + this.Records_temp[index].id)
                      // console.log(responseRecords[pos[k]])
                    }

                    //ORDENAR POR EL DATERECORD ID
                    var order = this.Records_temp[index]["subjectGroup"]["dateRecords"][index2]["UserRecords"]

                    this.Records_temp[index]["subjectGroup"]["dateRecords"][index2]["UserRecords"] = this.Records_temp[index]["subjectGroup"]["dateRecords"][index2]["UserRecords"].sort(function (a, b) {
                      // SE ORDENA DE FORMA DESCENDENTE
                      return a["DateRecordID"] == b["DateRecordID"] ? 0 : a["DateRecordID"] < b["DateRecordID"] ? 1 : -1;
                    });



                  }





                }
              }


              //CARGAR LAS ULTIMAS NOTAS DEL RECORD ACORDE AL MIDTERM Y AGREGAR  AL SMART TABLE
              for (var index = 0; index < this.Records_temp.length; index++) {
                //ORDENO LOS MIDTERMS
                this.Records_temp[index]["subjectGroup"]["minTerms"] = this.Records_temp[index]["subjectGroup"]["minTerms"].sort(function (a, b) {
                  // SE ORDENA DE FORMA ASCENDENTE
                  return a["MidTermNum"] == b["MidTermNum"] ? 0 : a["MidTermNum"] < b["MidTermNum"] ? -1 : 1;
                });


                //reviso los midterms
                for (var index2 = 0; index2 < this.Records_temp[index]["subjectGroup"]["minTerms"].length; index2++) {

                  //BUSCO LA PRIMERA POSICION QUE COINCIDA CON EL MIDTERMID
                  var pos2 = -1;
                  for (var k = 0; k < this.Records_temp[index]["subjectGroup"]["dateRecords"].length; k++) {
                    if (this.Records_temp[index]["subjectGroup"]["minTerms"][index2].id == this.Records_temp[index]["subjectGroup"]["dateRecords"][k].MidTermID) {
                      var pos2 = k
                      //console.log("la posicion 2 es" + pos2)
                      break
                    }
                  }

                  if (pos2 >= 0) {
                    if (!isNullOrUndefined(this.Records_temp[index]["subjectGroup"]["dateRecords"][pos2]["UserRecords"]))
                      this.Records_temp[index]["subjectGroup"]["minTerms"][index2]["Note"] = this.Records_temp[index]["subjectGroup"]["dateRecords"][pos2]["UserRecords"][0].ParcialRecord
                  }
                }


              }



              //UNA VEZ ORDENADAS LAS NOTAS QUE SON EN EL MIDTERM CORRESPONDIENTE AJUSTAR LA VARIABLE 


              for (var index = 0; index < this.Records_temp.length; index++) {



                var loadInfo = {

                  NameSubject: this.Records_temp[index]["subjectGroup"]["coursesgroup"].name.toUpperCase() + ", " + response[index]["subjectGroup"]["subjects"].NameSubject.toUpperCase(),
                  FinalRecord: this.Records_temp[index].FinalRecord
                }


                for (var index2 = 0; index2 < this.Records_temp[index]["subjectGroup"]["minTerms"].length; index2++) {

                  loadInfo["Porcent" + (index2 + 1)] = this.Records_temp[index]["subjectGroup"]["minTerms"][index2].Porcent
                  loadInfo["Record" + (index2 + 1)] = this.Records_temp[index]["subjectGroup"]["minTerms"][index2].Note
                  loadInfo["CourseGroup" + (index2 + 1)] = this.Records_temp[index]["subjectGroup"]["coursesgroup"].name.toUpperCase()

                }

                this.Records = this.Records.concat(loadInfo)




              }


              // console.log(responseRecords)
              // console.log(this.Records_temp)




              this.sourceMyRecord.load(this.Records)
              var posSearch = 0;
              for (var indexSearch = 0; indexSearch < this.Records.length; indexSearch++) {
                var element = this.Records[indexSearch];
                posSearch = 0;
                posSearch = this.nameGroups.findIndex(x => x.name === element.CourseGroup1)
                if (posSearch == -1) {
                  this.nameGroups.push({ name: element.CourseGroup1 })
                }

              }



              console.log(this.nameGroups)

              console.log(this.Records)


              swal({
                type: 'success',
                title: "Cursos Cargados",
                timer: 10,
                text: "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet",
                showConfirmButton: true,
                allowEscapeKey: false,
                allowOutsideClick: false,


              })




            })

          }




        }



      })





      /*
            this.coursesService.getAceptedUserCoursesByUserID(parseInt(this.userid)).subscribe(response => {
      
      
              this.Records_temp = response
      
              console.debug(this.Records_temp)
      
      
      
      
            })
      */







    }


  }


  ngOnDestroy() {

  }

  onSearch(query: string = '') {

    
      query = query.replace(/\s/g, '');

      this.sourceMyRecord.setFilter([
        {
          field: 'NameSubject',
          search: query
        }
      ], true);
    
  }



  onSearchGroup(){

    if(this.groupSearch!=""){
      this.sourceMyRecord.setFilter([])
      this.sourceMyRecord.setFilter([
        {
          field: 'CourseGroup1',
          search: this.groupSearch
        }
      ], false);

    }

  }



}