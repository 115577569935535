import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Course } from '../../models/Coursesinfo/course';
import { Module } from '../../models/Coursesinfo/module';
import { School } from '../../models/Coursesinfo/school';
import { CoursesService } from "../../models/Coursesinfo/courses.service";
import { UsersField } from '../../models/usersInfo/users-fields';
import { Force } from '../../models/usersInfo/force';
import { Range } from '../../models/usersInfo/ranges';
import { TypeDocument } from '../../models/usersInfo/typedocument';
import { UsersService } from "../../models/usersInfo/users.service";

import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../../models/Auth/auth.service';
import { take } from 'rxjs/operators';
import { environment } from 'environments/environment';


declare const google: any;
var ctx;
export interface FormModel {
  captcha?: string;
}

@Component( {
  selector: 'cat-page',
  templateUrl: './signup.old.html',
  styles: [ `
  .error { color: crimson; }
  .success { color: green; }
` ]
} )

export class PagesRegister implements OnInit, OnDestroy {

  showLoginBox: boolean = true;
  showDataLogin: number = 1;

  public auth2: any;

  courses: Course[];
  modules: Module[];
  schools: School[];
  typeDocuments: TypeDocument[];
  user: UsersField;
  forces: Force[];
  ranges: Range[];
  isok: Boolean;
  public formModel: FormModel = {};
  isCandidate = true;
  errorMessage: string = 'Si tiene una cuenta, por favor seleccione la opcion que corresponde';
  submitbuton: string = 'Aceptar';
  confirm: boolean = false;
  client = google.accounts.id;
  usercedoc: string = 'Nombre1Apellido1Apellido2@cedoc.edu.co';

  public currentDate: Date = new Date();

  public MAIN_SCHOOL_LOGO_IMG: any = environment.MAIN_SCHOOL_LOGO_IMG;
  public LINK_LOGOS:any = environment.LINK_LOGOS;
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;
  public SHOW_FOOTER = environment.SHOW_FOOTER;

  constructor ( private usersService: UsersService,
    private authService: AuthService,
    private coursesService: CoursesService,
    private zone: NgZone,
    private router: Router ) {


    ctx = this;
    this.client.initialize( {
      client_id: '682964296600-veptunpm5q6dqjmc7cghmftk20hhs8l5.apps.googleusercontent.com',
      callback: this.attachSignin,
    } );


  }

  ngOnInit() {


    this.user = {

      TypeDocumentID: 1,
      IsStudent: true,
      Document: '',
      Name1: '',
      LastName1: '',
      CellPhone: '',
      Email: '',
      CedulaMil: '',
      IsMilitar: true,
      SchoolID: 4,
      CourseOferID: 0,
      JobOferID: 0,
      ForceID: 1,
      OtanID: 1

    }
    this.isok = false;
    this.coursesService.getAllSchools().subscribe( p => this.schools = p );
    this.coursesService.getAllCoursesOfersBySchool( 4 ).subscribe( p => this.courses = p );
    this.coursesService.getAllAcademicAreasBySchool( 4 ).subscribe( p => this.modules = p );
    this.usersService.getAllForces().subscribe( p => this.forces = p );
    this.usersService.getAllTypeDocuments().subscribe( p => this.typeDocuments = p );
    this.usersService.getAllRangesByForce( 1 ).subscribe( p => this.ranges = p );
  }

  ngOnDestroy() {

  }


  loginGoogle() {

    this.client.renderButton(
      document.getElementById( "googleBtn" ),
      { theme: "filled_blue", size: "large" }  // customization attributes
    );

    this.client.prompt();

  }

  public attachSignin( response ) {

    console.log( response.credential )
    var data = { 'id_token': response.credential };
    ctx.authService.loginGoogle( data ).pipe( take( 1 ) ).subscribe(
      data => {
        ctx.authService.setToken( data.token );
        localStorage.setItem( "currentUser", data.user );

        ctx.zone.run( () => {
          window.location.href = '/user/my-account';
        } );
      }
    );


  }


  callType( model: UsersField ) {
    this.usersService.getAllRangesByForce( model.ForceID ).subscribe( p => this.ranges = p );
  }

  callSchool( model: UsersField ) {
    this.coursesService.getAllCoursesOfersBySchool( model.SchoolID ).subscribe( p => this.courses = p );
    this.coursesService.getAllAcademicAreasBySchool( model.SchoolID ).subscribe( p => this.modules = p );
  }
  savePersonDetails( model: UsersField, isValid: boolean ) {

    this.errorMessage = "Corriga los datos erroneos";
    if ( isValid ) {
      this.errorMessage = "Validando ... ";
      this.submitbuton = "Validando ... ";

      if ( model.IsStudent == true ) {

        this.usersService.createStudent( model ).subscribe(
          p => {
            this.isok = true;
            this.usercedoc = p.CedocEmail;
          },
          e => {
            this.submitbuton = "Error en datos";
            this.errorMessage = e;
            this.isok = false;
          } );



      } else {
        this.usersService.createInstructor( model ).subscribe(
          p => {
            this.isok = true;
            this.usercedoc = p.CedocEmail;
          },
          e => {
            this.submitbuton = "Error en datos";
            this.errorMessage = e;
            this.isok = false;
          } );

      }
    }

  }

  logInCandidate( model, isValid: boolean ) {
    if ( isValid ) {
      this.usersService.login( model.Email, model.CellPhone ).subscribe(
        p => {
          this.isok = true;
          localStorage.setItem( "currentUser", p.userId );
          localStorage.setItem( "accessToken", p.id );
          this.router.navigate( [ '/user/my-account' ] );
          location.reload();

        },
        e => {
          this.submitbuton = "Error en datos";
          this.errorMessage = e;
          // this.isok=false;
        } );
    }
  }

}







