import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Inscription } from '../../../../models/usersInfo/inscription';
import { UsersService } from '../../../../models/usersInfo/users.service';
import { AuthService } from '../../../../models/Auth/auth.service';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';
import { MailService } from 'app/models/mail/mail.service';
import { Mail } from 'app/models/mail/mail';
import { TypeCert } from 'app/models/usersInfo/type-cert';
import { UserCourseCert } from 'app/models/usersInfo/user-course-cert';
import { isNullOrUndefined } from 'util';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'environments/environment';

@Component({
    selector: 'MyInscription',
    templateUrl: './my-inscription.component.html',
    styleUrls: ['./my-inscription.component.css']
})

export class MyInscriptionComponent implements OnInit {

    userInscriptions: Inscription[];
    currentUserInscription: Inscription;
    IsShowCert = false
    userid: string;
    typecourseID: number = 0;
    schoolID: number = 0;
    fileToUpload: File;
    isImageError = true;
    enablesavefile = false;
    fileToUploadCert: File;
    isImageErrorCert = true;
    enablesavefileCert = false;
    textButtonCert: string = "Subir Recibo";
    textButton: string = "Subir Recibo De Pago";
    mailfields: Mail;
    typeCerts: TypeCert;
    userCerts: UserCourseCert[];
    newUserCert: UserCourseCert = {
        UserCourseID: 0,
        UserID: 0,
        SchoolID: 0,
        GPA: 0,
        Invoice: null,
        TypeCertID: 0,
    };
    userCourseOferID: number;

    public stateModalGetCertified: boolean = false;

    
    public MAIN_SCHOOL_LOGO_SVG:any = environment.MAIN_SCHOOL_LOGO_SVG;
    public CEMIL_ICON:any = environment.CEMIL_ICON;
    public EJC_ICON:any = environment.EJC_ICON;
    public UrlAPI:any = environment.UrlAPI;
    public SCHOOL_NAME:string = environment.SCHOOL_NAME;


    constructor(private usersService: UsersService,
        public attachmentService: AttachmentService,
        public coursesService: CoursesService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private mailService: MailService,
    ) {
        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            this.userid = this.authService.getCurrentUser();
            if (Number.isInteger(parseInt(this.userid))) {
                Swal({
                    type: 'success',
                    title: "Cargando historial de cursos",
                    text: "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet",
                    showConfirmButton: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,

                    onOpen: function () {
                        Swal.showLoading()
                    }
                })



                this.usersService.getInscrptionByUserID(parseInt(this.userid)).subscribe(p => {
                    Swal({
                        type: 'success',
                        title: "Cursos Cargados",
                        showConfirmButton: false,
                        timer: 10,

                    })
                    this.userInscriptions = p
                    this.userInscriptions.forEach(element => {

                        element["textButton"] = "Subir Recibo De Pago"
                    })
                    console.log('Curso', this.userInscriptions);

                })
            }

        }

    }

    ngOnInit() {

    }




    UploadFile(fileInput: any, FileError, index) {
        this.userInscriptions[index]["fileToUpload"] = <File>fileInput.target.files[0];
        if (this.userInscriptions[index]["fileToUpload"].size > 1000000) {//1M
            this.userInscriptions[index]["textButton"] = 'Error de Archivo'
            FileError = true

        }
        else {
            this.userInscriptions[index]["textButton"] = 'Archivo OK'
            FileError = false

        }
    }


    sendFile(model) {



        console.log(model)
        if (isNullOrUndefined(model.fileToUpload)) {
            this.authService.swalError("Error Al Enviar Solicitud", "Por favor adjunte recibo de pago.")
        }
        else {
            this.authService.swalLoading("Enviando Archivo")
            this.attachmentService.NewCourseRecipeSuports(model.fileToUpload).then((result) => {
                model.Invoice = "/attachments/courcerecipe/download/" + result['name'];
                this.coursesService.patchUserCourseById(model.id, { Invoice: model.Invoice }).subscribe(data => {
                    this.authService.swalEndLoad()
                }, err => {
                    this.authService.swalError("Error al guardar", "No se pudo actualizar la información")
                })
            }, (error) => {
                console.error(error);
                this.authService.swalError(
                    "Error en Archivo",
                    ""
                );
            })

        }


    }

    colorSchool(idSchool: number) {
        let classes = {};

        switch (idSchool) {
      case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


        }
        return classes;
    }

    showCert(currentUserInscription) {

        console.log(currentUserInscription);

        this.currentUserInscription = currentUserInscription

        this.usersService.getUserCourseCerts(this.currentUserInscription.id).subscribe(p => {
            this.userCerts = p
            console.log('Datos Cert', this.userCerts);

        })

        this.usersService.getTypeCerts(this.currentUserInscription.SchoolID).subscribe(p => {
            this.typeCerts = p
            console.log('Datos Cert', this.typeCerts);

        })

        this.usersService.getStudentRecordsByUserIDAndUCourseOferID(this.currentUserInscription.CourseOferID, this.currentUserInscription.UserID).subscribe(user => {
            console.log('Infor', user);

        })

        this.newUserCert = {
            UserCourseID: this.currentUserInscription.id,
            UserID: parseInt(this.userid),
            SchoolID: this.currentUserInscription.SchoolID,
            GPA: this.currentUserInscription.GPA,
            Invoice: null,
            TypeCertID: null,
        }





    }



    UploadFileCert(fileInput: any) {
        this.fileToUploadCert = <File>fileInput.target.files[0];
        if (this.fileToUploadCert.size > 1000000) {//1M
            this.textButtonCert = 'Error de Archivo'
        }
        else {
            this.textButtonCert = 'Archivo OK'
        }
    }


    sendFileCert(model: UserCourseCert, valid: boolean) {

        console.log("*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*");
        console.log("datos: ", UserCourseCert);
        console.log("*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*");
        console.log("datos: " + UserCourseCert);
        console.log('model', model)


        this.attachmentService.NewCourseRecipeSuports(this.fileToUploadCert).then((result) => {

            model.Invoice = "/attachments/courcerecipe/download/" + result['name'];


            this.usersService.sendCertRequest(model).subscribe(data => {

            }, err => {
                this.authService.swalError("Error al guardar", "No se pudo actualizar la información")
            })

        }, (error) => {
            console.error(error);
            this.authService.swalError(
                "Error en Archivo",
                ""
            );
        })

    }

    changeStateModalGetCertified(type: boolean, inscription?: any) {
        this.stateModalGetCertified = type;
        console.log('inscrip: ', inscription);
    }

}





