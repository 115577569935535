export class School{

    constructor(

    public id?:number,
    public NameTSchool?:string,
    public SchoolAcronim?:string,
    public ReportSchool?: string,
    public Icono?: string

    ){

    }
}