import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { Course } from '../../../models/Coursesinfo/course';
import { CoursesService } from "../../../models/Coursesinfo/courses.service";
import { UsersService } from "../../../models/usersInfo/users.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RecordService } from '../../../models/midterm/record.service';
import { AuthService } from '../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../components/profile-nav/profile-nav.component';
import { EvaluationsService } from '../../../models/evaluation/evaluations.service';

declare var $: any;
declare var Chart: any;

@Component( {
  moduleId: module.id,
  selector: 'evaluation-box',
  templateUrl: './evaluation-box.component.html',
  styleUrls: [ './evaluation-box.component.css' ],

} )


export class EvaluationBoxComponent implements OnInit {

  public Courses: Course[];
  public Subjects: Course[]
  public IsProfessor: boolean = false;
  public IsStudent: boolean = false;
  public IsStudent2: boolean = false;
  public SubjectsProfesor: Course[];
  public EventCalendarProfessor: any[] = []
  public EventCalendarColorIndexProfessor: any[] = []

  public EventCalendarStudent: any[] = []
  public EventCalendarColorIndexStudent: any[] = []

  TotalCat1: number = 0;
  TotalCat2: number = 0;
  TotalCat3: number = 0;
  TotalCat4: number = 0;
  TotalCat5: number = 0;
  TotalCat6: number = 0;
  TotalCat7: number = 0;
  TotalCat8: number = 0;
  TotalCat9: number = 0;
  TotalCat1S: number = 0;
  TotalCat2S: number = 0;
  TotalCat3S: number = 0;
  TotalCat4S: number = 0;
  TotalCat5S: number = 0;
  TotalCat6S: number = 0;
  TotalCat7S: number = 0;
  TotalCat8S: number = 0;
  TotalCat9S: number = 0;
  TotalCat1SA: number = 0;
  TotalCat2SA: number = 0;
  TotalCat3SA: number = 0;
  TotalCat4SA: number = 0;
  TotalCat5SA: number = 0;
  TotalCat6SA: number = 0;
  TotalCat7SA: number = 0;
  TotalCat8SA: number = 0;
  TotalCat9SA: number = 0;
  dataRadar: any
  ChartRadar: any
  IsInspector = false;

  public ColorCourses = [ "#5d80a2", "#680345", "#7C6445", "#E154CE", "#258C64", "#A44015", "#FE9A2E", "#DF0101", "#01DF01", "#0040FF", "#565ACB", "#144A20", "#AAE951", "#6CF132", "#F19663", "#891CBB", "#21EBF3", "#F19663", "#61265F", "#6916E4", "#464208", "#393D78", "#E2337D", "#A1924C", "#52B743", "#176548", "#A0B03D", "#7C188A", "#77BB3E" ]

  SchoolID: number = 0;
  subjectgroupsprofessor: any = []

  public idProfileCV: any;

  constructor (
    private recordService: RecordService,
    private authService: AuthService,
    private usersService: UsersService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    public profileNavComponent: ProfileNavComponent,
    public evaluationsservice: EvaluationsService

  ) {

  }

  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {
    var radarCtx = $( '#chart-radar-teacher' )[ 0 ].getContext( '2d' );

    this.dataRadar = {
      labels: [ "Planeación y Organización", "Conocimiento", "Pedagogía/Metodología", "Evaluación y Retroalimentación", "Relación y Comunicación", "Compromiso Institucional", "Currículo Propuesto", "Currículo En Uso", "Análisis Curricular" ],
      datasets: [
        {
          label: "Calidad Académica",
          backgroundColor: "rgba(47, 121, 238,0.2)",
          borderColor: "rgba(47, 121, 238,1)",
          pointBackgroundColor: "rgba(47, 121, 238,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(47, 121, 238,1)",
          data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
        }
        ,
        {
          label: "Evaluación Estudiantes",
          backgroundColor: "rgba(174, 238, 48,0.2)",
          borderColor: "rgba(174, 238, 48,1)",
          pointBackgroundColor: "rgba(174, 238, 48,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(174, 238, 48,1)",
          data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
        }
        ,
        {
          label: "Auto-Evaluación",
          backgroundColor: "rgba(238, 79, 48,0.2)",
          borderColor: "rgba(238, 79, 48, 1)",
          pointBackgroundColor: "rgba(238, 79, 48, 1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(238, 79, 48, 1)",
          data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
        }
      ]
    };

    this.ChartRadar = new Chart( radarCtx, {
      type: 'radar',
      data: this.dataRadar,
      options: {
        scale: {
          ticks: {
            max: 50,
            stepSize: 10,
            min: 0,
            beginAtZero: true
          }
        }
      }
    } );

    if ( isNullOrUndefined( this.idProfileCV ) ) {
      //NO HAGO NADA
    }
    else {
      this.coursesService.getSubjectByProfesorID( this.idProfileCV.id ).subscribe( responseProfessor => {
        //GUARDO TODOS LOS SUBJECTGROUPS
        for ( var index = 0; index < responseProfessor.length; index++ ) {
          this.subjectgroupsprofessor.push( responseProfessor[ index ].id )
        }

        //CREO EL FILTER PARA CONSULTAR TODAS LAS NOTAS
        var filterarray = []
        for ( var index = 0; index < this.subjectgroupsprofessor.length; index++ ) {
          filterarray.push( { "SubjectGroupID": this.subjectgroupsprofessor[ index ] } )
        }
        var filterdb = `{"where": { "or": ` + JSON.stringify( filterarray ) + `}}`;
        //consulto las calificaciones de calidad
        this.evaluationsservice.getDelegateBySubjectGroup( filterdb ).subscribe( responseDelegate => {
          console.log( "DE DELEGATE LLEGO", responseDelegate )
          //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
          for ( var i = 0; i < responseDelegate.length; i++ ) {

            this.TotalCat1 += responseDelegate[ i ].TotalCat1
            this.TotalCat2 += responseDelegate[ i ].TotalCat2
            this.TotalCat3 += responseDelegate[ i ].TotalCat3
            this.TotalCat4 += responseDelegate[ i ].TotalCat4
            this.TotalCat5 += responseDelegate[ i ].TotalCat5
            this.TotalCat6 += responseDelegate[ i ].TotalCat6
            this.TotalCat7 += responseDelegate[ i ].TotalCat7
            this.TotalCat8 += responseDelegate[ i ].TotalCat8
            this.TotalCat9 += responseDelegate[ i ].TotalCat9
          }

          this.TotalCat1 = Number( ( this.TotalCat1 / responseDelegate.length ).toFixed( 1 ) );
          this.TotalCat2 = Number( ( this.TotalCat2 / responseDelegate.length ).toFixed( 1 ) );
          this.TotalCat3 = Number( ( this.TotalCat3 / responseDelegate.length ).toFixed( 1 ) );
          this.TotalCat4 = Number( ( this.TotalCat4 / responseDelegate.length ).toFixed( 1 ) );
          this.TotalCat5 = Number( ( this.TotalCat5 / responseDelegate.length ).toFixed( 1 ) );
          this.TotalCat6 = Number( ( this.TotalCat6 / responseDelegate.length ).toFixed( 1 ) );
          this.TotalCat7 = Number( ( this.TotalCat7 / responseDelegate.length ).toFixed( 1 ) );
          this.TotalCat8 = Number( ( this.TotalCat8 / responseDelegate.length ).toFixed( 1 ) );
          this.TotalCat9 = Number( ( this.TotalCat9 / responseDelegate.length ).toFixed( 1 ) );

          this.dataRadar.datasets[ 0 ].data = [ this.TotalCat1, this.TotalCat2, this.TotalCat3, this.TotalCat4, this.TotalCat5, this.TotalCat6, this.TotalCat7, this.TotalCat8, this.TotalCat9 ]
          //this.ChartRadar.removeData();
          // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
          console.log( this.dataRadar.datasets[ 0 ].data )
          this.ChartRadar.update();
        } )

        //consulto las calificaciones de estudianntes
        this.evaluationsservice.getStudentsApprBySubjectGroup( filterdb ).subscribe( responseStudent => {
          console.log( "DE ESTUDENT LLEGO", responseStudent )
          //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
          for ( var i = 0; i < responseStudent.length; i++ ) {

            this.TotalCat1SA += responseStudent[ i ].TotalCat1
            this.TotalCat2SA += responseStudent[ i ].TotalCat2
            this.TotalCat3SA += responseStudent[ i ].TotalCat3
            this.TotalCat4SA += responseStudent[ i ].TotalCat4
            this.TotalCat5SA += responseStudent[ i ].TotalCat5
            this.TotalCat6SA += responseStudent[ i ].TotalCat6
            this.TotalCat7SA += responseStudent[ i ].TotalCat7
            this.TotalCat8SA += responseStudent[ i ].TotalCat8
            this.TotalCat9SA += responseStudent[ i ].TotalCat9
          }

          this.TotalCat1SA = Number( ( this.TotalCat1SA / responseStudent.length ).toFixed( 1 ) );
          this.TotalCat2SA = Number( ( this.TotalCat2SA / responseStudent.length ).toFixed( 1 ) );
          this.TotalCat3SA = Number( ( this.TotalCat3SA / responseStudent.length ).toFixed( 1 ) );
          this.TotalCat4SA = Number( ( this.TotalCat4SA / responseStudent.length ).toFixed( 1 ) );
          this.TotalCat5SA = Number( ( this.TotalCat5SA / responseStudent.length ).toFixed( 1 ) );
          this.TotalCat6SA = Number( ( this.TotalCat6SA / responseStudent.length ).toFixed( 1 ) );
          this.TotalCat7SA = Number( ( this.TotalCat7SA / responseStudent.length ).toFixed( 1 ) );
          this.TotalCat8SA = Number( ( this.TotalCat8SA / responseStudent.length ).toFixed( 1 ) );
          this.TotalCat9SA = Number( ( this.TotalCat9SA / responseStudent.length ).toFixed( 1 ) );

          this.dataRadar.datasets[ 1 ].data = [ this.TotalCat1SA, this.TotalCat2SA, this.TotalCat3SA, this.TotalCat4SA, this.TotalCat5SA, this.TotalCat6SA, this.TotalCat7SA, this.TotalCat8SA, this.TotalCat9SA ]
          console.log( this.dataRadar.datasets[ 1 ].data )
          this.ChartRadar.update();
        } )

        //consulto las calificaciones del docente
        this.evaluationsservice.getSelfsApprBySubjectGroup( this.idProfileCV.id ).subscribe( responseSelf => {
          console.log( "de self llego:", responseSelf )
          //CALCULAR EL PROMEDIO DE TODAS LAS NOTAS
          for ( var i = 0; i < responseSelf.length; i++ ) {
            this.TotalCat1S += responseSelf[ i ].TotalCat1
            this.TotalCat2S += responseSelf[ i ].TotalCat2
            this.TotalCat3S += responseSelf[ i ].TotalCat3
            this.TotalCat4S += responseSelf[ i ].TotalCat4
            this.TotalCat5S += responseSelf[ i ].TotalCat5
            this.TotalCat6S += responseSelf[ i ].TotalCat6
            this.TotalCat7S += responseSelf[ i ].TotalCat7
            this.TotalCat8S += responseSelf[ i ].TotalCat8
            this.TotalCat9S += responseSelf[ i ].TotalCat9
          }

          this.TotalCat1S = Number( ( this.TotalCat1S / responseSelf.length ).toFixed( 1 ) );
          this.TotalCat2S = Number( ( this.TotalCat2S / responseSelf.length ).toFixed( 1 ) );
          this.TotalCat3S = Number( ( this.TotalCat3S / responseSelf.length ).toFixed( 1 ) );
          this.TotalCat4S = Number( ( this.TotalCat4S / responseSelf.length ).toFixed( 1 ) );
          this.TotalCat5S = Number( ( this.TotalCat5S / responseSelf.length ).toFixed( 1 ) );
          this.TotalCat6S = Number( ( this.TotalCat6S / responseSelf.length ).toFixed( 1 ) );
          this.TotalCat7S = Number( ( this.TotalCat7S / responseSelf.length ).toFixed( 1 ) );
          this.TotalCat8S = Number( ( this.TotalCat8S / responseSelf.length ).toFixed( 1 ) );
          this.TotalCat9S = Number( ( this.TotalCat9S / responseSelf.length ).toFixed( 1 ) );

          this.dataRadar.datasets[ 2 ].data = [ this.TotalCat1S, this.TotalCat2S, this.TotalCat3S, this.TotalCat4S, this.TotalCat5S, this.TotalCat6S, this.TotalCat7S, this.TotalCat8S, this.TotalCat9S ]
          //this.ChartRadar.removeData();
          // this.ChartRadar.addData(this.dataRadar.datasets[0].data);
          console.log( this.dataRadar.datasets[ 2 ].data )
          this.ChartRadar.update();
        } )
      } )
    }
  }

  ngAfterViewInit() {
  }
}