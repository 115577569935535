import { Component,OnInit } from '@angular/core';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
  DAYS_OF_WEEK
} from 'angular-calendar';
import { Subject, of } from 'rxjs';
import { colors } from './colors';

import * as moment from 'moment'
import { switchMap, take } from 'rxjs/operators';
import { ScheduleService } from 'app/models/schedule/schedule.service';


@Component( {
  selector: 'student-calendar',
  templateUrl: 'student-calendar.component.html',
  styleUrls: [ './student-calendar.component.css'],
} )
export class StudentCalendarComponent implements OnInit {

  constructor (
    private _scheduleService: ScheduleService,
  ) { }

  public locale: string = 'es';
  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [ DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY ]
  public actions: CalendarEventAction[] = [
    {
      label: '<i class="fa  fa-trash"></i>',
      a11yLabel: 'Delete',
      onClick: ( { event }: { event: CalendarEvent } ): void => {
        this.events = this.events.filter( ( iEvent ) => iEvent !== event );
      },
    },
  ];

  public calendarView = CalendarView;
  public view = CalendarView.Week;
  public viewDate = new Date();
  public events: CalendarEvent[] = [];
  public activeDayIsOpen = true;
  public refresh = new Subject<void>();

  //

  ngOnInit(): void {
    this.getActiveUserCourses();
  }

  private getActiveUserCourses() {
    const filter = { where: { and: [{ IsAcepted: true }] } };
    this._scheduleService.myUserCourses(JSON.stringify(filter))
    .pipe(
      switchMap(data => {
        if(data.length === 0)
          return of([]);

        const courseGroupsID = data.map(x => x.CourseGroupID);
        const filterSubjectGroups = {
          where: { and: [{ CourseGroupID: { inq: courseGroupsID } }] },
          include: ['scheduleSubjects', 'userapp', 'subjects', 'coursesgroup']
        };

        return this._scheduleService.getSubjectGroups(JSON.stringify(filterSubjectGroups));
      })
    )
    .subscribe({
      next:(data:any[])=>{
        this.processSubjectGroups(data);
      },error:(err)=>{
        console.log(err);
      }
    });

  }

  private processSubjectGroups(subjectGroups :any[]){
    subjectGroups.forEach( (subjectGroup, index)  =>{
      const scheduleSubject:any[] = subjectGroup.scheduleSubjects;
      const subject = subjectGroup.subjects;
      const courseGroup = subjectGroup.coursesgroup;

      scheduleSubject.forEach((schedule)=>{
        const event = {
          title: `${subject.NameSubject} - ${courseGroup.name}`,
          color: colors[ index % colors.length ],
          start: moment(schedule.HourStart).toDate(),
          end: moment(schedule.HourEnd).toDate(),
          resizable: { beforeStart: false, afterEnd: false },
          draggable: false,
          meta: {
            scheduleSubjectID: schedule.id,
            subjectGroupID: subjectGroup.id,
            subject,
            courseGroup,
            user: subjectGroup.userapp,
          },
        };
        this.events.push(event);
      })
    })
  }

  public closeOpenMonthViewDay() {
    //
  }

  public setView( stateView ) {
    //
  }
}
