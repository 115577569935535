import { Component, OnInit } from '@angular/core';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView,
  DAYS_OF_WEEK
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { colors } from './colors';

import * as moment from 'moment'
import { switchMap, take } from 'rxjs/operators';
import { ScheduleService } from 'app/models/schedule/schedule.service';


@Component({
  selector: 'professor-calendar',
  templateUrl: 'professor-calendar.component.html',
  styleUrls: ['./professor-calendar.component.css'],
})
export class ProfessorCalendarComponent implements OnInit {
  public locale: string = 'es';
  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY]
  public actions: CalendarEventAction[] = [
    {
      label: '<i class="fa  fa-trash"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
      },
    },
  ];

  public calendarView = CalendarView;
  public view = CalendarView.Week;
  public viewDate = new Date();
  public events: CalendarEvent[] = [];
  public activeDayIsOpen = true;
  public refresh = new Subject<void>();

  constructor(
    private _scheduleService: ScheduleService,
  ) { }


  ngOnInit(): void {
    this.getMySubjects();
  }

  private getMySubjects() {
    this._scheduleService.getMyInfo().pipe(
      switchMap(data => {
        const startMoment = moment().subtract(1, 'days').startOf("year");
        const endMoment = moment().add(1, 'days').endOf("year");
        const filterSubjectGroups = {
          where: {
            and: [
              { UserID: data.id },
              { DateStart: { between: [startMoment.toISOString(), endMoment.toISOString()] } }
            ]
          },
          include: ["scheduleSubjects", "userapp", "subjects", "coursesgroup"]
        };

        return this._scheduleService.getSubjectGroups(JSON.stringify(filterSubjectGroups));
      })
    ).subscribe({
      next: (data) => {
        this.processSubjectGroups(data);
      }, error: (err) => {
        console.log(err);
      }
    })
  }

  private processSubjectGroups(subjectGroups: any[]) {
    subjectGroups.forEach(subjectGroup => {
      subjectGroup.scheduleSubjects.forEach((scheduleSubject, index) => {
        const event = {
          title: `${subjectGroup.subjects.NameSubject} - ${subjectGroup.coursesgroup.name}`,
          color: colors[index % colors.length],
          start: moment(scheduleSubject.HourStart).toDate(),
          end: moment(scheduleSubject.HourEnd).toDate(),
          resizable: {
            beforeStart: false,
            afterEnd: false,
          },
          draggable: false,
          meta: {
            scheduleSubjectID: scheduleSubject.id,
            subjectGroupID: subjectGroup.id,
            subject: subjectGroup.subjects,
            courseGroup: subjectGroup.coursesgroup,
            user: subjectGroup.userapp,
          },
        };
        this.events.push(event);
      });
    });
  }


  // Funciones para calendar
  public closeOpenMonthViewDay() {
    //
  }

  public setView(stateView) {
    //
  }

}
