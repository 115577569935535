export const colors: any[] = [
    {
      primary: '#5d80a2',
      secondary: '#84B3E1',
    },
    {
      primary: '#680345',
      secondary: '#D18CBA',
    },
    {
      primary: '#7C6445',
      secondary: '#D0A771',
    },
    {
      primary: '#E154CE',
      secondary: '#F0A9E6',
    },
    {
      primary: '#258C64',
      secondary: '#82C8AD',
    },
    {
      primary: '#FE9A2E',
      secondary: '#E3CAAF',
    },
    {
      primary: '#A44015',
      secondary: '#C29B8A',
    },
    {
      primary: '#0040FF',
      secondary: '#95A2CA',
    },
    {
      primary: '#144A20',
      secondary: '#67AD77',
    },
    {
      primary: '#565ACB',
      secondary: '#A9AACE',
    },
    {
      primary: '#AAE951',
      secondary: '#CADAB5',
    },
    {
      primary: '#F19663',
      secondary: '#E8BBA1'
    },
    {
      primary: '#891CBB',
      secondary: '#C6B6CD',
    },
    {
      primary: '#E2337D',
      secondary: '#DCBBC9',
    },
    {
      primary: '#464208',
      secondary: '#464208',
    },
    {
      primary: '#52B743',
      secondary: '#9A9664',
    },
    {
      primary: '#7C188A',
      secondary: '#C295C8',
    },
    {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    {
      primary: '#5d80a2',
      secondary: '#84B3E1',
    },
    {
      primary: '#680345',
      secondary: '#D18CBA',
    },
    {
      primary: '#7C6445',
      secondary: '#D0A771',
    },
    {
      primary: '#E154CE',
      secondary: '#F0A9E6',
    },
    {
      primary: '#258C64',
      secondary: '#82C8AD',
    },
    {
      primary: '#FE9A2E',
      secondary: '#E3CAAF',
    },
    {
      primary: '#A44015',
      secondary: '#C29B8A',
    },
    {
      primary: '#0040FF',
      secondary: '#95A2CA',
    },
    {
      primary: '#144A20',
      secondary: '#67AD77',
    },
    {
      primary: '#565ACB',
      secondary: '#A9AACE',
    },
    {
      primary: '#AAE951',
      secondary: '#CADAB5',
    },
    {
      primary: '#F19663',
      secondary: '#E8BBA1'
    },
    {
      primary: '#891CBB',
      secondary: '#C6B6CD',
    },
    {
      primary: '#E2337D',
      secondary: '#DCBBC9',
    },
    {
      primary: '#464208',
      secondary: '#464208',
    },
    {
      primary: '#52B743',
      secondary: '#9A9664',
    },
    {
      primary: '#7C188A',
      secondary: '#C295C8',
    },
    {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
  ];
