import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import swal from 'sweetalert2'
import { Category } from '../../../../models/Coursesinfo/category';
import { Course } from '../../../../models/Coursesinfo/course';
import { School } from '../../../../models/Coursesinfo/school';
import { TypeCourse } from '../../../../models/Coursesinfo/typecourse';
import { UsersField } from '../../../../models/usersInfo/users-fields';
import { UserCourse } from '../../../../models/usersInfo/usercourse';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { UsersService } from '../../../../models/usersInfo/users.service';
import { AuthService } from '../../../../models/Auth/auth.service';
import { Subject } from '../../../../models/Coursesinfo/subject';
import { environment } from 'environments/environment';

@Component({
    selector: 'new-course',
    templateUrl: './new-course.component.html',
    styleUrls: ['./new-course.component.css']
})
export class NewCourseComponent implements OnInit {

    courses: Course[];
    coursesFull: Course[];
    schools: School[];
    categorys: Category[];
    subjects: Subject[];
    typecourses: TypeCourse[];
    user: UsersField;
    courseinfo: Course;
    userid: string;
    userCourse: UserCourse;
    typecourseID: number = 0;
    schoolID: number = 0;
    categoryID: number = 0;
    namecourseselected = "";

    public SCHOOL_LOGO_DYNAMIC_SVG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);
    public CEMIL_ICON:any = environment.CEMIL_ICON;
    public EJC_ICON:any = environment.EJC_ICON;
    public SCHOOL_NAME:string = environment.SCHOOL_NAME;
  

    constructor(private coursesService: CoursesService, private usersService: UsersService, private route: ActivatedRoute, private router: Router, private authService: AuthService) {
        this.user = {
            SchoolID: 0,
            CourseOferID: 0,
            TypeCourseID: 0,
            CategoryID: 0
        }

        this.userCourse = {
            UserID: 0,
            SchoolID: 0,
            CourseOferID: 0

        }
    }

    ngOnInit() {

        if (this.authService.getCurrentUser() == null) {
            this.router.navigate(['/register']);
        } else {
            this.userid = this.authService.getCurrentUser();

            swal({
                type: 'success',
                title: "Cargando Cursos",
                text: "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet",
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,

                onOpen: function () {
                    swal.showLoading()
                }
            })
            //LLAMO A TODOS LOS CURSOS QUE EL USUARIO SE HA INSCRITO
            this.coursesService.getUserCoursesByUserID(parseInt(localStorage.getItem("currentUser"))).subscribe(courses => {
                //LLAMO TODO LOS CURSOS
                this.coursesService.getAllCourseBySchoolAndTypeCourse(0, 0).subscribe(p => {

                    //A TODOS LOS CURSOS LE AGREGO LOS CAMPOS
                    for (var index2 = 0; index2 < p.length; index2++) {
                        p[index2]["suscribeCourse"] = false
                        p[index2]["textSuscribe"] = "Inscribirse"
                    }
                    for (var index = 0; index < courses.length; index++) {
                        //BUSCO EL INDEX DENTRO DE TODOS LOS CURSOS
                        var pos = p.findIndex(x => x.id == courses[index].CourseOferID)
                        if (courses[index]["IsAcepted"] == true && pos >= 0) {
                            console.log("se encontro el curso")
                            p[pos]["suscribeCourse"] = true
                            p[pos]["textSuscribe"] = "Ya fue aceptado en este curso"
                        }
                        else if (courses[index]["IsAcepted"] == false && pos >= 0) {
                            p[pos]["suscribeCourse"] = true
                            p[pos]["textSuscribe"] = "Ya se inscribió al curso pero no ha sido o no fue aceptado"
                        }
                    }
                    swal({
                        type: 'success',
                        title: "Cursos Cargados",
                        showConfirmButton: false,
                        timer: 10,

                    })
                    this.courses = p
                    this.coursesFull = this.courses
                });


            })

            this.coursesService.getAllCategory().subscribe(p => this.categorys = p);
            this.coursesService.getAllSchools().subscribe(p => this.schools = p);

        }
    }

    selectSchool(model: UsersField) {
        this.schoolID = model.SchoolID;

        swal({
            type: 'success',
            title: "Cargando cursos de la escuela seleccionada",
            text: "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,

            onOpen: function () {
                swal.showLoading()
            }
        })

        swal({
            type: 'success',
            title: "Cursos Cargados",
            showConfirmButton: false,
            timer: 10,

        })
        this.courses = this.coursesFull.filter(course=>{
            if(course.SchoolID == this.schoolID)
                return true
        })

    }

    selectCategory(model: UsersField) {
        this.categoryID = model.CategoryID
        this.coursesService.getAllTypeCourseByCategory(this.categoryID).subscribe(p => {
            this.typecourses = p

            this.courses = this.coursesFull.filter(course=>{
                let isMatchSchool =false
                if (this.schoolID != 0)
                {  
                    if(course.SchoolID == this.schoolID)
                        isMatchSchool= true
                }
                else
                {
                    isMatchSchool= true
                }
    
                if(isMatchSchool){
                    for(const typecourse of this.typecourses){
                        if(course.TypeCourseID == typecourse.id)
                        {
                            return true;
                        }
                    }
                }
            })


        });


    }

    selectTypeCourse(model: UsersField) {
        this.typecourseID = model.TypeCourseID
        this.courses = this.coursesFull.filter(course=>{
            let isMatchSchool =false
            if (this.schoolID != 0)
            {  
                if(course.SchoolID == this.schoolID)
                    isMatchSchool= true
            }
            else
            {
                isMatchSchool= true
            }

            if(isMatchSchool){
                    if(course.TypeCourseID == this.typecourseID)
                    {
                        return true;
                    }
                
            }
        })
    }

    selectCourseOfer(courseID: number, namecourse: string) {
        this.namecourseselected = namecourse;
        this.coursesService.getSubjectsByCourseMaya(courseID).subscribe(p => this.subjects = p);
    }

    selectNewCourse(oferID: Course, courseIndex: number) {
        //this.errorMessage="Corriga los datos erroneos";
        if (Number.isInteger(parseInt(this.userid))) {

            this.userCourse.UserID = parseInt(this.userid);
            this.userCourse.CourseOferID = oferID.id;
            this.userCourse.SchoolID = oferID.SchoolID;

            swal({
                title: "Está seguro?",
                text: "Será inscrito al curso como estudiante",
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Si, inscribirme!",

            }).then((result) => {
                if (result.value) {
                swal({
                    type: 'success',
                    title: "Inscribiendo",
                    text: "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet",
                    showConfirmButton: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,

                    onOpen: function () {
                        swal.showLoading()
                    }
                })


                this.usersService.assignCourseOfer(this.userCourse).subscribe(
                    p => {
                        swal("Inscrito!", "Se inscribió Correctamente al curso.", "success");
                        this.courses[courseIndex]["suscribeCourse"] = true
                        this.courses[courseIndex]["textSuscribe"] = "Curso Inscrito, esperando aprobación"

                        //this.ngOnInit();
                    },
                    e => {
                        // this.errorMessage=e;
                        //this.isok=false;
                    });

                }
            });




        }
    }

    colorSchool(idSchool: number) {
        let classes = {};

        switch (idSchool) {
            case 0: classes = { cat__info__item_CEDOC: true }; break;
            case 1: classes = { cat__info__item_CEMIL: true }; break;
            case 4: classes = { cat__info__item_EAS: true }; break;
            case 5: classes = { cat__info__item_ESINF: true }; break;
            case 6: classes = { cat__info__item_ESCAB: true }; break;
            case 7: classes = { cat__info__item_ESART: true }; break;
            case 8: classes = { cat__info__item_ESING: true }; break;
            case 9: classes = { cat__info__item_ESCOM: true }; break;
            case 10: classes = { cat__info__item_ESICI: true }; break;
            case 16: classes = { cat__info__item_ESAVE: true }; break;
            case 17: classes = { cat__info__item_ESLOG: true }; break;
            case 18: classes = { cat__info__item_ESCEQ: true }; break;
            case 19: classes = { cat__info__item_ESPOM: true }; break;
            case 20: classes = { cat__info__item_ESMAI: true }; break;
            case 21: classes = { cat__info__item_ESDIH: true }; break;
            case 22: classes = { cat__info__item_CEMAI: true }; break;
            case 23: classes = { cat__info__item_ESIDE: true }; break;
            case 24: classes = { cat__info__item_ESFES: true }; break;
            case 25: classes = { cat__info__item_CEDOC: true }; break;
            case 33: classes = { cat__info__item_ESMIC: true }; break;
            case 34: classes = { cat__info__item_EMSUB: true }; break;
            case 35: classes = { cat__info__item_ESPRO: true }; break;
            default: classes = { cat__info__item_ALL: true }; break;


        }
        return classes;
    }

}