import {Component} from "@angular/core"
import {environment} from "environments/environment"

@Component({
  selector: "cedoc-tutorials",
  templateUrl: "./cedoc-tutorials.component.html",
})
export class CedocTutorialsComponent {
  public tutorials: any[] = [
    {
      id: 1,
      title: "Ingreso",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(1),
      link: "https://drive.google.com/file/d/1_57kfl7u1P0M8dmBbQEg4tqGeEwXcPFT/view",
    },
    {
      id: 2,
      title: "Registro | Evaluacion institucional",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(2),
      link: "https://drive.google.com/file/d/1n_rhuM9YmLkmKhpUcRb2BKlpD49qYjDq/view",
    },
    {
      id: 3,
      title: "Instrumentos",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(3),
      link: "https://drive.google.com/file/d/10BMkZuoWqWSbTDno4vpc5zHNm3EQN7dQ/view",
    },
    {
      id: 4,
      title: "Classroom | Google workspace",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(4),
      link: "https://drive.google.com/file/d/1XDDPJei11XRVF9Z3UM-bR0Uj-j2a2MSv/view",
    },
    {
      id: 5,
      title: "Forms | Google workspace",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(5),
      link: "https://drive.google.com/file/d/1qpfymDT0mNVR6dsMoMYev_t5Qi1NpY7-/view",
    },
    {
      id: 6,
      title: "Hoja de vida",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(6),
      link: "https://drive.google.com/file/d/1h0ATOUysrv0vVI4SLCWBbx9aDBgHgFqG/view",
    },
    {
      id: 7,
      title: "Inscripción | Ofertas Academicas",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(7),
      link: "https://drive.google.com/file/d/1vw70_V4G6-APCG1TgSGTdlFaCgSfp17d/view",
    },
    {
      id: 8,
      title: "Foto de perfil | Google workspace",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(8),
      link: "https://drive.google.com/file/d/1LDIbeuVuNTcUqioqKzFz9byqCTOFCk8x/view",
    },
    {
      id: 9,
      title: "Administracion | Administracion",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(9),
      link: "https://drive.google.com/file/d/1wso78sKKa-wEp0c_ECIUiBjrK5jlC3Yu/view",
    },
    {
      id: 10,
      title: "Inscripción | Area academica",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(10),
      link: "https://drive.google.com/file/d/1vy_phgKi6LRgDGIEOgDbx4y60vXRExpF/view",
    },
    {
      id: 11,
      title: "Malla curricular | Creacion y Administracion",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(11),
      link: "https://drive.google.com/file/d/1tB5ufH1OvT4GsrMRaZlKwpbC5lIRWArG/view",
    },
    {
      id: 12,
      title: "Contraseña | Cambiar y Restaurar",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(12),
      link: "https://drive.google.com/file/d/1rQY2Ak7_sciAM1--VIQ2kYN-0OIj3O5A/view",
    },
    {
      id: 13,
      title: "Grupos | Creacion de grupos",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(13),
      link: "https://drive.google.com/file/d/1p2tjSnQ4OOemA-TSf0b4WakZQ5RrohEk/view",
    },
    {
      id: 14,
      title: "Grupos | Edicion de grupos",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(14),
      link: "https://drive.google.com/file/d/1gE_YeVevgFk_gbypdThW-u6TVFJbdh-r/view",
    },
    {
      id: 15,
      title: "Horarios | Crear horarios",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(15),
      link: "https://drive.google.com/file/d/1qBzKn-sRx-CJKWmsuTy52eI0NYTBpgO3/view",
    },
    {
      id: 16,
      title: "Horarios | OAP automatica",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(16),
      link: "https://drive.google.com/file/d/1MdiBc-hsoqBCYNVPgU8kDksXHqi-w2Mx/view",
    },
    {
      id: 17,
      title: "Administrar | Fechas Academicas",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(17),
      link: "https://drive.google.com/file/d/1MdiBc-hsoqBCYNVPgU8kDksXHqi-w2Mx/view",
    },
    {
      id: 18,
      title: "Cronograma academico",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(18),
      link: "https://drive.google.com/file/d/1WNUPMq8KEL3QnXSkrIYI50qIatKaD-0q/view",
    },
    {
      id: 19,
      title: "Oferta Academica | Crear",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(19),
      link: "https://drive.google.com/file/d/1bWa8490pGW60SWLm6cDm3Y5KBwBscVyL/view",
    },
    {
      id: 20,
      title: "Cronograma academico | Oferta anual",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(20),
      link: "https://drive.google.com/file/d/17DRla1AizBX0w4XOZo3qCTlevVpADKiG/view",
    },
    {
      id: 21,
      title: "Cronograma academico | DIEDU",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(21),
      link: "https://drive.google.com/file/d/17DRla1AizBX0w4XOZo3qCTlevVpADKiG/view",
    },
    {
      id: 22,
      title: "Administración de Usuarios | ADMIN",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(22),
      link: "https://drive.google.com/file/d/1zX6qDGa8g8utSSY9bAdhmC8ETFaaitbV/view",
    },
    {
      id: 23,
      title: "Gestion de Roles | ADMIN",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(23),
      link: "https://drive.google.com/file/d/17DRla1AizBX0w4XOZo3qCTlevVpADKiG/view",
    },
    {
      id: 24,
      title: "Bienestar | Crear Noticia / Artículos / Correo",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(24),
      link: "https://drive.google.com/file/d/1w6WDmRbUfnMw9P7iA7VVfc1C1veQYSmH/view",
    },
    {
      id: 25,
      title: "Bienestar | Crear evento",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(25),
      link: "https://drive.google.com/file/d/1_QVQPle1hZ-_YlsFBEY9KrYd0r7khWGt/view",
    },
    {
      id: 26,
      title: "Bienestar | Inscribirse a Evento",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(26),
      link: "https://drive.google.com/file/d/16b6M66z7SgrmPouaiYAB9qVHHHX4tAp5/view",
    },
    {
      id: 27,
      title: "Bienestar",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(27),
      link: "https://drive.google.com/file/d/16b6M66z7SgrmPouaiYAB9qVHHHX4tAp5/view",
    },
    {
      id: 28,
      title: "Educación | Superior",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(28),
      link: "https://drive.google.com/file/d/16b6M66z7SgrmPouaiYAB9qVHHHX4tAp5/view",
    },
    {
      id: 29,
      title: "Carga academica | Inscipción estudiantes",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(29),
      link: "https://drive.google.com/file/d/16b6M66z7SgrmPouaiYAB9qVHHHX4tAp5/view",
    },
    {
      id: 30,
      title: "Instrumentos | Evaluación 2.0",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(30),
      link: "https://drive.google.com/file/d/1dbBJ315DfBlRBbc4noZXgQENOQzreHOj/view",
    },
    {
      id: 32,
      title: "Homologación",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(32),
      link: "https://drive.google.com/file/d/11TturZYv1MeBQIhqAWHZwktiumbEcrKS/view?usp=sharing",
    },
    {
      id: 33,
      title: "Cargar Recibos",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(33),
      link: "https://drive.google.com/file/d/1SGX9pQy257jIJJbmCrIB8L2JY1xkfVha/view?usp=sharing",
    },
    {
      id: 34,
      title: "Plan de mejora",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(34),
      link: "https://drive.google.com/file/d/1Lp7hsZFudM-dtnGgrwZOkmLOQMLN1vvr/view?usp=sharing",
    },
    {
      id: 35,
      title: "Convocatorias internas",
      image: environment.TUTORIAL_IMAGE_DYNAMIC(35),
      link: "https://drive.google.com/file/d/1E6kqijkRJ1wBxuOY7O88qa_J09xBE1_F/view?usp=share_link",
    },
  ]
}
