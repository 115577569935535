import { Component, Input, OnInit, AfterViewChecked, EventEmitter, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { LocalDataSource } from 'ng2-smart-table';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { AuthService } from '../../../../models/Auth/auth.service';
import { ProfileNavComponent } from '../../../../components/profile-nav/profile-nav.component';
import { ViewCell } from 'ng2-smart-table';
import { School } from '../../../../models/Coursesinfo/school';
import { AccordingView } from '../../../../components/according-view/according-view.component';
import { AngularCsv } from 'angular7-csv';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as XLSX from 'xlsx';
import { SharedService } from 'app/models/shared/shared.service';

import jsPDF, { jsPDFOptions } from 'jspdf';
import autoTable from 'jspdf-autotable'
import { concat, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, toArray } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { LinkRenderComponent } from './table-components/link/link-render.component';
import { Observable } from 'rxjs/Observable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

type AOA = any[][];
const async = require("async");

// INICIO COMPONENTE PARA EL NG SMART TABLE DE ACORDE
@Component({
  selector: 'is-according-professor-table',
  template: `
  <b *ngIf="renderValue=='Si'" >  {{renderValue}}</b>
  <b *ngIf="renderValue=='No'"   style='color: red;'>  {{renderValue}}</b>
      `
})
export class AccordingViewProfessor implements ViewCell, OnInit {

  constructor() { }

  renderValue: string;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter()
  ngOnInit() {
    if (!isNullOrUndefined(this.value)) {
      if (this.value.toString() == "true" || this.value.toString() == "1")
        this.renderValue = "Si"
      if (this.value.toString() == "false" || this.value.toString() == "0")
        this.renderValue = "No"
    }
  }
  ngAfterViewChecked() {
    this.renderValue = String(this.value);
  }


}
// FIN COMPONENTE PARA EL NG SMART TABLE GRUPOS


@Component({
  selector: "StudentRecord",
  templateUrl: "./student-record.component.html",
  styleUrls: ["./student-record.component.css"],
  providers: [AccordingView],
})
export class StudentRecordComponent implements AfterViewChecked {
  public APP_NAME = environment.APP_NAME.toLowerCase();
  public BACK_V2 = environment.URL_BACK_V2;

  totalStudent: number;
  date1: any;
  date2: any;
  date3: any;
  date4: any;
  date5: any;
  date6: any;

  percentages: any = {
    Note1: 0,
    Note2: 0,
    Note3: 0,
    Note4: 0,
    Note5: 0,
    Note6: 0,
  };

  user: string;
  ErrorGroup: string;
  SubjectsProfesor: any[] = [];
  SubjectsProfesor_temp: any = [];
  SubjectGID: number = 0;
  schools: School[] = [];
  SchoolID: number;
  counterNota1: number = 0;
  counterNota2: number = 0;
  counterNota3: number = 0;
  counterNota4: number = 0;
  counterNota5: number = 0;
  counterNota6: number = 0;
  serverTime: Date;
  listStudents: any;

  porcent1 = 0;
  porcent2 = 0;
  porcent3 = 0;
  porcent4 = 0;
  porcent5 = 0;
  porcent6 = 0;

  stepMassive = 0;
  sourceStudentRecord: LocalDataSource;

  public showData: boolean = false;
  public courseOffers: any[] = [];
  public isSuperDocente: boolean = false;
  public settingsStudentRecord: any;

  public selectedSubjectInfo: any = undefined;
  public SCHOOL_LOGO_DYNAMIC_SVG: any = (id: number = 0) =>
    environment.SCHOOL_LOGO_DYNAMIC_SVG(id);
  public SCHOOL_NAME = environment.SCHOOL_NAME;

  public massiveEmailForm: FormGroup = this._fb.group({
    title: ["", Validators.required],
    message: ["", Validators.required],
  });

  public sendingMassiveEmail: boolean = false;
  public qualitativeDescription: any[] = [];
  private _tempSubjectsProfessor: any[] = [];
  public subjectSelected: any;

  constructor(
    private _fb: FormBuilder,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public profileNavComponent: ProfileNavComponent,
    public sharedSevice: SharedService,
    private cdr: ChangeDetectorRef
  ) {
    this.sourceStudentRecord = new LocalDataSource(); // create the source

    this.coursesService.getAllSchools().subscribe(
      (p) => {
        this.schools = p;
      },
      (error) => {
        console.error(
          `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
        );
      }
    );

    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(["/register"]);
    } else {
      //DE LO CONTRARO CARGAR EL ID DEL USUARIO
      this.user = this.authService.getCurrentUser(); //devuelve el id del usuario
      //SI EL ID DEL USUARIO ES UN NUMERO ENTERO
      if (Number.isInteger(parseInt(this.user))) {
        //CONSULTO EL PERFIL DEL USUARIO
        var validar_usuario = [false];

        const superDocenteRole = this.profileNavComponent.roles.find(
          (role) => role.name === "SuperDocente"
        );
        const adminRole = this.profileNavComponent.roles.find(
          (role) => role.name === "admin"
        );

        if (superDocenteRole || adminRole) {
          this.isSuperDocente = true;
          console.log("ES SUPERDOCENTE");
        }

        this.profileNavComponent.roles.forEach((role) => {
          if (role.name === "Docente") {
            validar_usuario.push(true);
            this.SchoolID = role.SchoolID;
            return;
          }

          if (role.name === "admin" || role.name === "SuperDocente") {
            validar_usuario.push(true);
            this.SchoolID = role.SchoolID;
            this.isSuperDocente = true;
          }
        });

        if (!validar_usuario.includes(true)) {
          this.router.navigate(["/"]);
        }

        Swal({
          position: "center",
          title: "Cargando Materias",
          text: "Esto Puede tomar un tiempo dependiendo de su conexión.",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: function () {
            Swal.showLoading();
          },
        });

        //CONSULTO LA LISTA DE Materias POR EL USUARIO
        this.coursesService
          .getSubjectByProfesorID(parseInt(this.user))
          .subscribe(
            (response) => {
              console.log("response", response);
              console.log(
                "TOTAL MATERIAS DICTADAS POR ESTE ID" + response.length
              );
              //console.log(response)
              if (response.length > 0) {
                //ORGANIZAR LOS MIDTERMS DE LA RESPUESTA
                response.forEach((course) => {
                  course.minTerms.sort((a, b) => b.MidTermNum - a.MidTermNum);
                });

                this.SubjectsProfesor_temp = response;

                let tempSubjectsProfessor: any[] =
                  this.SubjectsProfesor_temp.filter(
                    (e) => e.CourseOferID !== null
                  );

                //consultar la oferta a partir del grupo, si la oferta esta activa mostrar, de lo contrario no ver nada
                const coursesObs = tempSubjectsProfessor.map((e) =>
                  this.coursesService.getCoursesByID(e.CourseOferID)
                );

                forkJoin(
                  [...coursesObs].map((coursesOb, index) =>
                    coursesOb.pipe(
                      catchError((error) => {
                        const courseId =
                          tempSubjectsProfessor[index].CourseOferID;
                        console.error(
                          `No se pudo encontrar el curso con id ${courseId}`
                        );
                        console.log(error);
                        return of(null); // Retorna un observable que emite un valor nulo y se completa.
                      })
                    )
                  )
                ).subscribe({
                  next: (coursesArray) => {
                    coursesArray.forEach((courses, index) => {
                      const subject = tempSubjectsProfessor[index];
                      if (courses !== null) {
                        subject.CourseOffers = courses;
                      }
                    });

                    tempSubjectsProfessor = tempSubjectsProfessor.filter(
                      (subject) => {
                        if (
                          isNullOrUndefined(subject.CourseOffers) ||
                          subject.CourseOffers.IsActive
                        ) {
                          return true;
                        }
                        return false;
                      }
                    );

                    tempSubjectsProfessor = tempSubjectsProfessor.filter(
                      (subject) => !isNullOrUndefined(subject)
                    );

                    if (tempSubjectsProfessor.length === 0) {
                      Swal({
                        position: "center",
                        type: "error",
                        title: "Error",
                        text: "No Hay Materias Para Dictar",
                        showConfirmButton: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                      });
                    } else {
                      Swal({
                        position: "center",
                        type: "success",
                        showConfirmButton: false,
                        timer: 10,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                      });

                      this._tempSubjectsProfessor = tempSubjectsProfessor;

                      //Some courseOffers are undefined, so filter only available
                      const courseOffers = tempSubjectsProfessor
                        .map((e: any) => e.CourseOffers)
                        .filter((courseOffer) => courseOffer !== undefined);

                      const uniqueCourseOffers = courseOffers.reduce(
                        (unique: any[], current: any) => {
                          if (!unique.some((obj) => obj.id === current.id))
                            unique.push(current);

                          return unique;
                        },
                        []
                      );

                      this.courseOffers = uniqueCourseOffers.sort((a, b) =>
                        a.NameCourseOfer.localeCompare(b.NameCourseOfer)
                      );
                    }
                  },
                  error: (err) => console.log(err),
                });
              } else {
                Swal({
                  position: "center",
                  type: "error",
                  title: "Error",
                  text: "No Hay Materias Para Dictar",
                  showConfirmButton: true,

                  allowEscapeKey: false,
                  allowOutsideClick: false,
                });
              }
            },
            (error) => {
              console.error(
                `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
              );
            }
          );
      } else {
        this.router.navigate(["/"]);
      }
    }
  }

  ngOnInit() {
    this.settingsStudentRecord.columns.Resume.title =
      this.APP_NAME === "CELIC" ? "Observador" : "Hoja de vida";
    this.authService
      .getTime()
      .then((server2) => (this.serverTime = server2.time));
  }

  resetTable() {
    this.showData = false;
    const newTableData = {
      actions: {
        add: false,
        edit: true,
        delete: false,
        columnTitle: "Acciones",
      },
      noDataMessage: "No hay datos disponibles",

      add: {
        addButtonContent: "",
        createButtonContent: "",
        cancelButtonContent: "",
        confirmCreate: true,
      },

      edit: {
        editButtonContent: '<i class="fa fa-pencil-square"></i>',
        saveButtonContent: '<i class="fa fa-floppy-o"></i>',
        cancelButtonContent: '<i class="fa fa-window-close"></i>',
        confirmSave: true,
      },
      pager: {
        perPage: 30,
      },
      delete: {
        deleteButtonContent: "",
        confirmDelete: true,
      },
      columns: {
        LastNames: {
          title: "Apellido",
          filter: false,
          editable: false,
        },
        Names: {
          title: "Nombre",
          filter: false,
          editable: false,
        },
        Document: {
          title: "Documento",
          filter: false,
          editable: false,
        },
        Resume: {
          title: "Hoja de vida",
          filter: false,
          editable: false,
          type: "custom",
          renderComponent: LinkRenderComponent,
        },
        Note1: {
          title: "Nota 1",
          filter: false,
          editable: false,
          editor: { type: "number", config: { list: [] } },
        },
        professorNote1: {
          title: "Observación 1",
          filter: false,
          editable: true,
          editor: { type: "textarea" },
        },
        Absences1: {
          title: "Inasistencias 1",
          filter: false,
          editable: false,
          type: "number",
        },
        RemedialGrade1: {
          title: "Recuperación 1",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording1: {
          title: "Conforme 1",
          filter: false,
          editable: false,
          editor: {
            type: "list",
            config: {
              list: [
                { value: true, title: "Si" },
                { value: false, title: "No" },
              ],
            },
          },
          type: "custom",
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(
              (row) => {
                console.log("algo se edito");
              },
              (error) => {
                console.error(
                  `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                );
              }
            );
          },
        },
        Note2: {
          title: "Nota 2",
          filter: false,
          editable: false,
          editor: { type: "number", config: { list: [] } },
        },
        professorNote2: {
          title: "Observación 2",
          filter: false,
          editable: true,
          editor: { type: "textarea" },
        },
        Absences2: {
          title: "Inasistencias 2",
          filter: false,
          editable: false,
          type: "number",
        },
        RemedialGrade2: {
          title: "Recuperación 2",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording2: {
          title: "Conforme 2",
          filter: false,
          editable: false,
          editor: {
            type: "list",
            config: {
              list: [
                { value: true, title: "Si" },
                { value: false, title: "No" },
              ],
            },
          },
          type: "custom",
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(
              (row) => {
                console.log("algo se edito");
              },
              (error) => {
                console.error(
                  `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                );
              }
            );
          },
        },
        Note3: {
          title: "Nota 3",
          filter: false,
          editable: false,
          editor: { type: "number", config: { list: [] } },
        },
        professorNote3: {
          title: "Observación 3",
          filter: false,
          editable: true,
          editor: { type: "textarea" },
        },
        Absences3: {
          title: "Inasistencias 3",
          filter: false,
          editable: false,
          type: "number",
        },
        RemedialGrade3: {
          title: "Recuperación 3",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording3: {
          title: "Conforme 3",
          filter: false,
          editable: false,
          editor: {
            type: "list",
            config: {
              list: [
                { value: true, title: "Si" },
                { value: false, title: "No" },
              ],
            },
          },
          type: "custom",
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(
              (row) => {
                console.log("algo se edito");
              },
              (error) => {
                console.error(
                  `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                );
              }
            );
          },
        },
        Note4: {
          title: "Nota 4",
          filter: false,
          editable: false,
          editor: { type: "number", config: { list: [] } },
        },
        professorNote4: {
          title: "Observación 4",
          filter: false,
          editable: true,
          editor: { type: "textarea" },
        },
        Absences4: {
          title: "Inasistencias 4",
          filter: false,
          editable: false,
          type: "number",
        },
        RemedialGrade4: {
          title: "Recuperación 4",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording4: {
          title: "Conforme 4",
          filter: false,
          editable: false,
          editor: {
            type: "list",
            config: {
              list: [
                { value: true, title: "Si" },
                { value: false, title: "No" },
              ],
            },
          },
          type: "custom",
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(
              (row) => {
                console.log("algo se edito");
              },
              (error) => {
                console.error(
                  `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                );
              }
            );
          },
        },
        Note5: {
          title: "Nota 5",
          filter: false,
          editable: false,
          editor: { type: "number", config: { list: [] } },
        },
        professorNote5: {
          title: "Observación 5",
          filter: false,
          editable: true,
          editor: { type: "textarea" },
        },
        Absences5: {
          title: "Inasistencias 5",
          filter: false,
          editable: false,
          type: "number",
        },
        RemedialGrade5: {
          title: "Recuperación 5",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording5: {
          title: "Conforme 5",
          filter: false,
          editable: false,
          editor: {
            type: "list",
            config: {
              list: [
                { value: true, title: "Si" },
                { value: false, title: "No" },
              ],
            },
          },
          type: "custom",
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(
              (row) => {
                console.log("algo se edito");
              },
              (error) => {
                console.error(
                  `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                );
              }
            );
          },
        },
        Note6: {
          title: "Nota 6",
          filter: false,
          editable: false,
          editor: { type: "number", config: { list: [] } },
        },
        professorNote6: {
          title: "Observación 6",
          filter: false,
          editable: true,
          editor: { type: "textarea" },
        },
        Absences6: {
          title: "Inasistencias 6",
          filter: false,
          editable: false,
          type: "number",
        },
        RemedialGrade6: {
          title: "Recuperación 6",
          filter: false,
          editable: false,
          type: "number",
        },
        IsAccording6: {
          title: "Conforme 6",
          filter: false,
          editable: false,
          editor: {
            type: "list",
            config: {
              list: [
                { value: true, title: "Si" },
                { value: false, title: "No" },
              ],
            },
          },
          type: "custom",
          renderComponent: AccordingViewProfessor,
          onComponentInitFunction(instance) {
            instance.save.subscribe(
              (row) => {
                console.log("algo se edito");
              },
              (error) => {
                console.error(
                  `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                );
              }
            );
          },
        },
        FinalRecord: {
          title: "Nota Final",
          filter: false,
          editable: false,
          type: "number",
        },
        TotalAbsences: {
          title: "Total Inasistencias",
          filter: false,
          editable: false,
          type: "number",
        },
      },
      rowClassFunction: (row) =>
        row.data.isWithdrawn ? "smart-table__disable-row" : "",
    };

    return Object.assign({}, newTableData);
  }

  public onSelectCourse(event: any) {
    const value = event.target.value;

    this.SubjectGID = 0;
    this.SubjectsProfesor = [];
    this.SubjectsProfesor = [...this._tempSubjectsProfessor].filter(
      (e: any) => e.CourseOffers && e.CourseOffers.id === Number(value)
    );

    this.qualitativeDescription =
      this.courseOffers.find((e) => e.id === Number(value)).course
        .qualitativeDescriptions || [];
  }

  IsBetween(p) {
    let startDate = moment(p[0].DateStartRecord, "YYYY-MM-DD HH:mm:ss")
      .startOf("day")
      .toString();
    let untilDate = moment(p[0].DateEndRecord, "YYYY-MM-DD HH:mm:ss")
      .endOf("day")
      .toString();
    let today = moment(this.serverTime).add(5, "hours").toString();
    let firstDate = new Date(startDate);
    let secondDate = new Date(untilDate);
    let todayDate = new Date(today);

    if (
      firstDate.getTime() < todayDate.getTime() &&
      todayDate.getTime() < secondDate.getTime()
    )
      return true;

    return false;
  }

  /**
   * Render subject data
   * @param model
   */
  public selectSubject(model) {
    //Reset table to default config
    this.showData = false;
    // this.settingsStudentRecord = { ...this._settingsStudentRecord };
    this.settingsStudentRecord = this.resetTable();

    this.subjectSelected = this._tempSubjectsProfessor.find(
      (x) => x.id === model.id
    );

    if (this.subjectSelected)
      this.subjectSelected = this.subjectSelected.subjects;

    this.SubjectGID = model.id;
    this.stepMassive = 0;

    this.date1 = null;
    this.date2 = null;
    this.date3 = null;
    this.date4 = null;
    this.date5 = null;
    this.date6 = null;

    const subgroup = this.SubjectsProfesor.find(
      (x) => x.id === this.SubjectGID
    );
    this.selectedSubjectInfo = subgroup;

    //Creates a collection of observables
    const observables = [];

    for (let i = 1; i <= 6; i++) {
      const observableFunction = this.handleMidTerm(subgroup, i);
      if (observableFunction) {
        observables.push(observableFunction);
        continue;
      }

      if (this.APP_NAME == "cedoc")
        delete this.settingsStudentRecord.columns[`RemedialGrade${i}`];

      delete this.settingsStudentRecord.columns[`Note${i}`];
      delete this.settingsStudentRecord.columns[`Absences${i}`];
      delete this.settingsStudentRecord.columns[`IsAccording${i}`];
    }

    if (this.APP_NAME == "cedoc") {
      delete this.settingsStudentRecord.columns[`RemedialGrade1`];
      delete this.settingsStudentRecord.columns[`RemedialGrade2`];
      delete this.settingsStudentRecord.columns[`RemedialGrade3`];
      delete this.settingsStudentRecord.columns[`RemedialGrade4`];
      delete this.settingsStudentRecord.columns[`RemedialGrade5`];
      delete this.settingsStudentRecord.columns[`RemedialGrade6`];
      delete this.settingsStudentRecord.columns[`professorNote1`];
      delete this.settingsStudentRecord.columns[`professorNote2`];
      delete this.settingsStudentRecord.columns[`professorNote3`];
      delete this.settingsStudentRecord.columns[`professorNote4`];
      delete this.settingsStudentRecord.columns[`professorNote5`];
      delete this.settingsStudentRecord.columns[`professorNote6`];
    }

    if (this.APP_NAME == "celic") {
      delete this.settingsStudentRecord.columns["Note5"];
      delete this.settingsStudentRecord.columns["Absences5"];
      delete this.settingsStudentRecord.columns["IsAccording5"];
      delete this.settingsStudentRecord.columns["RemedialGrade5"];
      delete this.settingsStudentRecord.columns["Note6"];
      delete this.settingsStudentRecord.columns["Absences5"];
      delete this.settingsStudentRecord.columns["IsAccording5"];
      delete this.settingsStudentRecord.columns["RemedialGrade6"];
      delete this.settingsStudentRecord.columns[`professorNote5`];
      delete this.settingsStudentRecord.columns[`professorNote6`];

      if (this.subjectSelected.TypeRecord != "Cualitativo") {
        delete this.settingsStudentRecord.columns[`professorNote1`];
        delete this.settingsStudentRecord.columns[`professorNote2`];
        delete this.settingsStudentRecord.columns[`professorNote3`];
        delete this.settingsStudentRecord.columns[`professorNote4`];
      }
    }

    //Get data from each observables
    forkJoin(observables).subscribe({
      next: (resp: any[]) => {
        resp.forEach((e, index) => {
          if (!e) return;

          const midTermNum = index + 1;

          this[`counterNota${midTermNum}`] = e.length;
          this[`porcent${midTermNum}`] = e[0].midTerm.Porcent;

          if (this.SCHOOL_NAME === "CELIC")
            this.settingsStudentRecord.columns[
              `RemedialGrade${midTermNum}`
            ].editable = this.IsBetween(e);

          this.settingsStudentRecord.columns[`Note${midTermNum}`].editable =
            this.IsBetween(e);
          this.settingsStudentRecord.columns[`Absences${midTermNum}`].editable =
            this.IsBetween(e);

          this[`date${midTermNum}`] = e[0];
          this.percentages[`Note${midTermNum}`] = e[0].midTerm.Porcent;
        });

        this.getStudents();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  /**
   * Returns a observable getAllDateRecordByMidterm
   *
   * @param subgroup
   * @param midTermNum
   * @returns
   */
  private handleMidTerm(subgroup, midTermNum): Observable<any> {
    const midterm = subgroup.minTerms.find((x) => x.MidTermNum === midTermNum);

    if (midterm === undefined) return null;

    this.settingsStudentRecord.columns[
      `Note${midTermNum}`
    ].title = `${midterm.NameMid} ${midterm.Porcent}%`;
    return this.coursesService.getAllDaterecordByMidterm(midterm.id);
  }

  /**
   * Get student list
   */
  private getStudents() {
    this.coursesService.getAllStudentRecord(this.SubjectGID).subscribe({
      next: (resp) => {
        resp = resp.filter((e) => e !== undefined);
        resp = resp.sort((a, b) =>
          a["LastNames"].localeCompare(b["LastNames"])
        );
        this.totalStudent = resp.length;
        this.listStudents = resp;

        this.sourceStudentRecord.load(resp);
        this.showData = true;
      },
      error: (err) => {
        console.error(`Ocurrió un error: ${err}`);
      },
    });
  }

  ngOnDestroy() {}

  /**
   * Validate note fields value and store it
   *
   * @param event
   * @returns
   */
  public onConfirmEdit(event): any {
    const { grades, absences } = this.extractGradesAndAbsences(event.newData);
    event.newData.FinalRecord = this.calculateFinalRecord(grades);
    event.newData.TotalAbsences = this.calculateTotalAbsences(absences);
   
    // const invalidInput = this.validateInput(event.newData).error;
    const validationError = this.validateInput(event.newData);
    if (validationError.error)
      return this.authService.swalError( "Error", validationError.message );

    this.authService.swalLoading("Guardando...");

    const { TotalAbsences } = event.newData;
    const totalAbsences = parseInt(TotalAbsences);

    //If the current userSubject has no userRecords, the updateUserSubjects function will create the missing user records (6)
    this.coursesService.updateUserSubject(event.data.id, totalAbsences)
      .pipe(
        switchMap((updatedUserSubject: any) => this.createSaveObservables(updatedUserSubject, event.newData) )
      )
      .subscribe({
        next: () => {
          this.authService.swalSuccess( "Registro guardado!", "" );
          event.confirm.resolve(event.newData);
        },
        error: (err) => {
          console.log(err);
          this.authService.swalError("Ha ocurrido un error", err);
        },
      });
  }

  public confirmUpdateAll(){
    Swal({
      title: "Confirmar actualización",
      text: "¿Seguro que deseas actualizar todos los registros?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, actualizar",
      cancelButtonText: "Cancelar"
    }).then((isConfirmed) => {
      if (isConfirmed.value) this.updateAllRecords();
    });
  }

  private updateAllRecords() {
    this.sourceStudentRecord.getAll().then((records: any[]) => {
      const observables: Observable<any>[] = records.map(record => this.processRecordUpdate(record));

      if (observables.length > 0) {
        this.authService.swalLoading("Actualizando...");
        concat(...observables)
          .pipe(toArray())
          .subscribe({
            next: (_) => {
              this.authService.swalSuccess(
                "Actualización completa",
                "Todos los registros han sido actualizados."
              );
            },
            error: (err) => {
              console.log(err);
              this.authService.swalError("Error en la actualización masiva", err);
            },
          });
      } else {
        this.authService.swalInfo("No hay cambios para actualizar.");
      }
    });
  }

  private processRecordUpdate(record: any): Observable<any> {
    const { grades, absences } = this.extractGradesAndAbsences(record);
    record.FinalRecord = this.calculateFinalRecord(grades);
    record.TotalAbsences = this.calculateTotalAbsences(absences);
  
    const validationError = this.validateInput(record);
    if (validationError.error) {
      this.authService.swalError("Error", validationError.message);
      return of(null);
    }
  
    const totalAbsences = parseInt(record.TotalAbsences);
    return this.coursesService.updateUserSubject(record.id, totalAbsences)
      .pipe(
        switchMap((updatedUserSubject: any) => this.createSaveObservables(updatedUserSubject, record))
      );
  }

  private createSaveObservables(updatedUserSubject: any, newData: any): Observable<any[]> {
    const observables: Observable<any>[] = [];
    const userRecords = updatedUserSubject.userRecord;
  
    for (let i = 1; i <= 6; i++) {
      if (this.percentages[`Note${i}`] > 0) {
        const noteId = userRecords.find(e => e.midTerm.MidTermNum == i);
        if (noteId) {
          const data = this.buildSaveData(newData, i);
          const obs = this.saveUserRecord(noteId.id, data, updatedUserSubject.id);
          observables.push(obs);
        }
      }
    }

    return observables.length === 0 ? of([]) : forkJoin(observables);
  }

  private buildSaveData(newData: any, index: number): any {
    const noteKey = `Note${index}`;
    const absenceKey = `Absences${index}`;
    const remedialGradeKey = `RemedialGrade${index}`;
    const professorNoteKey = `professorNote${index}`;
  
    const data = {
      ParcialRecord: parseFloat(newData[noteKey]),
      professorNote: newData[professorNoteKey],
      absences: parseFloat(newData[absenceKey]),
      ...(this.SCHOOL_NAME === "CELIC" && {
        remedialGrade: newData[remedialGradeKey] === "" ? null : parseFloat(newData[remedialGradeKey]),
      })
    };
  
    return data;
  }

  private calculateTotalAbsences(absences: number[]): number {
    return absences.reduce((total, absence) => total + absence, 0);
  }

  private extractGradesAndAbsences(data: any): { grades: number[], absences: number[] } {
    const grades = [
      Number(data.RemedialGrade1 || data.Note1 || 0),
      Number(data.RemedialGrade2 || data.Note2 || 0),
      Number(data.RemedialGrade3 || data.Note3 || 0),
      Number(data.RemedialGrade4 || data.Note4 || 0),
      Number(data.RemedialGrade5 || data.Note5 || 0),
      Number(data.RemedialGrade6 || data.Note6 || 0)
    ];
  
    const absences = [
      Number(data.Absences1 || 0),
      Number(data.Absences2 || 0),
      Number(data.Absences3 || 0),
      Number(data.Absences4 || 0),
      Number(data.Absences5 || 0),
      Number(data.Absences6 || 0)
    ];
  
    return { grades, absences };
  }

  private calculateFinalRecord(notes: number[]): string {
    const percentages = [
      this.porcent1 || 0,
      this.porcent2 || 0,
      this.porcent3 || 0,
      this.porcent4 || 0,
      this.porcent5 || 0,
      this.porcent6 || 0,
    ];

    const totalPercentage = percentages.reduce(
      (sum, percent) => sum + percent,
      0
    );
    
    const weightedSum = notes.reduce(
      (sum, note, index) => sum + note * percentages[index],
      0
    );

    return (weightedSum / totalPercentage).toFixed(2);
  }

  /**
   * Validates the note inputs value
   *
   * @param newData
   * @returns
   */
  private validateInput(newData) {
    const getMaxNoteValue = () => {
      if (this.subjectSelected.TypeRecord == "Cualitativo") {
        if (this.qualitativeDescription.length === 0)
          return { minValue: 1, maxValue: 10 };
        let minValue = Infinity;
        let maxValue = -Infinity;

        this.qualitativeDescription.forEach((obj) => {
          const [min, max] = obj.range.split("-").map(Number);
          if (min < minValue) minValue = min;
          if (max > maxValue) maxValue = max;
        });

        return { minValue, maxValue };
      } else {
        let ranges = this.subjectSelected.TypeRecord.split("-").map((x) =>
          Number(x)
        );
        return { minValue: ranges[0], maxValue: ranges[1] };
      }
    };

    const { minValue, maxValue } = getMaxNoteValue();

    const isNoteValid = (note: number) => {
      const validFormat = /^(\d+(\.\d+)?)$/.test(note.toString());
      return validFormat && note >= minValue && note <= maxValue;
    };

    const isAbsencensInvalid = (key: string) => {
      const absencesValue = parseInt(newData[key], 10);
      const validFormat = /^(\d+(\.\d+)?)$/.test(newData[key]);
      return (
        this.settingsStudentRecord.columns[key] &&
        this.settingsStudentRecord.columns[key].editable &&
        (!validFormat || absencesValue < 0)
      );
    };

    const isRemedialGradeInvalid = (key: string) => {
      const remedialGradeValidValue = parseInt(newData[key], 10);
      const validFormat = /^(\d+(\.\d+)?)$/.test(newData[key]);
      return (
        this.settingsStudentRecord.columns[key] &&
        this.settingsStudentRecord.columns[key].editable &&
        (!validFormat || remedialGradeValidValue < 0)
      );
    };

    for (let i = 1; i <= 6; i++) {
      const noteKey = `Note${i}`;
      const absenceKey = `Absences${i}`;
      const remedialGrade = `RemedialGrade${i}`;
      if (newData[absenceKey])
        if (isAbsencensInvalid(absenceKey))
          return {
            error: true,
            message: `La cantidad de inasistencias debe ser un número positivo válido.`,
          };

      if (newData[remedialGrade])
        if (
          isRemedialGradeInvalid(remedialGrade) &&
          this.SCHOOL_NAME === "CELIC"
        )
          return {
            error: true,
            message: `La Recuperación debe ser un número positivo válido.`,
          };

      //If the percentage is 0, do nothing
      if (this.percentages[`Note${i}`] > 0) {
        const noteEditable =
          this.settingsStudentRecord.columns[noteKey] &&
          this.settingsStudentRecord.columns[noteKey].editable;
        const noteValue = newData[noteKey];

        if (noteEditable && !isNoteValid(noteValue)) {
          return {
            error: true,
            message: `La nota debe estar entre ${minValue} y ${maxValue}. Si incluye valores decimales hacerlo con punto.`,
          };
        }
      }

      return { error: false, message: "Datos válidos" };
    }
  }

  /**
   * Query to store records and midTerms
   *
   * @param noteId
   * @returns
   */
  private saveUserRecord(
    noteId: number | undefined,
    data: any,
    userSubjectId: number
  ) {
    return this.coursesService
      .saveUserRecord(noteId, {
        IsModify: true,
        ...data,
        UserSubjectID: userSubjectId,
      })
      .pipe(
        switchMap((userRecord) => {
          return this.coursesService
            .patchMidTermsByID(userRecord["MidTermID"], { IsDisabled: true })
            .pipe(map((midTerm) => ({ midTerm, userRecord })));
        })
      );
  }

  colorSchool(idSchool: number) {
    let classes = {};
    switch (idSchool) {
      case 0:
        classes = { cat__info__item_CEDOC: true };
        break;
      case 1:
        classes = { cat__info__item_CEMIL: true };
        break;
      case 4:
        classes = { cat__info__item_EAS: true };
        break;
      case 5:
        classes = { cat__info__item_ESINF: true };
        break;
      case 6:
        classes = { cat__info__item_ESCAB: true };
        break;
      case 7:
        classes = { cat__info__item_ESART: true };
        break;
      case 8:
        classes = { cat__info__item_ESING: true };
        break;
      case 9:
        classes = { cat__info__item_ESCOM: true };
        break;
      case 10:
        classes = { cat__info__item_ESICI: true };
        break;
      case 16:
        classes = { cat__info__item_ESAVE: true };
        break;
      case 17:
        classes = { cat__info__item_ESLOG: true };
        break;
      case 18:
        classes = { cat__info__item_ESCEQ: true };
        break;
      case 19:
        classes = { cat__info__item_ESPOM: true };
        break;
      case 20:
        classes = { cat__info__item_ESMAI: true };
        break;
      case 21:
        classes = { cat__info__item_ESDIH: true };
        break;
      case 22:
        classes = { cat__info__item_CEMAI: true };
        break;
      case 23:
        classes = { cat__info__item_ESIDE: true };
        break;
      case 24:
        classes = { cat__info__item_ESFES: true };
        break;
      case 25:
        classes = { cat__info__item_CEDOC: true };
        break;
      case 33:
        classes = { cat__info__item_ESMIC: true };
        break;
      case 34:
        classes = { cat__info__item_EMSUB: true };
        break;
      case 35:
        classes = { cat__info__item_ESPRO: true };
        break;
      default:
        classes = { cat__info__item_ALL: true };
        break;
    }
    return classes;
  }

  textSchools(id: number) {
    var num = this.schools.find((x) => x.id == id);
    if (num) return num.NameTSchool;
    return "Nombre de Escuela";
  }

  exportTemplate() {
    let data: AOA = [];
    let wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
    let fileName: string = "Lista Estudiantes.xlsx";

    data.push([
      "APELLIDOS",
      "NOMBRES",
      "CEDULA",
      this.settingsStudentRecord.columns.Note1.title.toUpperCase(),
      this.settingsStudentRecord.columns.Note2.title.toUpperCase(),
      this.settingsStudentRecord.columns.Note3.title.toUpperCase(),
      this.settingsStudentRecord.columns.Note4.title.toUpperCase(),
      this.settingsStudentRecord.columns.Note5.title.toUpperCase(),
      this.settingsStudentRecord.columns.Note6.title.toUpperCase(),
    ]);

    this.listStudents.forEach((element) => {
      data.push([
        element.LastNames ? element.LastNames.toUpperCase() : "",
        element.Names ? element.Names.toUpperCase() : "",
        element.Document ? element.Document : "",
        isNullOrUndefined(element.Note1) ? "--" : element.Note1,
        isNullOrUndefined(element.Note2) ? "--" : element.Note2,
        isNullOrUndefined(element.Note3) ? "--" : element.Note3,
        isNullOrUndefined(element.Note4) ? "--" : element.Note4,
        isNullOrUndefined(element.Note5) ? "--" : element.Note5,
        isNullOrUndefined(element.Note6) ? "--" : element.Note6,
      ]);
    });

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  public sendMassiveEmail() {
    this.sendingMassiveEmail = true;
    const emailList = this.listStudents.map((e) =>
      this.coursesService.postNotificationQueue({
        namesTO: JSON.stringify([this.massiveEmailForm.get("title").value]),
        emailsTo: JSON.stringify([e.CedocEmail]),
        msg: JSON.stringify([
          { message: this.massiveEmailForm.get("message").value },
        ]),
        timeToSend: date.toISOString(),
        isSend: false,
        isSingleMessage: true,
        typeNotification: "email",
      })
    );

    if (this.massiveEmailForm.invalid) return;

    const date: Date = new Date();
    date.setMinutes(date.getMinutes() + 1);

    Swal({
      title: "Enviando correos...",
      text: "Esto puede tomar un tiempo, por favor, espere.",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => Swal.showLoading(),
    });

    concat(...emailList)
      .pipe(toArray())
      .subscribe({
        next: () => {
          this.massiveEmailForm.reset();
          Swal({
            type: "success",
            title: "¡Hecho!",
            text: "Correos enviados correctamente.",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
        },
        error: (err) => {
          Swal({
            type: "error",
            title: "¡Error!",
            text: `Se produjo un error al enviar los correos: ${err.message}`,
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          console.log(err);
        },
        complete: () => {
          this.sendingMassiveEmail = false;
        },
      });
  }

  exportGuide() {
    console.log("sourceStudentRecord ", this.listStudents);
    let list = this.listStudents;
    let data: AOA = [];
    let fileName: string = "Guía Estudiantes.xlsx";

    data.push([
      "APELLIDOS",
      "NOMBRES",
      "NUMERO DOCUMENTO",
      "NOTA 1",
      "NOTA 2",
      "NOTA 3",
      "NOTA 4",
    ]);
    list.map((item) => {
      data.push([
        item.LastNames,
        item.Names,
        item.Document,
        item.Note1,
        item.Note2,
        item.Note3,
        item.Note4,
      ]);
    });

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  /**
   * This function exports a PDF file of a table
   */
  exportTemplatePdf() {
    /**
     * Logic data.
     */
    let allDataNotes = [];
    let totalNotes = this.selectedSubjectInfo.minTerms.length;
    console.log("total notes", totalNotes);
    console.log("total notes", this.selectedSubjectInfo.minTerms);

    for (let i = 0; i < totalNotes; i++) {
      const item = this.selectedSubjectInfo.minTerms.find(
        (x) => x.MidTermNum == i + 1
      );

      if (!item) continue;

      const dataToReturn = {
        id: item.MidTermNum,
        name: item.NameMid,
        percent: item.Porcent,
      };

      allDataNotes.push(dataToReturn);
    }
    /**
     * init PDF data.
     */
    let jsPDF_options: jsPDFOptions = {
      orientation: "l",
      unit: "in",
      format: "letter",
    };
    const doc = new jsPDF(jsPDF_options);
    /**
     * Tabla de convenciones.
     */
    let HEADERS_TABLE_01 = ["", "", ""];
    let DATA_TABLE_01 = [
      [
        "Profesor",
        {
          content: `${this.selectedSubjectInfo.userapp.LastName1} ${this.selectedSubjectInfo.userapp.Name1}`,
          colSpan: 2,
        },
      ],
      [
        "Materia",
        { content: `${this.selectedSubjectInfo.NameSubjectGroup}`, colSpan: 2 },
      ],
      [
        "Oferta",
        {
          content: `${this.selectedSubjectInfo.CourseOffers.NameCourseOfer}`,
          colSpan: 2,
        },
      ],
      ["Apellido", "Apellido", "Apellido del estudiante"],
      ["Nombre", "Nombre", "Nombre del estudiante"],
      ["Documento", "Doc", "Documento del estudiante"],
      [
        "Nota 1",
        "N1",
        allDataNotes.length >= 1 ? `${allDataNotes[0].percent}%` : "0%",
      ],
      ["Conforme 1", "C1", "El estudiante esta o no esta conforme con la nota"],
      [
        "Nota 2",
        "N2",
        allDataNotes.length >= 2 ? `${allDataNotes[1].percent}%` : "0%",
      ],
      ["Conforme 2", "C2", "El estudiante esta o no esta conforme con la nota"],
      [
        "Nota 3",
        "N3",
        allDataNotes.length >= 3 ? `${allDataNotes[2].percent}%` : "0%",
      ],
      ["Conforme 3", "C3", "El estudiante esta o no esta conforme con la nota"],
      [
        "Nota 4",
        "N4",
        allDataNotes.length >= 4 ? `${allDataNotes[3].percent}%` : "0%",
      ],
      ["Conforme 4", "C4", "El estudiante esta o no esta conforme con la nota"],
      [
        "Nota 5",
        "N5",
        allDataNotes.length >= 5 ? `${allDataNotes[4].percent}%` : "0%",
      ],
      ["Conforme 5", "C5", "El estudiante esta o no esta conforme con la nota"],
      [
        "Nota 6",
        "N6",
        allDataNotes.length >= 6 ? `${allDataNotes[5].percent}%` : "0%",
      ],
      ["Conforme 6", "C6", "El estudiante esta o no esta conforme con la nota"],
      ["Nota Final", "NF", "Nota final del estudiante"],
      ["Inasistencias", "I", "Fallas en clase"],
    ];
    autoTable(doc, {
      head: [HEADERS_TABLE_01],
      body: DATA_TABLE_01,
      showHead: false,
      columnStyles: {
        0: { fillColor: [41, 128, 185], textColor: 255, fontStyle: "bold" },
      },
    });
    /**
     * Tabla de datos.
     */
    let HEADERS_TABLE_02_init = ["Apellido", "Nombre", "Doc"];
    let HEADERS_TABLE_02_notes = [];
    for (let item of allDataNotes) {
      let note = `N${item.id}`;
      let conf = `C${item.id}`;
      HEADERS_TABLE_02_notes.push(note);
      HEADERS_TABLE_02_notes.push(conf);
    }
    let HEADERS_TABLE_02_end = ["NF", "I"];
    let HEADERS_TABLE_02 = [
      ...HEADERS_TABLE_02_init,
      ...HEADERS_TABLE_02_notes,
      ...HEADERS_TABLE_02_end,
    ];
    let DATA_TABLE_02 = [];
    for (let student of this.listStudents) {
      let dataStudent = [
        `${student.LastNames}`,
        `${student.Names}`,
        `${student.Document}`,
      ];
      let notesStudent = [];
      if (1 <= totalNotes) {
        notesStudent.push(student.Note1);
        notesStudent.push(student.IsAccording1 == 1 ? "Si" : "No");
      }
      if (2 <= totalNotes) {
        notesStudent.push(student.Note2);
        notesStudent.push(student.IsAccording2 == 1 ? "Si" : "No");
      }
      if (3 <= totalNotes) {
        notesStudent.push(student.Note3);
        notesStudent.push(student.IsAccording3 == 1 ? "Si" : "No");
      }
      if (4 <= totalNotes) {
        notesStudent.push(student.Note4);
        notesStudent.push(student.IsAccording4 == 1 ? "Si" : "No");
      }
      if (5 <= totalNotes) {
        notesStudent.push(student.Note5);
        notesStudent.push(student.IsAccording5 == 1 ? "Si" : "No");
      }
      if (6 <= totalNotes) {
        notesStudent.push(student.Note6);
        notesStudent.push(student.IsAccording6 == 1 ? "Si" : "No");
      }
      let finalNoteStudent = [
        `${student.FinalRecord}`,
        `${student.TotalAbsences ? student.TotalAbsences : "0"}`,
      ];
      let allDataStudent = [
        ...dataStudent,
        ...notesStudent,
        ...finalNoteStudent,
      ];
      DATA_TABLE_02.push(allDataStudent);
    }
    autoTable(doc, {
      styles: { fontSize: 8 },
      head: [HEADERS_TABLE_02],
      body: DATA_TABLE_02,
    });
    /**
     * Tabla de firmas.
     */
    let HEADERS_TABLE_03 = ["", "", "", ""];
    let DATA_TABLE_03 = [
      ["", { content: "", rowSpan: 3 }, { content: "", rowSpan: 3 }],
      [],
      [],
      ["", "Docente", "Coordinador"],
      [
        "",
        `${this.selectedSubjectInfo.userapp.LastName1} ${this.selectedSubjectInfo.userapp.Name1}`,
        "",
      ],
    ];
    autoTable(doc, {
      head: [HEADERS_TABLE_03],
      body: DATA_TABLE_03,
      showHead: false,
      styles: {
        halign: "center",
        valign: "middle",
      },
    });
    /**
     * Se genera el PDF
     */
    doc.save("Notas.pdf");
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      let data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.showMasiveData(data);
      //console.log("data excel", data)
    };
    reader.readAsBinaryString(target.files[0]);
  }

  showMasiveData(data) {
    this.stepMassive = 0;
    this.sharedSevice.swalLoading("Importando datos");
    //Checkeo que exista la misma cantidad de datos
    console.log("cantidad de estudiantes", this.listStudents.length);
    console.log("cantidad de datos", data.length);

    if (this.listStudents.length != data.length - 1) {
      this.sharedSevice.sleep(1000).then(() => {
        this.sharedSevice.swalError(
          "Error al importar",
          "Verificar el número de estudiantes"
        );
      });
      return;
    }

    // verificar Nombre por nombre
    for (let index = 1; index < this.listStudents.length + 1; index++) {
      let element = data[index];
      let pos = -1;

      pos = this.listStudents.findIndex((x) => {
        return x.Document.toString().toLowerCase() == element[2].toString().toLowerCase();
      });
      
      if (pos >= 0) {
        if (this.listStudents[pos].Note1Id) {
          if (parseFloat(element[3]) < 0 || parseFloat(element[3]) > 10) {
            this.sharedSevice.sleep(1000).then(() => {
              this.sharedSevice.swalError(
                "Error al importar",
                " Únicamente es Valido Ingresar Números y Puntos En Las Notas y Deben Estar En Un Rango de 0 A 5"
              );
            });
            return;
          }
        }

        if (this.listStudents[pos].Note2Id) {
          if (parseFloat(element[4]) < 0 || parseFloat(element[4]) > 10) {
            this.sharedSevice.sleep(1000).then(() => {
              this.sharedSevice.swalError(
                "Error al importar",
                " Únicamente es Valido Ingresar Números y Puntos En Las Notas y Deben Estar En Un Rango de 0 A 5"
              );
            });
            return;
          }
        }

        if (this.listStudents[pos].Note3Id) {
          if (parseFloat(element[5]) < 0 || parseFloat(element[5]) > 5) {
            this.sharedSevice.sleep(1000).then(() => {
              this.sharedSevice.swalError(
                "Error al importar",
                " Únicamente es Valido Ingresar Números y Puntos En Las Notas y Deben Estar En Un Rango de 0 A 5"
              );
            });
            return;
          }
        }

        if (this.listStudents[pos].Note4Id) {
          if (parseFloat(element[6]) < 0 || parseFloat(element[6]) > 5) {
            this.sharedSevice.sleep(1000).then(() => {
              this.sharedSevice.swalError(
                "Error al importar",
                " Únicamente es Valido Ingresar Números y Puntos En Las Notas y Deben Estar En Un Rango de 0 A 5"
              );
            });
            return;
          }
        }

        if (this.listStudents[pos].Note5Id) {
          if (parseFloat(element[7]) < 0 || parseFloat(element[7]) > 5) {
            this.sharedSevice.sleep(1000).then(() => {
              this.sharedSevice.swalError(
                "Error al importar",
                " Únicamente es Valido Ingresar Números y Puntos En Las Notas y Deben Estar En Un Rango de 0 A 5"
              );
            });
            return;
          }
        }

        if (this.listStudents[pos].Note6Id) {
          if (parseFloat(element[8]) < 0 || parseFloat(element[8]) > 5) {
            this.sharedSevice.sleep(1000).then(() => {
              this.sharedSevice.swalError(
                "Error al importar",
                " Únicamente es Valido Ingresar Números y Puntos En Las Notas y Deben Estar En Un Rango de 0 A 5"
              );
            });
            return;
          }
        }
      } else {
        this.sharedSevice.sleep(1000).then(() => {
          this.sharedSevice.swalError(
            "Error al importar",
            "No Existe La persona " +
              element[0].toLowerCase() +
              " " +
              element[1].toLowerCase()
          );
        });
        return;
      }
    }

    // Si he llegado a este paso es porque todos los nombres existen
    // Cargar Notas
    for (let index = 1; index < this.listStudents.length + 1; index++) {
      let element = data[index];
      let pos = -1;
      pos = this.listStudents.findIndex(
        (x) => x.Document.toLowerCase() == element[2].toLowerCase()
      );
      if (pos >= 0) {
        this.listStudents[pos].Note1
          ? (this.listStudents[pos].Note1 = element[3])
          : "";
        this.listStudents[pos].Note2
          ? (this.listStudents[pos].Note2 = element[4])
          : "";
        this.listStudents[pos].Note3
          ? (this.listStudents[pos].Note3 = element[5])
          : "";
        this.listStudents[pos].Note4
          ? (this.listStudents[pos].Note4 = element[6])
          : "";
        this.listStudents[pos].Note5
          ? (this.listStudents[pos].Note5 = element[7])
          : "";
        this.listStudents[pos].Note6
          ? (this.listStudents[pos].Note6 = element[8])
          : "";
      }
    }
    this.sharedSevice.sleep(1000).then(() => {
      this.sharedSevice.swalSuccess(
        "Datos Importados",
        "Verificar las  notas y luego guardar"
      );
      this.sourceStudentRecord = new LocalDataSource(); // create the source
      this.stepMassive = 1;
      this.settingsStudentRecord.actions = {
        add: false,
        edit: false,
        delete: false,
        columnTitle: "0",
      };
      this.sourceStudentRecord.load(this.listStudents);
      console.log("this.sourceStudentRecord", this.sourceStudentRecord);
    });
  }

  saveMassive() {
    let cx = this;
    this.sharedSevice.swalLoading("Actualizando Notas Masivas");
    //Recorro cada estudiante la nota
    async.eachLimit(
      this.listStudents,
      10,
      function (student, cbGrade) {
        //Guardo en paralelo las notas, si existe el id actualizo nota, sino , solo se da el callback
        async.parallel(
          [
            function (callback1) {
              if (student.Note1Id) {
                cx.coursesService
                  .saveUserRecord(student.Note1Id, {
                    IsModify: true,
                    ParcialRecord: parseFloat(student.Note1),
                    professorNote: student.professorNote1,
                    IsAccording: true,
                  })
                  .subscribe(
                    (p) => {
                      callback1();
                    },
                    (error) => {
                      console.error(
                        `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                      );
                    }
                  );
              } else {
                callback1();
              }
            },
            function (callback2) {
              if (student.Note2Id) {
                cx.coursesService
                  .saveUserRecord(student.Note2Id, {
                    IsModify: true,
                    ParcialRecord: parseFloat(student.Note2),
                    professorNote: student.professorNote2,
                    IsAccording: true,
                  })
                  .subscribe(
                    (p) => {
                      callback2();
                    },
                    (error) => {
                      console.error(
                        `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                      );
                    }
                  );
              } else {
                callback2();
              }
            },
            function (callback3) {
              if (student.Note3Id) {
                cx.coursesService
                  .saveUserRecord(student.Note3Id, {
                    IsModify: true,
                    ParcialRecord: parseFloat(student.Note3),
                    professorNote: student.professorNote3,
                    IsAccording: true,
                  })
                  .subscribe(
                    (p) => {
                      callback3();
                    },
                    (error) => {
                      console.error(
                        `Ocurrió un error: ${error.message}. El error ocurrió en la siguiente ubicación: ${error.stack}`
                      );
                    }
                  );
              } else {
                callback3();
              }
            },
          ],
          function (err, results) {
            cbGrade(); //Calback para indicar que el esudiante se actualizo la nota
          }
        );
      },
      function (err) {
        cx.sharedSevice.sleep(1000).then(() => {
          cx.sharedSevice.swalSuccess(
            "Datos Importados",
            "Verificar las  notas y luego guardar"
          );
          cx.stepMassive = 0;
        });
      }
    );
  }

  ExportList() {
    var Exporar = [];
    Exporar.push({
      LastNames: "APELLIDOS",
      Names: "NOMBRES",
      "Note:1": this.settingsStudentRecord.columns.Note1.title.toUpperCase(),
      "Note:2": this.settingsStudentRecord.columns.Note2.title.toUpperCase(),
      "Note:3": this.settingsStudentRecord.columns.Note3.title.toUpperCase(),
      "Note:4": this.settingsStudentRecord.columns.Note4.title.toUpperCase(),
      "Note:5": this.settingsStudentRecord.columns.Note5.title.toUpperCase(),
      "Note:6": this.settingsStudentRecord.columns.Note6.title.toUpperCase(),
      FinalRecord: "NOTA FINAL",
    });
    this.listStudents.forEach((element) => {
      Exporar.push({
        LastNames: element.LastNames.toUpperCase(),
        Names: element.Names.toUpperCase(),
        "Note:1": isNullOrUndefined(element.Note1) ? "--" : element.Note1,
        "Note:2": isNullOrUndefined(element.Note2) ? "--" : element.Note2,
        "Note:3": isNullOrUndefined(element.Note3) ? "--" : element.Note3,
        "Note:4": isNullOrUndefined(element.Note4) ? "--" : element.Note4,
        "Note:5": isNullOrUndefined(element.Note5) ? "--" : element.Note5,
        "Note:6": isNullOrUndefined(element.Note6) ? "--" : element.Note6,
        FinalRecord: element.FinalRecord.toFixed(2),
      });
    });
    new AngularCsv(Exporar, "Lista Estudiantes");
  }

  /** ******************************************************************************************** */
  /** ******************** _INICIO LOGICA PARA EDITAR TODA LA TABLA AL TIEMPO ******************** */
  /** ******************************************************************************************** */
  @ViewChild("myTable") myTable: any;
  isTableRendered = false;
  ngAfterViewChecked() {
    if (this.showData && !this.isTableRendered) {
      this.isTableRendered = true;
      setTimeout(() => {
        this.onRowSelect();
      }, 2000);
    }
  }
  onRowSelect() {
    const grid = this.myTable.grid;
    const rows = grid.getRows();
    for (const row of rows) {
      row.isInEditing = !row.data.isWithdrawn;
    }
  }
  /** ******************************************************************************************** */
  /** ******************** ____FIN LOGICA PARA EDITAR TODA LA TABLA AL TIEMPO ******************** */
  /** ******************************************************************************************** */
}