import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProfesionalPerfil } from "../../models/usersInfo/profesionalperfil";
import { ProfesorCv } from '../../models/usersInfo/profesor-cv';
import { UsersService } from '../../models/usersInfo/users.service';
import { EvaluationBoxComponent } from './evaluation-box/evaluation-box.component';
import { environment } from 'environments/environment';

@Component( {
  selector: 'Cv-Public',
  templateUrl: './cv-public.component.html',
  providers: [ EvaluationBoxComponent ]
} )
export class CvPublicComponent implements OnInit, OnDestroy {
  cedocEmail: string;
  private sub: any;
  profesors: ProfesorCv;
  isInfo: Boolean = false;
  profileP: ProfesionalPerfil[]; V

  /// Radar
  public radarChartLabels: string[] = [
    "Planeación y Organización",
    "Conocimiento",
    "Pedagogía/Metodología",
    "Evaluación y Retroalimentación",
    "Relación y Comunicación",
    "Compromiso Institucional",
    "Currículo Propuesto",
    "Currículo en Uso",
    "Análisis Curricular"
  ];

  public radarChartData: any = [
    {
      data: [ 3.5, 4, 4.9, 3.8, 5, 3.2, 2, 2.7, 2.2 ],
      label: "Calidad Académica"
    },
    {
      data: [ 3, 3.8, 4, 4.2, 4.5, 4.5, 3.5, 4.5, 5 ],
      label: "Evaluación Estudiantes"
    },
    {
      data: [ 4.5, 4.8, 4.9, 4.2, 4.5, 4.5, 4.5, 4.5, 5 ],
      label: "Auto- Evaluación"
    }
  ];

  public radarChartOptions: any = { scale: { ticks: { max: 5, stepSize: 1, min: 0, beginAtZero: true } } };

  public radarChartType: string = "radar";

  /// Events
  public chartClicked( e: any ): void {
  }

  public chartHovered( e: any ): void {
  }

  public MAIN_SCHOOL_LOGO_SVG:any = environment.MAIN_SCHOOL_LOGO_SVG;

  constructor ( private route: ActivatedRoute, private usersService: UsersService, public evaluationbox: EvaluationBoxComponent ) {
    this.profesors = {
      id: 0
    };
    this.profileP = [ { id: null, UserID: null, Description: "..." } ];
  }

  ngOnInit() {

    this.sub = this.route.params.subscribe( params => {
      this.cedocEmail = params[ 'id' ]; // (+) converts string 'id' to a number

      this.usersService.getCvProfesorID( this.cedocEmail ).subscribe( p => {
        this.profesors = p

        this.evaluationbox.idProfileCV = p
        this.evaluationbox.ngOnInit()

        this.usersService.GetProfileByUserID( this.profesors.id ).subscribe( p => {

          this.profileP = p;

        } );
        this.isInfo = true;

      } );

    } );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}