import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RecordService } from "app/models/midterm/record.service";
import { swalAlert } from "../swal";

@Component({
  moduleId: module.id,
  selector: "edit-dates-form",
  templateUrl: "./edit-dates-form.component.html",
  styleUrls: ["./edit-dates-form.component.css"],
})
export class EditDatesFormComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private _recordService: RecordService
  ) {}

  @Input("schoolData") protected schoolData: any = undefined;
  @Output("updatedDates") protected updatedDates: EventEmitter<any> =
    new EventEmitter();

  @Input("court") protected set court(value: any) {
    if (value) {
      this.formGroup.patchValue({
        ...value,
        InitCorteI: value.InitCorteI.slice(0, 10),
        EndCorteI: value.EndCorteI.slice(0, 10),
        InitCorteII: value.InitCorteII.slice(0, 10),
        EndCorteII: value.EndCorteII.slice(0, 10),
        InitCorteIII: value.InitCorteIII.slice(0, 10),
        EndCorteIII: value.EndCorteIII.slice(0, 10),
        InitSemester: value.InitSemester.slice(0, 10),
        EndSemester: value.EndSemester.slice(0, 10),
      });
    }
  }

  public formGroup: FormGroup = this._fb.group({
    SchoolID: ["", Validators.required],
    SemesterName: ["", Validators.required],
    InitCorteI: ["", Validators.required],
    EndCorteI: ["", Validators.required],
    InitCorteII: ["", Validators.required],
    EndCorteII: ["", Validators.required],
    InitCorteIII: ["", Validators.required],
    EndCorteIII: ["", Validators.required],
    NDays: ["", Validators.required],
    InitSemester: ["", Validators.required],
    EndSemester: ["", Validators.required],
    id: null,
  });
  public isSavingData: boolean = false;

  ngOnInit(): void {
    //
  }

  public saveDates() {
    this.formGroup.patchValue({
      SchoolID: this.schoolData.SchoolID,
    });

    if (!this.formGroup.valid) return;

    this.isSavingData = true;
    swalAlert("loading")("Guardando...", "");


    console.log(this.formGroup.value);
    

    this._recordService.patchDates(this.formGroup.value).subscribe({
      next: (resp) => {
        this.updatedDates.emit(resp);
        swalAlert("success")("Hecho!", "Se han guardado los datos con éxito.");
      },
      error: (err) => {
        console.log(err);
        swalAlert("error")("Error!", "Ha ocurrido un error: " + err.message);
      },
      complete: () => (this.isSavingData = false),
    });
  }
}
