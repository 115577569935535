import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'Tutorial',
    templateUrl: './tutorialalumni.component.html'
})
export class TutorialalumniComponent {
    public TUTORIAL_IMAGE_DYNAMIC:any = (id:number) => environment.TUTORIAL_IMAGE_DYNAMIC(id);
    public TUTORIAL_LAYOUT_DYNAMIC:any = (id:string) => environment.TUTORIAL_LAYOUT_DYNAMIC(id);
    public SCHOOL_NAME:string = environment.SCHOOL_NAME;

}