import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UsersField } from "../../models/usersInfo/users-fields";
import { UsersService } from "../../models/usersInfo/users.service";
import { Router } from "@angular/router";
import { ProfileNavComponent } from '../../components/profile-nav/profile-nav.component';
import { Countrie } from '../../models/usersInfo/countrie';
import { CoursesService } from '../../models/Coursesinfo/courses.service';
import { HealthInfoInterface } from 'app/models/usersInfo/healthInfoInteface';
import { environment } from 'environments/environment';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { RecordService } from 'app/models/midterm/record.service';
import { EditSubjectFormComponent } from './edit-subject-form/edit-subject-form.component';
import { WellnessService } from 'app/models/wellness/wellness.service';
import { EditDatesFormComponent } from './edit-dates-form/edit-dates-form.component';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { swalAlert } from './swal';
import { Article } from 'app/models/wellness/article';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
 enum UserActions { news = 'news', subjects = 'subjects', dates = 'dates',  myCourses = 'myCourses' }
 enum FormToShow { subjects = 'subjects', evaluation = 'evaluation',  dates = 'dates' }


@Component( {
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: [ './profile.component.css' ],
  providers: [ UsersService ],
} )

export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild( BaseChartDirective ) chart: BaseChartDirective;
  @ViewChild( EditSubjectFormComponent ) editSubjectForm: EditSubjectFormComponent;
  @ViewChild( EditDatesFormComponent ) editDatesForm: EditDatesFormComponent;

  public userActionsEnum = UserActions;
  public userActions:UserActions = UserActions.news;

  public loadingCourses:boolean = false;
  public loadingMyCourses:boolean = false;


  //Admin subjects variables
  public courseOffers:any[] = [];
  public offerSubjects:any[] = [];
  public selectedSubjectGroup:any = undefined;
  private _subjectGroups:any[] = [];
  public doughnutChartLabels: any[] = [];
  public doughnutChartData: number[] = [];
  public chartColors: any[] = [ { backgroundColor:["#c7522a", "#e5c185", "#7593af", "#74a892", "#008585"] }];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions: any = {
    maintainAspectRatio: false,
    responsive:true,
    legend: { display: false },
    plugins: { },
  };

  //Admin date variables
  public dates:any = undefined;
  public datesHistory:any = undefined;

  //Main variables
  public news: any[] = [];
  public countries: Countrie[] | undefined = undefined;
  public userHealthInfo: HealthInfoInterface | undefined = undefined;
  public userData: UsersField | undefined = undefined;
  public roles:string[] = [];
  public schoolData: any = undefined;
  public currentUserAge: number = 0;
  public typeDocument: string = '';
  public evaluationData:any[] = [];
  private _allSchools:any[] = [];
  htmlContent: SafeHtml;

  //My courses variables
  public myCourses:any[] = [];
  public myCourseSelected:any = undefined;

  //Form variables
  public formToShowEnum = FormToShow;
  public formToShow:FormToShow = FormToShow.subjects;
  public showForm:boolean = false;
  public userForm:FormGroup = this._fb.group({
    UserID:['', Validators.required],
    CellPhone:['', Validators.required],
    Email:['', Validators.required],
  });

  //Others
  public isSavingData:boolean = false;
  public isEditingUser:boolean = false;
  public olwNewsSettings: OwlOptions = {
    navText:[
      '<em class="icon-i-angle-right-1"></em>',
      '<em class="icon-i-angle-right"></em>'
    ],
    nav: true,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    dots: true,
    dotsSpeed: 600,
    autoplay: true,
    autoplaySpeed:600,
    autoplayTimeout: 30000,
    responsive: { 0: { items: 1 } },
  }
  public selectedNews:any = undefined;
  public showNewsForm:boolean = false;

  //#region ENV variables
  public SCHOOL_LOADER: any = environment.SCHOOL_LOADER;
  public CEMIL_ICON: any = environment.CEMIL_ICON;
  public EJC_ICON: any = environment.EJC_ICON;
  public DEFAULT_AVATAR: any = environment.DEFAULT_AVATAR;
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;
  public SCHOOL_PHOTO_DYNAMIC:any = (id:number = 0) => environment.SCHOOL_PHOTO_DYNAMIC(id);
  public FORCE_LOGO_DYNAMIC:any = (id:number = 0) => environment.FORCE_LOGO_DYNAMIC(id);
  public SCHOOL_LOGO_DYNAMIC_SVG:any = (id:number) => environment.SCHOOL_LOGO_DYNAMIC_SVG(id);
  //#endregion

  constructor (
    private _coursesService: CoursesService,
    private _fb:FormBuilder,
    private _router: Router,
    private _userService: UsersService,
    private _wellnessService: WellnessService,
    private _recordService:RecordService,
    private _renderer:Renderer2,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private _document:Document,
    public profileNavComponent: ProfileNavComponent ) {
  }

  get schoolPhotoUrl(): string {
    return `url(${this.SCHOOL_PHOTO_DYNAMIC(this.schoolData.SchoolID)})`;
  }

  ngOnInit() {
    const userId = localStorage.getItem("currentUser");

    if(!userId){
      this._router.navigate(['/register']);
      return;
    }

    const userData = this._userService.get(Number(userId)).pipe(
      switchMap(user => {
        const document = user.UserDocuments.length > 0 ?
          this._userService.getAllTypeDocumentsByID(user.UserDocuments[0].TypeDocumentID) :
          of(null)

        return document.pipe(
          map((typeDocument: any) => ({ ...typeDocument, user }))
        )
      })
    )

    forkJoin({
      schools: this._coursesService.getAllSchools(),
      countries: this._coursesService.getAllCountrie(),
      healthInfo: this._userService.getHealthInfoByUserID( Number(userId) ),
      userData,
      evaluation: this._wellnessService.getIndexFormUserByUserID(Number(userId))
    }).subscribe({
      next:({countries, healthInfo, userData, evaluation, schools}) => {
        this.roles = this.profileNavComponent.nameRoles;

        if(this.roles.length === 0){
          swalAlert('error')('Error', 'Este usuario no cuenta con roles.');
          return;
        }

        this._allSchools = schools;
        this.schoolData = this.profileNavComponent.roles[ 0 ];
        this.getNews();

        this.countries = countries;
        this.userHealthInfo = healthInfo ? healthInfo[0] : undefined;

        this.evaluationData = evaluation;

        this.userData = userData.user;
        this.typeDocument = userData.document ? userData.document.NameTypeDocument : '';
        this.currentUserAge = this.getCurrentAge();
        this.patchUserForm();

        if( this.roles.includes('Docente') || this.roles.includes('SuperDocente') )
          this.courseInfo();

        if( this.roles.includes('Estudiante') )
          this.ownCourses();

        if( this.roles.includes('Inspector Escuela') || this.roles.includes('isAdmin') )
          this.getDates();
      },
      error:(err)=>{
        console.log(err);
      }
    });
  }

  //Set form default values
  private patchUserForm(){
    this.userForm.patchValue({
      UserID: this.userData!.id,
      CellPhone: this.userData.ContactInfos.length > 0 ? this.userData.ContactInfos[0].CellPhone : '',
      Email: this.userData.ContactInfos.length > 0 ? this.userData.ContactInfos[0].Email : '',
    })
  }

  setHtmlContent( stringHtml) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(stringHtml);
    return this.htmlContent
  }
  //Update user data
  public updateUserData(){
    if(!this.userForm.valid)
      return;

    swalAlert('loading')('Guardando...', '');
    this.isSavingData = true;

    const data = this.userForm.value;
    const filteredData = {};

    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== null && data[key] !== '')
        filteredData[key] = data[key];
    }

    this._userService.updateInfoPerson(filteredData).pipe(
      switchMap( (personalDetails) => {
        let dataToSendv2 = {
          id: this.userData.id,
          email: filteredData['Email']
        }
        console.log('filteredData:',filteredData);
        console.log('dataToSendv2:',dataToSendv2);
        return this._userService.updateUserappEmail(dataToSendv2).pipe(
          map(responseUserAppEmail => ({personalDetails, responseUserAppEmail}))
        );
      }),
      switchMap( (allResponse) => {
        console.log('allResponse: ',allResponse);
        let {personalDetails, responseUserAppEmail} = allResponse
        return this._userService.get(this.userData.id).pipe(
          map(userInfo => ({personalDetails, userInfo, responseUserAppEmail}))
        );
      }),
    ).subscribe({
      next:(data)=>{
        console.log('data:',data);
        let {userInfo} = data;
        this.userData = { ...userInfo };
        this.isEditingUser = false;
        this.isSavingData = false;
        swalAlert('success')('Hecho!', 'Se han guardado los datos con éxito.');
      },error:(err)=>{
        this.isSavingData = false;
        swalAlert('error')('Error!', 'Ha ocurrido un error: ' + (err.message || err));
      }
    })


    // let dataToSend = {
    //   id: 19426,
    //   password: 'nuevoPassword'
    // }
    // this._userService.updatePassword(dataToSend).pipe(take(1)).subscribe({
    //   next:(response)=>{
    //     console.log('response: ',response);
    //     swalAlert('success')('Hecho!', 'Se han guardado los datos con éxito.');
    //   },error:(err)=>{
    //     swalAlert('error')('Error!', 'Ha ocurrido un error: ' + (err.message || err));
    //   }
    // })

    // let dataToSendv2 = {
    //   id: 19426,
    //   email: 'dacardo4Editado@gmail.com'
    // }
    // this._userService.updateUserappEmail(dataToSendv2).pipe(take(1)).subscribe({
    //   next:(response)=>{
    //     console.log('response: ',response);
    //     swalAlert('success')('Hecho!', 'Se han guardado los datos con éxito.');
    //   },error:(err)=>{
    //     swalAlert('error')('Error!', 'Ha ocurrido un error: ' + (err.message || err));
    //   }
    // })
  }

  //Get user age
  public getCurrentAge() {
    const contactInfo = this.userData.ContactInfos[0];
    const birthDate = contactInfo && contactInfo.birthDate ? new Date(contactInfo.birthDate) : new Date();
    const currentDate = new Date();
    let age:any = currentDate.getFullYear() - birthDate.getFullYear();

    if (currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }


  private getNews(){
    const currentSchoolId = this.schoolData.SchoolID;
    this._coursesService.getNews(currentSchoolId).subscribe({
      next: (news) => {
        this.news = news;
      }, error: (err) =>
        console.log(err)
    })
  }

  //Gets subjects and offers
  private courseInfo(){
    this.loadingCourses = true;

    this._coursesService.getSubjectByProfesorID(this.userData.id).pipe(
      switchMap((groups: any[]) => {

        if(groups.length === 0)
          return of({ groups:[], courses:[] });

        //Create a courses observable list, only unique CourseOfferID
        const uniqueCourseIds: number[] = [];
        const courseObs: any[] = [];

        for (let i = 0; i < groups.length; i++) {
          if (!uniqueCourseIds.includes(groups[i].CourseOferID)) {
            const obs = this._coursesService.getCoursesByID(groups[i].CourseOferID).pipe(
              catchError(error => {
                console.log({ error: 'Error no se encontraron resultados para la oferta: ' + groups[i].CourseOferID });
                return of(null);
              }),
            );
            courseObs.push(obs);
            uniqueCourseIds.push(groups[i].CourseOferID);
          }
        }

        return forkJoin(courseObs).pipe(
          map(courses => ({ groups, courses }))
        )
      })
    ).subscribe({
      next: ({ groups, courses }:any) => {
        //Removes null items
        courses = courses.filter( e => e);

        //Assign course to the group, pd: for a better approach use loopback relations
        let filteredGroups:any[] = [...groups];
        for (let i = 0; i < filteredGroups.length; i++) {
          for (let x = 0; x < courses.length; x++) {
            if(courses[x].id === filteredGroups[i].CourseOferID)
              Object.assign(filteredGroups[i], { CourseOffers: courses[x] });
          }
        }

        //Filter only active courses
        const result = filteredGroups.filter( e =>  e.CourseOffers && e.CourseOffers.IsActive);
        this._subjectGroups = result;

        if(result.length === 0){
          this.loadingCourses = false;
          return;
        }

        const courseOffers = result.map((e: any) => e.CourseOffers);
        const uniqueCourseOffers = courseOffers.reduce((unique: any[], current: any) => {
          if (!unique.some(obj => obj.id === current.id))
            unique.push(current);

          return unique;
        }, []);

        this.courseOffers = uniqueCourseOffers;
        this.loadingCourses = false;
      }, error: (err) => {
        this.loadingCourses = false;
        console.log(err);
      }
    })
  }

  //#region Admin subjects
  public onSelectSubject(event:any){
    const value = event.target.value;
    this.selectedSubjectGroup = undefined;
    this.doughnutChartLabels = [];
    this.doughnutChartData = [];

    const subjectGroup = this._subjectGroups.find( e => e.id == value);
    this.updateChart(subjectGroup);
  }

  private updateChart(subjectGroup:any){
    if(!subjectGroup.minTerms)
      return;

    this.selectedSubjectGroup = subjectGroup;
    this.doughnutChartLabels = subjectGroup.minTerms.map((e, index) => e.NameMid ? e.NameMid : index + 1);
    this.doughnutChartData = subjectGroup.minTerms.map((e) => e.Porcent ? e.Porcent : 0);
  }

  public saveSubjects(){
    if(this.editSubjectForm.formGroup.valid){
      this.editSubjectForm.saveSubjects();
      this.isSavingData = this.editSubjectForm.isSavingData;
    }
  }

  public updateSubjectGroupData(event:any){
    this.updateChart(event);
    this.isSavingData = this.editSubjectForm.isSavingData;
  }
  //#endregion

  //#region Admin dates calls
  private getDates() {
    this._recordService.getDatesBySchool(this.schoolData.SchoolID).subscribe({
      next: (dates) => {
        if (dates.length === 0)
          return;

        this.dates = dates[0];        
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  /**
   * Calculate dates difference
   * @param untilDate 
   * @returns 
   */
  public dateDiff(untilDate) {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(untilDate);
    const secondDate = new Date();

    if (firstDate.getTime() < secondDate.getTime())
      return 0

    return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
  }

  public onSelectDateSubject(event:any){
    const value = event.target.value;
    const subjectGroup = this._subjectGroups.find( e => e.id == value);

    const minTermsObs:Observable<any>[] = subjectGroup.minTerms.map(e => this._coursesService.getAllDaterecordByMidterm(e.id));
    combineLatest(minTermsObs).subscribe({
      next:(resp)=>{
        this.datesHistory = resp;
      },error:(err)=>{
        console.log(err);
      }
    })
  }
  //#endregion

  //Own courses
  private ownCourses(){
    this.loadingMyCourses = true
    this._coursesService.getCoursesByUserID(this.userData.id).pipe(
      switchMap( courses => {
        const courseOffer:any[] = courses.filter( e => e.coursesOfer);

        if(courseOffer.length == 0)
          return of([])

        const observables: any[] = courseOffer.map(course =>
          this._coursesService.getSubjectGroupsByCourseGroupID(course.CourseGroupID).pipe(
            catchError(error => {
              console.log(`Error al cargar los subject groups para el curso con ID: ${course.CourseGroupID}: `, error);
              return of(null);
            }),
            filter(data => data !== null)
          ))

        return forkJoin(observables).pipe(
          map(subjectsArrays => {
            return courseOffer.map((course, index) => {
              if (subjectsArrays[index])
                course.subjects = subjectsArrays[index];

              return course;
            });
          })
        )
      })
    ).subscribe({
      next:(coursesWithSubjects )=>{
        if(coursesWithSubjects.length === 0){
          this.loadingMyCourses = false;
          return;
        }

        this.myCourses = coursesWithSubjects.filter(course => course.subjects);
        this.loadingMyCourses = false;
      },error:(err)=>{
        this.loadingMyCourses = false;
        console.log(err);
      }
    })
  }

  public onSelectMyOwnCourses(event:any){
    const value = event.target.value;
    this.myCourseSelected = this.myCourses.find( e => e.id == value); 
  }

  // Filter subjects by subjectGroup
  public onSelectOffer(event:any, select:any){
    const value = event.target.value;
    select.value = '';

    this.offerSubjects = [];
    const subjects = [...this._subjectGroups].filter((e: any) => e.CourseOffers && e.CourseOferID == value);
    this.offerSubjects = subjects;
  }

  public onsUserAction(action: UserActions): void {
    this.userActions = action;
  }

  public formToShowAction(action: FormToShow): void {
    this._renderer.setStyle(this._document.body, 'overflow', 'hidden');
    this.showForm = true;
    this.formToShow = action;
  }

  public closeGeneralModal(){
    if(this.isSavingData)
      return;

    this.showForm = false;
    this._renderer.setStyle(this._document.body, 'overflow', 'auto');
  }

  public openNewsModal(item:any){
    this.showNewsForm = true;
    this._renderer.setStyle(this._document.body, 'overflow', 'hidden');
    this.selectedNews = item;
  }

  public closeNewsModal(){
    this.showNewsForm = false;
    this._renderer.setStyle(this._document.body, 'overflow', 'auto');
  }

  public stopPropagation(event:any){
    event.stopPropagation();
  }

  public trackByFn(index, item){
    return item.id;
  }

  public headerColor(){
    return{
      'background-image': `linear-gradient(to left, transparent, ${this.schoolData.Color}CC), ${this.schoolPhotoUrl}`,
      'background-size': 'cover',
      'background-position': 'center'
    }
  }

  public academicInfoBg(){
    return{
      'background': `linear-gradient(to right, ${this.schoolData.Color}F2, ${this.schoolData.Color}B3)`,
    }
  }

  ngOnDestroy(){ }

  public getImgNews(article: Article): string {
    let imgLink = '';
    if (article.Imagen) imgLink = article.Imagen;
    else imgLink = this.DYNAMIC_SCHOOL_PHOTO(article.SchoolID);
    return imgLink;
  }
  public DYNAMIC_SCHOOL_PHOTO: any = (id: number = 0) => environment.DYNAMIC_SCHOOL_PHOTO(id);
}