import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../Auth/auth.service';
import { catchError } from 'rxjs/operators';


@Injectable()

export class WellnessService {

  constructor(
    private http : HttpClient,
    private authService: AuthService
  ) { }

  private baseUrl: string = this.authService.UrlAPI;

  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }



  getCareAreaBySchool(schools:number[]): Observable<any>{
    let url: string = `CareAreas?filter={"where": {"SchoolID": {"inq": [${schools}]}}}`
    let query = this.http
     .get(`${this.baseUrl}/`+url, { headers: this.getHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
    ) 
     return query;
  }

  getArticles(): Observable<any>{
    const today = new Date();
    let url: string = `Articles?filter={ "where": {"and":[{"Until":{"gt": "${today}"}},{"IsPrivate":true}]}, "order": "id DESC","limit": 30,"include":["careArea"]}`
    let query = this.http
     .get(`${this.baseUrl}/`+url, { headers: this.getHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
    ) 
     return query;
  }

  getArticleByID(id:number): Observable<any>{
    let url: string = `Articles/${id}`
    let query = this.http
     .get(`${this.baseUrl}/`+url, { headers: this.getHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
    ) 
     return query;
  }

  getIndexFormUserByUserID(id:number): Observable<any>{
    let url: string = `indexFormUsers?filter={"where":{"and":[{"UserID":${id}},{"IsAnswer":"0"}]},"include":"IndexForm"}`
    let query = this.http
     .get(`${this.baseUrl}/`+url, { headers: this.getHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
    ) 
     return query;
  }
  
}
