import { UserCVComponent } from '../../user-cv.component';
import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersField } from 'app/models/usersInfo/users-fields';
import { Academic } from 'app/models/Academicinfo/academic';
import { Course } from 'app/models/Coursesinfo/course';
import { UsersService } from 'app/models/usersInfo/users.service';
import { AcademicService } from 'app/models/Academicinfo/academic.service';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { AuthService } from 'app/models/Auth/auth.service';

declare var jQuery: any;

@Component({
  moduleId: module.id,
  selector: 'add-academicinfo',
  templateUrl: './add-academicinfo.component.html',
  styleUrls: ['./add-academicinfo.component.css']
})

export class AddAcademicInfoComponent implements OnInit {

  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/];
  User_info: UsersField = new UsersField();
  AcademicInfos: Academic;
  courses: Course[];
  user: UsersField;
  TypeCourses: any[];
  stateButton: boolean = false
  isok: Boolean;
  errorMessage: string = '';
  submitbuton: string = 'Aceptar';
  fileToUpload: File;
  imagebuton = "Subir Archivo";
  isImageError = true;
  enablesavefile = false;


  constructor(public userCVComponent: UserCVComponent,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public academicService: AcademicService,
    private router: Router,
    public attachmentService: AttachmentService,
    private authService: AuthService) {
  }

  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {

    this.AcademicInfos = {
      IsCurrent: false,

    }
    this.AcademicInfos["stateUploadFile"] = "Subir Diploma"
    if (!isNullOrUndefined(this.userCVComponent.User_info))
      this.User_info = this.userCVComponent.User_info;

    this.academicService.getAllTypeCourses().subscribe(p => this.TypeCourses = p);
  }

  uploadFile(fileInput: any) {
    this.AcademicInfos["newCertificate"] = <File>fileInput.target.files[0];
    if (this.AcademicInfos["newCertificate"].size > 1000000) {//1M
      this.AcademicInfos["stateUploadFile"] = "Error de Archivo, No debe superar 1MB"
      this.AcademicInfos["enablesavefile"] = false
      console.error("ARCHIVO MAS DE 1MB")
    }
    else {
      this.AcademicInfos["stateUploadFile"] = "Archivo OK"
      this.AcademicInfos["enablesavefile"] = true
    }
  }

  CreateAcademicsInfo(model: Academic, isValid: boolean) {
    console.log(model)

    if (isValid) {
      this.stateButton = true
      console.debug(model);
      this.errorMessage = "Validando ... ";
      this.submitbuton = "Validando ... ";

      if (model.IsCurrent) {
        this.academicService.saveAcademic(model).subscribe(
          p => {
            this.stateButton = false
            this.isok = true;
            this.userCVComponent.ngOnInit();
            this.AcademicInfos = {};
            this.userCVComponent.AcademicInfos = [];
            this.errorMessage = '';
            this.submitbuton = 'Guardar'

            //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
            setTimeout(function () {
              jQuery("#ModalAcademic").modal('hide');
              jQuery(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
              jQuery(':checkbox, :radio').prop('checked', false);
            }, 100);
          },
          e => {
            this.stateButton = false
            this.errorMessage = "Corrija los datos erróneos";
            this.isok = false;
            this.submitbuton = 'Actualizar'
          });

      }
      else {

        if (this.AcademicInfos["enablesavefile"] == true) {

          this.attachmentService.NewAcademicInfoSuports(this.AcademicInfos["newCertificate"]).then((result) => {

            model.Diploma = "/attachments/academicinfo/download/" + result['name'];

            this.academicService.saveAcademic(model).subscribe(
              p => {
                this.stateButton = false
                this.isok = true;
                this.userCVComponent.ngOnInit();
                this.AcademicInfos = {};
                this.AcademicInfos["stateUploadFile"] = "Subir Diploma"
                this.userCVComponent.AcademicInfos = [];
                this.errorMessage = '';
                this.submitbuton = 'Guardar'

                //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
                setTimeout(function () {
                  jQuery("#ModalAcademic").modal('hide');
                  jQuery(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
                  jQuery(':checkbox, :radio').prop('checked', false);
                }, 100);
              },
              e => {
                this.stateButton = false
                this.errorMessage = "Corrija los datos erróneos";
                this.isok = false;
                this.submitbuton = 'Actualizar'
              });
          }, (error) => {
            console.error(error);
            this.authService.swalError(
              "Error en Archivo",
              ""
            );
          })

        }
        else {
          this.academicService.saveAcademic(model).subscribe(
            p => {
              this.stateButton = false
              this.isok = true;
              this.userCVComponent.ngOnInit();
              this.AcademicInfos = {};
              this.AcademicInfos["stateUploadFile"] = "Subir Diploma"
              this.userCVComponent.AcademicInfos = [];
              this.errorMessage = '';
              this.submitbuton = 'Guardar'

              //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
              setTimeout(function () {
                jQuery("#ModalAcademic").modal('hide');
                jQuery(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
                jQuery(':checkbox, :radio').prop('checked', false);
              }, 100);
            },
            e => {
              this.stateButton = false
              this.errorMessage = "Corrija los datos erróneos";
              this.isok = false;
              this.submitbuton = 'Actualizar'
            });
        }


      }
    }



  }
}