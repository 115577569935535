import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncateText'
})

export class TruncateTextPipe implements PipeTransform {
	transform(text: string, qty: number, byWords: boolean = true): any {
    if (!text) return '';

    let words = byWords ? text.split(' ') : Array.from(text);
    let result = '';

    if (byWords) {
      result = words.slice(0, qty).join(' ');
    } else {
      result = text.slice(0, qty);
    }

    result = result.trim();

    const isExceeded = words.length > qty;
    return {
      text: isExceeded ? result + '...' : result,
      isExceeded
    };
	}
}