import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../Auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private _authService:AuthService,
    private _http : HttpClient,
  ) { }

  private _baseUrl: string = this._authService.UrlAPI;


  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this._authService.getToken()
    });
    return headers;
  }

  myUserCourses(filter: string, ): Observable<any> {
    const url: string = `/Userapps/me/userCourses?=filter${filter}`;
    return this._http.get(`${this._baseUrl}${url}`,{ headers: this.getHeaders()});
  }

  getCourseGroups(filter: string): Observable<any> {
    const url = `/CourseGroups?filter=${filter}`
    return this._http.get(`${this._baseUrl}${url}`,{ headers: this.getHeaders()});
  }

  getSubjectGroups(filter: string): Observable<any> {
    const url: string = `/SubjectGroups?filter=${filter}`
    return this._http.get(`${this._baseUrl}${url}`,{ headers: this.getHeaders()});
  }


  getMyInfo(): Observable<any> {
    const url: string = `/userapps/me/`
    return this._http.get(`${this._baseUrl}${url}`,{ headers: this.getHeaders()});
  }

}


