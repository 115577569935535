import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import swal from 'sweetalert2'
import { ProfesionalPerfil } from "app/models/usersInfo/profesionalperfil";
import { UsersService } from 'app/models/usersInfo/users.service';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';
import { AcademicService } from 'app/models/Academicinfo/academic.service';
import { WorkService } from 'app/models/worksinfo/works.service';
import { ScientificService } from 'app/models/Scientificinfo/scientific.service';
import { Work } from 'app/models/worksinfo/work';
import { Academic } from 'app/models/Academicinfo/academic';
import { Scientific } from 'app/models/Scientificinfo/scientific';
import { UsersField } from 'app/models/usersInfo/users-fields';
import { AuthService } from 'app/models/Auth/auth.service';
import { ProfileNavComponent } from 'app/components/profile-nav/profile-nav.component';
import { UserDocument } from 'app/models/usersInfo/userDocument';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { TypeDocument } from '../../../../models/usersInfo/typedocument';

import * as moment from 'moment';
import { environment } from 'environments/environment';
declare let jQuery: any;


@Component({
  selector: 'user-cv',
  templateUrl: './user-cv.component.html',
  styleUrls: ['./user-cv.component.css'],
  providers: [UsersService, CoursesService, AcademicService, WorkService, ScientificService],
})
export class UserCVComponent implements OnInit {
  //DECLARO VARIABLES
  WorkInfos: Work[];
  UserInfos: UserDocument;
  AcademicInfos: Academic[];
  ScientificInfos: Scientific[];
  profileP: ProfesionalPerfil[];
  contactInfo:any = { medicalCondition: ''};
  user: string;
  User_info: UsersField = new UsersField();
  EditWork: Work[];
  EditWorkID: number;
  EditAcademic: Academic[];
  EditAcademicID: number;
  EditScientific: Scientific[];
  EditScientificID: number;
  ShowWork: boolean = true;
  ShowAcademic: boolean = true;
  ShowScientific: boolean = true;
  public DateMessage = ""
  isok: boolean;
  errorMessage: string = '';
  submitbuton: string = 'Actualizar'
  serverTime: Date;

  fileToUpload: File;
  imagebuton = "Subir Archivo";
  buttonEcaes = "Soporte SaberPRO";
  buttonEcaes2 = "Actualizar SaberPro"
  buttonIcfes = "Soporte ICFES";
  buttonIcfes2 = "Actualizar ICFES"
  buttonDocumento = "Soporte Documento"
  buttonDocumento2 = " Actualizar Documento"
  classStyleDocument = "btn btn-primary  btn-file"
  classStyleIcfes = "btn btn-primary btn-file"
  classStyleSaberPro = "btn btn-primary btn-file"
  isImageError = true;
  enablesavefile = false
  DocumentFile: string = "";
  IcfesID: string = "";
  SaberProID: string = "";

  UserInfo: any
  stateButton: boolean = false
  typeDocuments: TypeDocument[];
  infoLaboral: any

  //Environment variables
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;
  public CEMIL_ICON:any = environment.CEMIL_ICON;
  public EJC_ICON:any = environment.EJC_ICON;
  public CV_IMAGES:any = environment.CV_IMAGES;
  public UrlAPI = environment.UrlAPI;



  constructor(private authService: AuthService,
    private router: Router,
    public servicios: UsersService,
    public coursesService: CoursesService,
    public academicServices: AcademicService,
    public workServices: WorkService,
    public scientificService: ScientificService,
    public profileNavComponent: ProfileNavComponent,
    public attachmentService: AttachmentService
  ) {

    this.user = this.authService.getCurrentUser(); 

    if (Number.isInteger(parseInt(this.user))) {

      let validar_usuario = false;

      this.profileNavComponent.roles.forEach(role => {
        if (role.name == "Estudiante")
          validar_usuario = true;
        if (role.name == "Docente")
          validar_usuario = true;
        if (role.name == "Aspirante")
          validar_usuario = true;
      });

      if (!validar_usuario) {
        this.router.navigate(['/user/my-account']);
      }
    }
    else{
      this.router.navigate(['/register']);
    }


  }

  DatosDisponibles: boolean = false;
  DatosDisponiblesEditar: boolean = false;
  DatosDisponiblesEditarAcademic: boolean = false;
  DatosDisponiblesEditarScientific: boolean = false;

  ngOnInit() {

    this.profileP = [{ id: null, UserID: null, Skills:"",Language:"", Description: "Description" }];

    this.UserInfo = {
      Description: "",
      Skills: "",
      Language: "",
      ProfesionalProfileID: 0,
      UserDocumentID: 0,
    };

    this.servicios.get(parseInt(this.user)).subscribe(response => {
      this.User_info = response;
      this.contactInfo.medicalCondition = response.ContactInfos[0].medicalCondition || '';
      this.User_info.UserID = this.user;
    });

    swal({
      type: 'success',
      title: "Cargando Datos",
      text: "Esto Puede Tomar Un Tiempo Dependiendo De Su Conexión A Internet",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,

      onOpen: function () {
        swal.showLoading()
      }
    })


    //GUARDO LA HORA DEL SERVIDOR
    this.authService.getTime().then(server2 => this.serverTime = server2.time);

    this.servicios.GetProfileByUserID(parseInt(this.user)).subscribe(p => {
      if (p.length > 0)
        {
          this.profileP = p;
          this.UserInfo.ProfesionalProfileID = p[0].id
        }
    });
    //CARGO LOS TRABAJOS DE LA PERSONA
    this.workServices.getWorkByUserID(parseInt(this.user)).subscribe(p => {
      this.WorkInfos = p;
    });
    //CARGO LA INFORMACION ACADEMICA  DE LA PERSONA
    this.academicServices.getAcademicInfosByUserID(parseInt(this.user)).subscribe(p => {
      this.AcademicInfos = p;
    });
    //CARGO LA INFORMACION CIENTIFICA  DE LA PERSONA
    this.scientificService.getScientificInfosByUserID(parseInt(this.user)).subscribe(p => {
      this.ScientificInfos = p;
      swal({
        type: 'success',
        title: "Datos Cargados",
        showConfirmButton: false,
        timer: 10,
        allowEscapeKey: false,
        allowOutsideClick: false,
      })

      this.DatosDisponibles = true; //INDICO QUE LOS DATOS ESTAN DISPONIBLES, POR LO TANTO CARGA LA PLANTILLA HTML
    });

    this.scientificService.getScientificInfosByUserID(parseInt(this.user)).subscribe(p => {
      this.ScientificInfos = p;
      swal({
        type: 'success',
        title: "Datos Cargados",
        showConfirmButton: false,
        timer: 10,
        allowEscapeKey: false,
        allowOutsideClick: false,
      })

      this.DatosDisponibles = true; //INDICO QUE LOS DATOS ESTAN DISPONIBLES, POR LO TANTO CARGA LA PLANTILLA HTML
    });
  }


  setSpecialChildInfo(){
    const contacatInfoId = this.User_info.ContactInfos[0].id;
    const data = { medicalCondition: this.contactInfo.medicalCondition}
    
    if( !this.contactInfo.medicalCondition )
      return;

    this.servicios.putContactInfo(parseInt(this.user),contacatInfoId, data).subscribe({
      next:(resp)=>{
        this.authService.swalSuccess('Hecho', 'Información Especial se ha actualizado');
      },error:(err)=>{
        console.log(err);
        this.authService.close();
      }
    })
  }

  uploadFileIcfes(fileInput: any) {

    this.classStyleIcfes = "btn btn-warning  btn-file"
    this.buttonIcfes = "Cargando ..."
    this.buttonIcfes2 = "Cargando ..."
    this.fileToUpload = <File>fileInput.target.files[0];
    if (this.fileToUpload.size > 5000000) {//5M
      this.imagebuton = 'Error de Archivo'
      this.isImageError = true
      this.enablesavefile = false

      this.classStyleIcfes = "btn btn-danger  btn-file"
      this.buttonIcfes = "No debe superar 1MB"
      this.buttonIcfes2 = "No debe superar 1MB"

    }
    else {
      this.attachmentService.NewIcfesInfoSuports(this.fileToUpload).then((result) => {

        this.IcfesID = "/attachments/icefesinfo/download/" + result['name'];
        this.imagebuton = 'Archivo OK'
        this.isImageError = false
        this.enablesavefile = true

        let data = {}
        if (this.UserInfo.ProfesionalProfileID == 0) {
          data = {
            IcfesID: this.IcfesID,
            UserID: parseInt(this.user),

          }

          this.servicios.postProfesionalProfileByUserID(parseInt(this.user), data).subscribe(data => {

            this.UserInfo.IcfesID = data["IcfesID"];
            this.UserInfo.ProfesionalProfileID = data.id
            this.classStyleIcfes = "btn btn-success  btn-file"
            this.buttonIcfes = "Archivo Guardado"
            this.buttonIcfes2 = "Archivo Guardado"

          }, err => {

            this.authService.swalError("Error al cargar ICFES", "No se pudo cargar ICFES, detalles: " + err)

            this.imagebuton = 'Cargar Archivo'
            this.isImageError = true
            this.enablesavefile = false
            this.classStyleIcfes = "btn btn-danger  btn-file"
            this.buttonIcfes = "Intente Nuevamente"
            this.buttonIcfes2 = "Intente Nuevamente"



          })


        }
        else {
          data = {
            IcfesID: this.IcfesID,
            UserID: parseInt(this.user),
            id: this.UserInfo.ProfesionalProfileID
          }
          this.servicios.pachProfesionalProfileByID(this.UserInfo.ProfesionalProfileID, data).subscribe(data => {
            this.UserInfo.IcfesID = data["IcfesID"];
            this.UserInfo.ProfesionalProfileID = data.id
            this.classStyleIcfes = "btn btn-success  btn-file"
            this.buttonIcfes = "Archivo Guardado"
            this.buttonIcfes2 = "Archivo Guardado"

          }, err => {

            this.authService.swalError("Error al cargar ICFES", "No se pudo cargar ICFES, detalles: " + err)

            this.imagebuton = 'Cargar Archivo'
            this.isImageError = true
            this.enablesavefile = false
            this.classStyleIcfes = "btn btn-danger  btn-file"
            this.buttonIcfes = "Intente Nuevamente"
            this.buttonIcfes2 = "Intente Nuevamente"


          })

        }



      }, (error) => {
        console.error(error);
        this.authService.swalError(
          "Error en Archivo",
          ""
        );
      })
    }
  }

  uploadFileEcaes(fileInput: any, model: ProfesionalPerfil) {

    this.fileToUpload = <File>fileInput.target.files[0];

    this.classStyleSaberPro = "btn btn-warning  btn-file"
    this.buttonEcaes2 = "Cargando ..."
    this.buttonEcaes = "Cargando ..."


    if (this.fileToUpload.size > 5000000) {//5M
      this.imagebuton = 'Error de Archivo'
      this.isImageError = true
      this.enablesavefile = false
      this.classStyleSaberPro = "btn btn-warning  btn-file"
      this.buttonEcaes2 = "No debe superar 1MB"
      this.buttonEcaes = "No debe superar 1MB"

    }
    else {
      this.attachmentService.NewEcaesInfoSuports(this.fileToUpload).then((result) => {

        this.SaberProID = "/attachments/ecaesinfo/download/" + result['name'];
        this.imagebuton = 'Archivo OK'
        this.isImageError = false
        this.enablesavefile = true


        let data = {}
        if (this.UserInfo.ProfesionalProfileID == 0) {
          data = {
            SaberProID: this.SaberProID,
            UserID: parseInt(this.user),

          }

          this.servicios.postProfesionalProfileByUserID(parseInt(this.user), data).subscribe(data => {

            this.UserInfo.SaberProID = data["SaberProID"];
            this.UserInfo.ProfesionalProfileID = data.id
            this.classStyleSaberPro = "btn btn-success  btn-file"
            this.buttonEcaes2 = "Archivo Guardado"
            this.buttonEcaes = "Archivo Guardado"

          }, err => {

            this.authService.swalError("Error al cargar ICFES", "No se pudo cargar ICFES, detalles: " + err)

            this.imagebuton = 'Cargar Archivo'
            this.isImageError = true
            this.enablesavefile = false
            this.classStyleSaberPro = "btn btn-danger  btn-file"
            this.buttonEcaes2 = "Intentar Nuevamente"
            this.buttonEcaes = "Intentar Nuevamente"



          })


        }
        else {
          data = {
            SaberProID: this.SaberProID,
            UserID: parseInt(this.user),
            id: this.UserInfo.ProfesionalProfileID
          }
          this.servicios.pachProfesionalProfileByID(this.UserInfo.ProfesionalProfileID, data).subscribe(data => {
            this.UserInfo.SaberProID = data["SaberProID"];
            this.UserInfo.ProfesionalProfileID = data.id
            this.classStyleSaberPro = "btn btn-success  btn-file"
            this.buttonEcaes2 = "Archivo Guardado"
            this.buttonEcaes = "Archivo Guardado"
          }, err => {

            this.authService.swalError("Error al cargar ICFES", "No se pudo cargar ICFES, detalles: " + err)


            this.imagebuton = 'Cargar Archivo'
            this.isImageError = true
            this.enablesavefile = false
            this.classStyleSaberPro = "btn btn-danger  btn-file"
            this.buttonEcaes2 = "Intentar Nuevamente"
            this.buttonEcaes = "Intentar Nuevamente"


          })

        }


      }, (error) => {
        console.error(error);
        this.authService.swalError(
          "Error en Archivo",
          ""
        );
      })
    }
  }


  SavePerfil(model, models: UserDocument) {
 
    this.profileP[0].UserID = parseInt(this.user);
    this.profileP[0].Description = model.Description
    this.profileP[0].Skills = model.Skills
    this.profileP[0].Language = model.Language

 
    this.submitbuton = "Validando ... ";
    this.authService.swalLoading("Guardando Datos")

    let temp2 = {
      Description: model.Description,
      Skills: model.Skills,
      Language: model.Language,
    }

    if (this.UserInfo.ProfesionalProfileID != 0) {
      temp2["id"] = this.UserInfo.ProfesionalProfileID
      this.servicios.pachProfesionalProfileByID(this.UserInfo.ProfesionalProfileID, temp2).subscribe(editProfesional => {
        this.authService.swalEndLoad()
        this.submitbuton = 'Guardar'
        setTimeout(function () {
          jQuery("#ModalInfo").modal('hide');
        }, 500);
    
      }, e => {
        this.authService.swalError("Error al guardar los datos", "No se pudo guardar los datos, detalles: " + e)
      })
    } else {
      temp2["UserID"] = parseInt(this.user)
      this.servicios.postProfesionalProfileByUserID(parseInt(this.user), temp2).subscribe(editProfesional => {
        this.UserInfo.ProfesionalProfileID = editProfesional.id
        this.UserInfo.Description = editProfesional.Description
        this.UserInfo.Skills = editProfesional.Skills
        this.UserInfo.Language = editProfesional.Language
        this.authService.swalEndLoad()
        this.submitbuton = 'Guardar'
        setTimeout(function () {
          jQuery("#ModalInfo").modal('hide');
        }, 500);
    


      }, e => {
        this.authService.swalError("Error al guardar los datos", "No se pudo guardar la descripción del perfil, detalles: " + e)
      })

    }

  }

  WorkEditInfo(id: number) {
    console.debug("se va a editar la información " + id)
    this.EditWorkID = id;
    this.workServices.getWorkByID(id).subscribe(p => {
      this.EditWork = p;
      console.debug("la informacion que se va a editar es:", this.EditWork);
      this.DatosDisponiblesEditar = true;
      this.EditWork["stateUploadFile"] = "Cargar Archivo"
      this.EditWork["stateButton"] = false
    });
  }
  DeleteItem(UserID, WorkID) {
    console.debug("el id del usuario es " + UserID + " y el numero del id del trabajo es " + WorkID);
    swal({
      title: "¿Está seguro?",
      text: "El ítem será borrado permanentemente!",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Si, Borrar ítem!",
    }).then(result => {
      console.log(result)
      if (result.value) {
        this.workServices.DeleteWorkByID(UserID, WorkID).subscribe(p => {
          this.isok = true
          swal("Borrado!", "El Ítem Ha sido Borrado Correctamente.", "success");
          this.ngOnInit();
        });
      }
    });
  }
  AcademicEditInfo(id: number) {
    console.debug("se va a editar la información " + id)
    this.EditAcademicID = id;
    this.academicServices.getAcademicByID(id).subscribe(p => {
      this.EditAcademic = p;
      this.EditAcademic["stateUploadFile"] = "Cargar Archivo"
      this.EditAcademic["stateButton"] = false
      console.debug("la informacion que se va a editar es:" + JSON.stringify(this.EditAcademic));
      this.DatosDisponiblesEditarAcademic = true;
    });
  }
  DeleteItemAcademic(UserID, AcademicID) {
    console.debug("el id del usuario es " + UserID + " y el numero del id  del academico es " + AcademicID);
    swal({
      title: "¿Está seguro?",
      text: "El ítem será borrado permanentemente!",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Si, Borrar ítem!",
    }).then(result => {
      console.log(result)
      if (result.value) {
        this.academicServices.DeleteAcademicByID(UserID, AcademicID).subscribe(p => {
          this.isok = true
          swal("Borrado!", "El Ítem Ha sido Borrado Correctamente.", "success");
          this.ngOnInit();
        });
      }
    });
  }
  ScientificEditInfo(id: number) {
    console.debug("se va a editar la información " + id)
    this.EditAcademicID = id;
    this.scientificService.getScientificByID(id).subscribe(p => {
      this.EditScientific = p;
      this.EditScientific["stateUploadFile"] = "Cargar Soporte"
      this.EditScientific["stateButton"] = false
      console.debug("la informacion que se va a editar es:" + JSON.stringify(this.EditScientific));
      this.DatosDisponiblesEditarScientific = true;
    });
  }
  DeleteItemScientific(UserID, ScientificID) {
    console.debug("el id del usuario es " + UserID + " y el numero del id  del academico es " + ScientificID);
    swal({
      title: "¿Está seguro?",
      text: "El ítem será borrado permanentemente!",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Si, Borrar ítem!",
    }).then(result => {
      console.log(result)
      if (result.value) {
        this.scientificService.DeleteScientificByID(UserID, ScientificID).subscribe(p => {
          this.isok = true
          swal("Borrado!", "El Ítem Ha sido Borrado Correctamente.", "success");
          this.ngOnInit();
        });
      }
    });
  }
  OnlyWork() {
    this.ShowWork = true;
    this.ShowAcademic = false;
    this.ShowScientific = false;
  }
  OnlyAcademic() {
    this.ShowWork = false;
    this.ShowAcademic = true;
    this.ShowScientific = false;
  }
  OnlyScientific() {
    this.ShowWork = false;
    this.ShowAcademic = false;
    this.ShowScientific = true;
  }
  ShowAll() {
    this.ShowWork = true;
    this.ShowAcademic = true;
    this.ShowScientific = true;
  }


  ValidateDate(model) {

    let since = ""
    let until = ""
    let compareuntil: boolean = false
    let current_past
    let actual: boolean = false

    if (model.Since) {

      model.Since = model.Since //PARA WORKINFO
      since = "ingreso"
      until = "retiro"

        actual = !model.IsCurrent;


    }



    if (model.FinishDate) {
      model.Since = model.FinishDate //PARA ACADEIMCINFO
      since = "finalización"
      actual = false
    }

    if (model.PublicationDate) {
      model.Since = model.PublicationDate //PARA SCIENTIFICINFO
      since = "publicación"
      actual = false
    }



    let ActualDate = new Date(this.serverTime)
    let InputDateSince = new Date(model.Since)//EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE
    let InputDateUntil = new Date(model.Until) //EL TEXTO INGRESADO SE CONVIERTE EN TIPO DATE
    let validateDateSince = moment(InputDateSince, "YYYY-MM", true).isValid() //VALIDO SI ES FECHA
    let validateDateUntil = moment(InputDateUntil, "YYYY-MM", true).isValid() //VALIDO SI ES FECHA

    //console.log(InputDateUntil.toISOString())
    //cuando indica que trabaja actualmente
    if (actual) {


      if (validateDateSince === false && validateDateUntil === false) {
        this.DateMessage = "Formatos de fechas incorrecto"
        // return false
      }

      if (validateDateSince === false) {
        this.DateMessage = "Formato de fecha " + since + " Incorrecto"
        // return false
      }
      if (validateDateUntil === false) {
        this.DateMessage = "Formato de fecha " + until + " Incorrecto"
        // return false
      }
      else if (InputDateSince > ActualDate) {
        this.DateMessage = "La fecha de  " + since + " no deben superar la fecha actual:: " + ActualDate.getFullYear().toString() + "-" + (ActualDate.getMonth() + 1)
      }
      else if (InputDateUntil > ActualDate) {
        this.DateMessage = "La fecha de  " + until + " no deben superar la fecha actual:: " + ActualDate.getFullYear().toString() + "-" + (ActualDate.getMonth() + 1)
      }
      else if (InputDateSince > InputDateUntil) {
        this.DateMessage = "La fecha de  " + since + " es mayor a la de  " + until + " "
        // return false
      }
      else {
        this.DateMessage = ""
      }

    }
    //CUANDO SE HA SELECCIONADO QUE TRABAJA ACTUALMENTE
    else {

      if (validateDateSince === false) {
        this.DateMessage = "Formato de fecha " + since + " Incorrecto"
        // return false
      }
      else if (InputDateSince > ActualDate) {
        this.DateMessage = "La fecha de " + since + " no deben superar la fecha actual: " + ActualDate.getFullYear().toString() + "-" + (ActualDate.getMonth() + 1)
      }
      else {
        this.DateMessage = ""
      }





    }




  }


}
