import { Component, Input, OnInit } from '@angular/core';
import { CourseGroup } from 'app/models/Coursesinfo/course-group';
import { CourseOfer } from 'app/models/Coursesinfo/course-ofert';
import { CoursesService } from 'app/models/Coursesinfo/courses.service';
import { Subject } from 'app/models/Coursesinfo/subject';
import { UserSubject } from 'app/models/Coursesinfo/user-subject';
import { SubjectGroup } from 'app/models/usersInfo/subjectgroup';
import { UserCourse } from 'app/models/usersInfo/usercourse';
import { environment } from 'environments/environment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'cedoc360-student-bulletin',
  templateUrl: './student-bulletin.component.html',
  styleUrls: ['./student-bulletin.component.scss']
})
export class StudentBulletinComponent implements OnInit {

  public courseGroup: CourseGroup;
  public typeView: string = "gradesByUser"
  public subjectGroupsSelected: Array<SubjectGroup>

  public subjectsByCourseAndSemester: Array<Subject>
  public midTermNumSelected = 1
  public userCourseSelected: UserCourse
  public courseOffer: CourseOfer
  public academicAreas: Array<any>
  selectedYear: number;
  public qualitativeDescriptions: any[]=[];
  public BACK_V2= environment.URL_BACK_V2


  constructor(private coursesServices: CoursesService,) {

  }

  ngOnInit() {

  }

  private filterSubjectsByGrade(text: any): number {

    const courseRegex = /(prejardín|jardín|transición)/i;
    const gradeRegex = /grado\s*(\d+)/i;
    const courseResult = courseRegex.exec(text);
    let courseIndex = 0;
    if (courseResult && courseResult.length > 1) {
      const courseName = courseResult[1].toLowerCase();
      console.log(courseName);
      switch (courseName) {
        case 'prejardín':
          courseIndex = 1;
          break;
        case 'jardín':
          courseIndex = 2;
          break;
        case 'transición':
          courseIndex = 3;
          break;
        default:
          break;
      }
      return courseIndex;
    }
    const gradeResult = gradeRegex.exec(text);
    if (gradeResult && gradeResult.length > 1)
      courseIndex = parseInt(gradeResult[1]);
    return courseIndex;

  }

  setYear(year:number){
    this.selectedYear = year

  }

  setCourseOffer(courseOfferSelected: CourseOfer) {
    console.log("llego del emmit de oferta", courseOfferSelected)


    let filterCourseOffers = {
      include: [
        {
          course: { relation: "Subjects", scope: { where: { "SemesterNumber": this.filterSubjectsByGrade(courseOfferSelected.NameCourseOfer) } } }
        }
      ],
      where: { id: courseOfferSelected.id }
    }

    let filterAcademicAreas = {
      where: { SchoolID: courseOfferSelected.SchoolID }
    }

    let filterQualitativeDescription = {
      where: {and: [
        { courseID: courseOfferSelected.CourseID },
        { year: this.selectedYear   }
      ]  } 
    }
    
    forkJoin({
      courseOffer: this.coursesServices.getCoursesOfers(JSON.stringify(filterCourseOffers)),
      academiAreas: this.coursesServices.getAllAreaAcademic(JSON.stringify(filterAcademicAreas)),
      qualitativeDescriptions: this.coursesServices.getQualitativeDescriptions(JSON.stringify(filterQualitativeDescription))
    }).subscribe({
      next: ({ courseOffer, academiAreas , qualitativeDescriptions}: any) => {


  
        if (courseOffer.length == 1)
          this.subjectsByCourseAndSemester = courseOffer[0].course.Subjects
        else
          this.subjectsByCourseAndSemester = []
        this.academicAreas = academiAreas
        this.qualitativeDescriptions = qualitativeDescriptions

        console.log("this.subjectsByCourseAndSemester", this.subjectsByCourseAndSemester)
        console.log("academicAreas", this.academicAreas)


      },
      error: (err) => {
        console.log(err);

      },
    });

  }


  setCourseGroup(courseGroup: CourseGroup) {
    let filter = {
      include: ["minTerms"],
      where: { "CourseGroupID": courseGroup.id }
    }
    this.coursesServices.getSubjectGroupsNew(JSON.stringify(filter)).subscribe(res => {
      console.log("subjectGroupsByCourseGroup ", res)
      this.subjectGroupsSelected = res
    })
  }


  setUserCourse(userCourse) {
    console.log("llego del filtro", userCourse)
    this.userCourseSelected = { ...userCourse }

  }

  setTypeView(typeView: string) {
    this.typeView = typeView;

  }

}
