import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';

import { ProfileNavComponent } from './components/profile-nav/profile-nav.component';
import { StructureModule } from './structure/structure.module';

import { UsersService } from './models/usersInfo/users.service';
import { OapinfoService } from './models/oapinfo/oapinfo.service';
import { CoursesService } from './models/Coursesinfo/courses.service';
import { AuthService } from "./models/Auth/auth.service";
import { AttachmentService } from "./models/attachment/attachment.service";
import { EvaluationsService } from './models/evaluation/evaluations.service';
import { RecordService } from './models/midterm/record.service';
import { PolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { PagesRegister } from './public/signup/signup.page';
import { MailService } from './models/mail/mail.service';


import { AccordingView } from './components/according-view/according-view.component';
import { Pages404 } from './public/404page/page-404';

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderModule } from 'ngx-order-pipe';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';

import { UrlSaniter } from './models/pipes/saniter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WellnessService } from './models/wellness/wellness.service';
import { StudentBulletinComponent } from './structure/modUser/modprofesor/student-bulletin/student-bulletin.component';
import { FilterBulletinComponent } from './structure/modUser/modprofesor/student-bulletin/filter-bulletin/filter-bulletin.component';
import { ViewBulletinByUser } from './structure/modUser/modprofesor/student-bulletin/view-bulletin-by-user/view-bulletin-by-user.component';




@NgModule({
  declarations: [
    EmptyRouteComponent,
    AppComponent,
    ProfileNavComponent,
    PolicyComponent,
    PagesRegister,
    Pages404,
    AccordingView,
    UrlSaniter,
    StudentBulletinComponent,
    FilterBulletinComponent,
    ViewBulletinByUser,
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    StructureModule,
    OrderModule,
    ChartsModule,
  ],

  entryComponents: [
    AccordingView],
  providers: [
    UsersService,
    WellnessService,
    OapinfoService,
    CoursesService,
    AuthService,
    AttachmentService,
    EvaluationsService,
    RecordService,
    MailService,
    UrlSaniter,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {}
