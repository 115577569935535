import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Course } from "../../../../models/Coursesinfo/course";
import { ActivatedRoute, Router } from "@angular/router";

import { ViewCell } from 'ng2-smart-table';
import { Module } from '../../../../models/Coursesinfo/module';
import { School } from '../../../../models/Coursesinfo/school';
import { UsersField } from '../../../../models/usersInfo/users-fields';
import { CoursesService } from '../../../../models/Coursesinfo/courses.service';
import { UsersService } from '../../../../models/usersInfo/users.service';
import { AuthService } from '../../../../models/Auth/auth.service';


export class Intermediate {
  exist: boolean = false
  text = "Inscribirse"
}




@Component({
  selector: 'button-view',
  template: `
      <button [disabled]="intermediate.exist" (click)="onClick()" class="btn btn-block btn-info btn-block ">{{intermediate.text}}</button>
    `,
})
export class ButtonViewComponent implements ViewCell, OnInit {

  constructor(
    public intermediate: Intermediate,
  ) { }

  renderValue: string;



  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();


  }

  onClick() {
    this.save.emit(this.rowData);

  }




}

@Component({
  selector: 'new-select-academic',
  templateUrl: './new-select-academic.component.html',
  providers: [ButtonViewComponent, Intermediate],
  styleUrls: ['./new-select-academic.component.css']
})
export class NewSelectAcademicComponent implements OnInit {

  modules: Module[];
  schools: School[] = [];
  courses: Course[];

  subjects: Course[];
  user: UsersField;
  userid: string;
  userJobs: any[] = []
  areaID: number = 0;
  courseID: number = 0;
  schoolID: number = 4;
  id_temp: any



  constructor(private coursesService: CoursesService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private usersServices: UsersService,
    public intermediate: Intermediate,
    private buttonViewComponent: ButtonViewComponent,
    private authService: AuthService) {



    this.coursesService.getAllSchools().subscribe(p => this.schools = p);

    this.coursesService.getAllAcademicAreasBySchool(4).subscribe(p => this.modules = p);
    this.coursesService.getAllCoursesMayaBySchool(4).subscribe(p => this.courses = p);

  }

  ngOnInit() {





    if (this.authService.getCurrentUser() == null) {
      this.router.navigate(['/register']);
    } else {
      this.userid = this.authService.getCurrentUser();

      this.coursesService.getUserJobsByUserID(parseInt(this.userid)).subscribe(p => this.userJobs = p)
    }
  }

  selectSchool(model: UsersField) {

    this.schoolID = model.SchoolID;
    this.coursesService.getAllAcademicAreasBySchool(model.SchoolID).subscribe(p => this.modules = p);
    this.coursesService.getAllCoursesMayaBySchool(model.SchoolID).subscribe(p => this.courses = p);

  }

  teachSchool(schoolID: number,JobOferID:number) {

    var data = {
      "isAcepted": true,
      "SchoolID": schoolID,
      "isSuper": false,
      "JobOferID":JobOferID,
    }
    console.log(schoolID)
    console.log("antes de enviarlo",data)
    this.authService.swalLoading("Inscribiendo a la escuela")
    this.coursesService.newUserJobsByUserID(parseInt(this.userid), data).subscribe(p2 => {

      this.coursesService.getUserJobsByUserID(parseInt(this.userid)).subscribe(p => {
        this.userJobs = p

        this.authService.swalEndLoad()
      })

    }, err => {
      this.authService.swalError("Error Guardando Inscripcion", "No se pudo guardar la inscripción, detalles: " + err)
    })



  }

  isUserJobInSchoolID(schoolID: number) {

    var found = this.userJobs.find(x => x.SchoolID === schoolID)
    if (found) {
      return true
    }
    else {
      return false
    }

  }

  colorSchool(idSchool: number) {
    let classes = {};

    switch (idSchool) {
      case 0: classes = { cat__info__item_CEDOC: true }; break;
      case 1: classes = { cat__info__item_CEMIL: true }; break;
      case 4: classes = { cat__info__item_EAS: true }; break;
      case 5: classes = { cat__info__item_ESINF: true }; break;
      case 6: classes = { cat__info__item_ESCAB: true }; break;
      case 7: classes = { cat__info__item_ESART: true }; break;
      case 8: classes = { cat__info__item_ESING: true }; break;
      case 9: classes = { cat__info__item_ESCOM: true }; break;
      case 10: classes = { cat__info__item_ESICI: true }; break;
      case 16: classes = { cat__info__item_ESAVE: true }; break;
      case 17: classes = { cat__info__item_ESLOG: true }; break;
      case 18: classes = { cat__info__item_ESCEQ: true }; break;
      case 19: classes = { cat__info__item_ESPOM: true }; break;
      case 20: classes = { cat__info__item_ESMAI: true }; break;
      case 21: classes = { cat__info__item_ESDIH: true }; break;
      case 22: classes = { cat__info__item_CEMAI: true }; break;
      case 23: classes = { cat__info__item_ESIDE: true }; break;
      case 24: classes = { cat__info__item_ESFES: true }; break;
      case 25: classes = { cat__info__item_CEDOC: true }; break;
      case 33: classes = { cat__info__item_ESMIC: true }; break;
      case 34: classes = { cat__info__item_EMSUB: true }; break;
      case 35: classes = { cat__info__item_ESPRO: true }; break;
      default: classes = { cat__info__item_ALL: true }; break;


    }
    return classes;
  }



}