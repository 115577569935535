import { Injectable } from '@angular/core';
import { AuthService} from "../Auth/auth.service";
import { DateRecord } from '../daterecord/daterecord';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AttachmentService {

    constructor(private http : HttpClient, private authService: AuthService){
  }
  private baseUrl: string = this.authService.UrlAPI;
  

  private getHeaders(){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

   private putHeaders(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }

  DeleteFileByID(container: string,filename: string): Observable<any>{
    let a = this
     .http
     .delete(`${this.baseUrl}/attachments/${container}/files/${filename}`,  { headers: this.putHeaders()})
     .pipe(catchError(handleError))
    return a;
    
  }


  //Stolen from: https://developer.mozilla.org/en-US/docs/Using_files_from_web_applications
  storageFile(container: string, file: File) {
    return new Promise((resolve, reject) => {

      var xhr = new XMLHttpRequest();
      var fd = new FormData();
     
      xhr.open("POST", `${this.baseUrl}/attachments/${container}/upload`, true);

      xhr.onreadystatechange = function() {
          if(xhr.readyState == 4 && xhr.status == 200) {
              resolve(JSON.parse(xhr.responseText));
          }
      };
      fd.append('file', file);
      xhr.setRequestHeader('Authorization', this.authService.getToken())
      xhr.send(fd);

    });
}


sendFile(CourseOferID: Number, SchoolID:number, file: File) {
  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();
   
    xhr.open("POST", `${this.baseUrl}/ValidationOAPs/${SchoolID}/${CourseOferID}/upload`, true);
    xhr.onreadystatechange = function() {
        if(xhr.readyState == 4 && xhr.status == 200) {
            resolve(JSON.parse(xhr.responseText));
        }
    };
    fd.append('file', file);
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });
}

NewDatesSuports(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/new_dates_suport/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}

NewWorkInfoSuports(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/workinfo/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}

NewAcademicInfoSuports(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/academicinfo/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}



NewCourseRecipeSuports(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/courcerecipe/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}

NewScientificInfoSuports(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/scientificinfo/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}

NewDocumentsInfoSuports(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/documentsinfo/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}

NewImageOva(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/ova_cedoc/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}

NewIcfesInfoSuports(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/icefesinfo/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}

NewEcaesInfoSuports(file: File) {

  return new Promise((resolve, reject) => {

    var xhr = new XMLHttpRequest();
    var fd = new FormData();

    xhr.open("POST", `${this.baseUrl}/attachments/ecaesinfo/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        var res = JSON.parse(xhr.responseText)
        resolve(res.result.files.file[0]);
      }
    };

    fd.append('file', file);
    //fd.append('data', JSON.stringify(fileAdj));
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });

}
}



function handleError (error: any) {

  let errorMsg = error.message || `error attachment!`
  console.error(errorMsg);

  return Observable.throw(errorMsg);
}
 
