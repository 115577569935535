import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from "@angular/core"
import {CommonModule, DatePipe, registerLocaleData} from "@angular/common"

import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {HttpModule} from "@angular/http"
import {CustomFormsModule} from "ng2-validation"
import {TextMaskModule} from "angular2-text-mask"
import {ProfileComponent} from "./profile/profile.component"
import {ColombiaPipeMododule} from "../components/profile-nav/profile-nav.component"
import {CvPublicComponent} from "./cv-public/cv-public.component"
import {TutorialComponent} from "./tutorial/tutorial.component"
import {EvaluationBoxComponent} from "./cv-public/evaluation-box/evaluation-box.component"

import {OrderModule} from "ngx-order-pipe"
// Charts api => see imports: ChartModule
import {ChartsModule} from "ng2-charts"
import {NgbModule} from "@ng-bootstrap/ng-bootstrap"
import {CarouselModule} from "ngx-owl-carousel-o"
import {TutorialalumniComponent} from "./tutorialalumni/tutorialalumni.component"
import {RouterModule} from "@angular/router"
import {EditSubjectFormComponent} from "./profile/edit-subject-form/edit-subject-form.component"
import {EditDatesFormComponent} from "./profile/edit-dates-form/edit-dates-form.component"
import {StudentCalendarComponent} from "./profile/student-calendar/student-calendar.component"
import {CalendarModule, DateAdapter} from "angular-calendar"
import {adapterFactory} from "angular-calendar/date-adapters/date-fns"
import locales from "@angular/common/locales/es"
import {ProfessorCalendarComponent} from "./profile/professor-calendar/professor-calendar.component"
import {TruncateTextPipe} from "app/pipes/truncateText.pipe"
import {CelicTutorialsComponent} from "./tutorial/celic-tutorials/celic-tutorials.component"
import {CedocTutorialsComponent} from "./tutorial/cedoc-tutorials/cedoc-tutorials.component"
import {TutorialContainerComponent} from "app/components/tutorial-container/tutorial-container.component"

registerLocaleData(locales)

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    CustomFormsModule,
    HttpModule,
    TextMaskModule,
    ChartsModule,
    ColombiaPipeMododule,
    OrderModule,
    CarouselModule,
    ReactiveFormsModule,
    RouterModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [
    TutorialComponent,
    CelicTutorialsComponent,
    CedocTutorialsComponent,
    TutorialContainerComponent,
    ProfileComponent,
    CvPublicComponent,
    TutorialalumniComponent,
    EvaluationBoxComponent,
    EditSubjectFormComponent,
    EditDatesFormComponent,
    StudentCalendarComponent,
    ProfessorCalendarComponent,
    TruncateTextPipe,
  ],
  providers: [DatePipe],

  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class StructureModule {}
