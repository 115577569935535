import { Injectable } from '@angular/core';

import {OapLoad} from './oapload';

import { AuthService} from "../Auth/auth.service";

import { CreateMail } from './createmail';
import { AntiquityGpa } from './antiquity-gpa';
import { IsAcepted } from '../Coursesinfo/is-acepted';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


@Injectable()
export class OapinfoService {


  constructor(private http : HttpClient,private authService: AuthService){
  }

  private baseUrl: string = this.authService.UrlAPI;



  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }
  
  putHeaders(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }
  

  validateOAP(id:number) :Observable<any> {
    let total = this.http
     .get(`${this.baseUrl}/ValidationOAPs/validate?CourseOferID=${id}`, { headers: this.getHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
    ) 
     return total;
  }

  deleteOAP(id:number) :Observable<any> {
    let total = this.http
     .delete(`${this.baseUrl}/CoursesOfers/${id}/validationoaps`, { headers: this.putHeaders()});

     return total;
  }


  getbyCourseOferID(courseOferId:number,SchoolID:number) :Observable<any> {
    let oaps$ = this.http
     .get(`${this.baseUrl}/ValidationOAPs?filter={"where": {"and": [{"SchoolID":${SchoolID}},{"CourseOferID":${courseOferId}}]}}`, { headers: this.getHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
    ) 
     return oaps$;
 }


 sendGoogle( file: File) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", `${this.baseUrl}/googleEmails/upload`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        resolve(JSON.parse(xhr.responseText));
      }
      if (xhr.status == 422) {
        reject(JSON.parse(xhr.responseText));
      }
      if (xhr.status == 404) {
        reject(JSON.parse(xhr.responseText));
      }
    };
    fd.append('file', file);
    xhr.setRequestHeader('Authorization', this.authService.getToken())
    xhr.send(fd);

  });
}

 getAllOap(idcourseofer: number) :Observable<any> {
   let filter = '?filter={"include": ["school"], "where": {"and": [{},';
   if(idcourseofer!=0){
   filter = filter + `{"CourseOferID": ${idcourseofer}},`;
   }     
   let oaps$ = this.http
           .get(`${this.baseUrl}/OAPs/${filter}{}]}}`, { headers: this.getHeaders() })
           .pipe(
            catchError(err => this.authService.errorMessages(err))
          ) 
           return oaps$;
}

getAllGPAStudent(idcourseofer:number,idschool:number) :Observable<any> {
  let filter = '?filter={"where": {"and": [{},';
  if(idcourseofer!=0){
    filter = filter + `{"CourseOferID": ${idcourseofer}},`;
  }   
  if(idschool!=0){
    filter = filter + `{"SchoolID": ${idcourseofer}},`;
  }   
  let courseofers$ = this.http
          .get(`${this.baseUrl}/GPAStudents/${filter}{}]}, "order":"GPA desc"}`, { headers: this.getHeaders() })
          .pipe(
            catchError(err => this.authService.errorMessages(err))
          ) 
          return courseofers$;
}

getFileToMail(idcourse:number) :Observable<any> {
  let oaps$ = this.http
   .get(`${this.baseUrl}/OAPs?filter={"where": {"and": [{"CourseOferID":${idcourse}},{"IsCreated":0}]}}`, { headers: this.getHeaders()})
   .pipe(
    catchError(err => this.authService.errorMessages(err))
  ) 
   return oaps$;

}

  save(courseOferId:number, SchoolID:number, oap: OapLoad): Observable<any>{
    
    oap.SchoolID = SchoolID;
    oap.CourseOferID=courseOferId;
     return this
      .http
      .post(`${this.baseUrl}/ValidationOAPs`, 
            JSON.stringify(oap), 
            {headers: this.putHeaders()});
  }

  changeSchool(userId:number, SchoolID:number): Observable<any>{
    var dato = {"SchoolID": SchoolID}
     return this
      .http
      .post(`${this.baseUrl}/roleMappings/update?where={"principalId":${userId}}`, 
            JSON.stringify(dato), 
            {headers: this.putHeaders()});
  }

  update(courseOferId:number, oap: OapLoad): Observable<any>{
    return this
     .http
     .post(`${this.baseUrl}/CoursesOfers/${courseOferId}/ValidationOAPs`, 
           JSON.stringify(oap), 
           {headers: this.putHeaders()});
 }

 updateUserCourseByID(usercourseId:number, isacepted:IsAcepted):Observable<any>{
  return this
  .http
  .patch(`${this.baseUrl}/UserCourses/${usercourseId}`, 
        JSON.stringify(isacepted), 
        {headers: this.putHeaders()});
 }

 updateUserJobByID(isacepted:IsAcepted):Observable<any>{


   console.log(isacepted)
  return this
  .http
  .patch(`${this.baseUrl}/UserJobs`, 
        JSON.stringify(isacepted), 
        {headers: this.putHeaders()});
 }

  mapPersons(response): CreateMail[]{
  //throw new Error('ups! Force choke!');
  // The response of the API has a results
  // property with the actual results
  return response.map(toPerson)
}
 mapPerson(response): CreateMail{
  // toPerson looks just like in the previous example
  return toPerson(response);
}

}





function toPerson(r:any): CreateMail{
  let course = <CreateMail>({

    Names:r.Names,
    LastNames:r.LastNames,
    CedocEmail:r.CedocEmail,
    CellPhone:r.CellPhone,
    Email:r.Email

  });
  return course;
}




