import { Injectable } from '@angular/core';

import {Academic} from './academic';
import { AuthService} from "../Auth/auth.service";
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


@Injectable()
export class AcademicService {

 
    constructor(private http : HttpClient, private authService: AuthService){
  }

  private baseUrl: string = this.authService.UrlAPI;

  


  getAllTypeCourses() :Observable<any> {
     let TypeCourses$ = this.http
      .get(`${this.baseUrl}/TypeCourses`, { headers: this.getHeaders()})
      .pipe(
        catchError(err => this.authService.errorMessages(err))
    )  
      return TypeCourses$;
  }


  getAcademicInfosByUserID(id:number) :Observable<any> {
     let TypeCourses$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/AcademicInfos/?filter={"include": ["TypeCourse"], "order": "id DESC"}`, { headers: this.getHeaders()})
      .pipe(
        catchError(err => this.authService.errorMessages(err))
      )  
      return TypeCourses$;
  }


  saveAcademic(academic: Academic): Observable<any>{
    return this
     .http
     .post(`${this.baseUrl}/Userapps/${academic.UserID}/AcademicInfos`, JSON.stringify(academic) , { headers: this.putHeaders()})
     .pipe(
      catchError(err => this.authService.errorMessages(err))
  )  
    
 }

 getAcademicByID(id:number) :Observable<any> {
  let TypeCourses$ = this.http
   .get(`${this.baseUrl}/AcademicInfos/${id}`, { headers: this.putHeaders()})
   .pipe(
    catchError(err => this.authService.errorMessages(err))
)  
   return TypeCourses$;
}

UpdateAcademicByID(academic: Academic): Observable<any>{
  return this
   .http
   .put(`${this.baseUrl}/Userapps/${academic.UserID}/AcademicInfos/${academic.id}`, JSON.stringify(academic) , { headers: this.putHeaders()})
   .pipe(
    catchError(err => this.authService.errorMessages(err))
)  
  
}


 DeleteAcademicByID(UserID,AcademicID): Observable<any>{
  return this
   .http
   .delete(`${this.baseUrl}/Userapps/${UserID}/AcademicInfos/${AcademicID}`,  { headers: this.putHeaders()})
   .pipe(
    catchError(err => this.authService.errorMessages(err))
)  
  
}

getHeaders(): HttpHeaders{
  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.authService.getToken()
  });
  return headers;
}

putHeaders(): HttpHeaders{
  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.authService.getToken()
  });
  return headers;
}





}





