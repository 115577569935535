import { UserCVComponent } from '../../user-cv.component';
import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersField } from 'app/models/usersInfo/users-fields';
import { Scientific } from 'app/models/Scientificinfo/scientific';
import { Course } from 'app/models/Coursesinfo/course';
import { UsersService } from 'app/models/usersInfo/users.service';
import { ScientificService } from 'app/models/Scientificinfo/scientific.service';
import { AttachmentService } from 'app/models/attachment/attachment.service';
import { AuthService } from 'app/models/Auth/auth.service';

declare var jQuery: any;
declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'add-scientificinfo',
  templateUrl: './add-scientificinfos.component.html',
  styleUrls: ['./add-scientificinfos.component.css']
})


export class AddScientificInfoComponent implements OnInit {
  public mask = [/[1-2]/, /\d/, /\d/, /\d/, '-', /[0-1]/, /\d/];
  User_info: UsersField = new UsersField();

  ScientificInfos: any;
  courses: Course[];
  user: UsersField;
  TypePublications: any[];
  stateButton: boolean = false

  isok: Boolean;
  errorMessage: string = '';
  submitbuton: string = 'Aceptar'
  fileToUpload: File;
  imagebuton = "Subir Archivo";
  isImageError = true;
  enablesavefile = false;
  buttonSoporte = "Subir Soporte";

  constructor(public userCVComponent: UserCVComponent,
    private route: ActivatedRoute,
    private usersService: UsersService,
    public scientificService: ScientificService,
    private router: Router,
    public attachmentService: AttachmentService,
    private authService: AuthService) {

  }






  //INDICO QUE LOS DATOS NO ESTAN DISPONIBLES AL CARGAR EL HTML PARA NO MOSTRAR ERRORES
  ngOnInit() {

    this.ScientificInfos = {


    }
    this.ScientificInfos["stateUploadFile"] = "Subir Soporte"
    if (!isNullOrUndefined(this.userCVComponent.User_info))
      this.User_info = this.userCVComponent.User_info;


    this.scientificService.getAllScientificPublication().subscribe(p => this.TypePublications = p);


  }

  uploadFile(fileInput: any) {
    console.log(fileInput)
    this.ScientificInfos["newCertificate"] = <File>fileInput.target.files[0];
    if (this.ScientificInfos["newCertificate"].size > 1000000) {//1M
      this.ScientificInfos["stateUploadFile"] = "Error de Archivo, No debe superar 1MB"
      this.ScientificInfos["enablesavefile"] = false
      console.error("ARCHIVO MAS DE 1MB")
    }
    else {
      this.ScientificInfos["stateUploadFile"] = 'Archivo OK'
      this.ScientificInfos["enablesavefile"] = true
    }
  }

  CreateScientificInfo(model: Scientific, isValid: boolean) {

    if (isValid) {
      this.stateButton = true
      console.debug(model);
      this.errorMessage = "Validando ... ";
      this.submitbuton = "Validando ... ";

      if (this.ScientificInfos["enablesavefile"] == true) {
        this.attachmentService.NewScientificInfoSuports(this.ScientificInfos["newCertificate"]).then((result) => {

          model.Soporte = "/attachments/scientificinfo/download/" + result['name'];

          this.scientificService.saveScientific(model).subscribe(
            p => {
              this.isok = true;
              this.stateButton = false
              this.userCVComponent.ngOnInit();

              this.ScientificInfos = {};
              this.ScientificInfos["stateUploadFile"] = "Subir Soporte"
              this.userCVComponent.ScientificInfos = [];
              this.errorMessage = '';
              this.submitbuton = 'Guardar'
              //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
              setTimeout(function () {
                jQuery("#ModalScientific").modal('hide');
                jQuery(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
                jQuery(':checkbox, :radio').prop('checked', false);
              }, 100);

            },
            e => {
              this.errorMessage = "Corrija los datos erróneos";
              this.stateButton = false
              this.isok = false;
              this.submitbuton = 'Actualizar'
            });
        }, (error) => {
          console.error(error);
          this.authService.swalError(
            "Error en Archivo",
            ""
          );
        })
      }
      else {
        this.scientificService.saveScientific(model).subscribe(
          p => {
            this.isok = true;
            this.stateButton = false
            this.userCVComponent.ngOnInit();

            this.ScientificInfos = {};
            this.userCVComponent.ScientificInfos = [];
            this.ScientificInfos["stateUploadFile"] = "Subir Soporte"
            this.errorMessage = '';
            this.submitbuton = 'Guardar'
            //Esperar 0.5 segundos despues de actualizar y cerrat ventana modal
            setTimeout(function () {
              jQuery("#ModalScientific").modal('hide');
              jQuery(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
              jQuery(':checkbox, :radio').prop('checked', false);
            }, 100);

          },
          e => {
            this.errorMessage = "Corrija los datos erróneos";
            this.stateButton = false
            this.isok = false;
            this.submitbuton = 'Actualizar'
          });

      }




    }

  }


}


