import { Component, Input } from "@angular/core";
import { environment } from "environments/environment";

@Component({
    template: `<a [routerLink]="[redirectPath]" target="_blank">Ver</a>`
})

export class LinkRenderComponent {
    public SCHOOL_NAME:string = environment.SCHOOL_NAME;

    public redirectPath:string = '/Cv-Public';

    private _rowData:any;
    get rowData(): any {
        return this._rowData;
    }
    @Input() set rowData(value: any) {
        this._rowData = value;
        this.redirectPath = `/Cv-Public/${this._rowData.CedocEmail}`;
        if(this.SCHOOL_NAME === 'CEDOC')
            return;

        this.redirectPath = `/academicStatus/observations/${this._rowData.UserID}`;
    }
}